<div class="row reservationContainer">
  <div class="col-md-4 col-xs-12 reservationHeading">
    {{reservation.restaurantName}}
  </div>
  <div class="col-md-4 col-xs-12 reservationHeading align-content-center">
    {{reservation.reservationDate}}
  </div>
  <div class="col-md-4 col-xs-12 reservationHeading align-content-center">
    {{reservation.reservationTime}}
  </div>
  <div class="col-12">
    <app-guest-element *ngFor="let guest of reservation.guests" [guest]="guest"></app-guest-element>
  </div>
</div>


<footer class="bg-primary mdlb-footer-container">
  <div class="row">
    <div class="col-12 mdlb-footer-logo">
      <a href="/">
        <img src="assets/images/logo.svg"
             alt="Makery Global Webshop"
             title="Makery Global Webshop">
      </a>
    </div>
  </div>
  <div class="row mdlb-footer-frame">

    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
      <h5 class="mdlb-footer-heading" translate="footerBar.makery"></h5>
      <ul class="mdlb-footer-list">
        <li *ngFor="let element of menuElements">
          <a class="mdlb-footer-link" href="{{element.seoHref | translate}}{{getNavExtra(element)}}" title=""
             translate="{{element.title}}"></a>
        </li>
      </ul>
    </div>
    <div class=" col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
      <h5 class="mdlb-footer-heading" translate="footerBar.info"></h5>
      <ul class="mdlb-footer-list">
        <li>
          <a class="mdlb-footer-link" href="{{ 'seohref.privacyAszf' | translate}}{{externalNavigationExtra}}" title=""
             translate="menu.aszf"></a>
        </li>
        <li>
          <a class="mdlb-footer-link" href="{{ 'seohref.privacyCookie' | translate}}{{externalNavigationExtra}}"
             title=""
             translate="menu.gdpr"></a>
        </li>
        <li>
          <a class="mdlb-footer-link" href="{{ 'seohref.privacyPrivacy' | translate}}{{externalNavigationExtra}}"
             title=""
             translate="menu.pp"></a>
        </li>


      </ul>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
      <h5 class="mdlb-footer-heading" translate="footerBar.contact"></h5>
      <ul class="mdlb-footer-list">
        <li>
          <a class="mdlb-footer-link"
             href="mailto:{{'connectData.email' | translate}}"
             title="{{'connectData.email' | translate}}" translate="connectData.email"></a>
        </li>
        <li>
          <a class="mdlb-footer-link"
             href="tel:{{'connectData.phone' | translate}}"
             title="{{'connectData.phone' | translate}}" translate="connectData.phone"></a>
        </li>

      </ul>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <h5 class="mdlb-footer-heading" translate="footerBar.restaurants"></h5>
      <ul class="mdlb-footer-list">
        <li *ngFor="let restaurant of restaurants" class="lis">
          <div class="mdlb-footer-link">
            <img class="align-text-top" src="assets/images/flag-{{restaurant.locale}}.svg"
                 (error)="restaurant.locale = 'default'" width="22px" alt="flag-{{restaurant.locale}}"
                 title="flag-{{restaurant.locale}}">
            <span class="ml-2">{{restaurant.name}}</span>
          </div>
          <ul class="list-unstyled pl-4">
            <li *ngFor="let phone of restaurant.phones" class="mb-0">
              <a class="mdlb-footer-link"
                 href="tel:{{phone.countryCode}}{{phone.number}}"
                 title="{{phone.countryCode}}{{phone.number}}">{{(phone.countryCode + " " + phone.number) | phone}}</a>
            </li>
            <li class="mb-0">
              <a class="mdlb-footer-link"
                 href="mailto:{{restaurant.generalEmail}}"
                 title="{{restaurant.generalEmail}}">{{restaurant.generalEmail}}</a>
            </li>
            <li class="mb-0">
              <a class="mdlb-footer-link"
                 target="_blank"
                 href="https://www.google.hu/maps/@{{restaurant.baseAddress.latitude }},{{restaurant.baseAddress.longitude }},19z"
                 title="{{restaurant.name}}">
                {{restaurant.baseAddress.zipCode }} {{restaurant.baseAddress.city }}
                , {{restaurant.baseAddress.address}} {{'streets.' + restaurant.baseAddress.addressType | translate}} {{restaurant.baseAddress.houseNumber}}
                .
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>


  </div>
  <div class="row">
    <div class=" col-12 ">
      <ul class="mdlb-footer-list">
        <li class="mdlb-footer-barion-frame justify-content-end">
          <a href="https://www.barion.com"
             target="_blank">
            <img src="assets/images/logo/barion-newl.svg"
                 alt="{{'footerbar.paymentProvider' | translate}}"/>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Fordítások be/ki kapcsolása -->
  <div class="custom-control custom-switch" [hidden]="isProduction">
    <input type="checkbox" class="custom-control-input" id="translateSwitch"
           [(ngModel)]="turnOffTranslations" (change)="translateOnOff()">
    <label class="custom-control-label"
           for="translateSwitch">{{'general.translateOnOff' | translate }}</label>
  </div>
</footer>
<noscript>
  <img height="1" width="1" style="display:none" alt="Barion Pixel"
       src="https://pixel.barion.com/a.gif?ba_pixel_id='{{barionPixelId}}'&ev=contentView&noscript=1">
</noscript>

<noscript>
  <img height="1" width="1" style="display:none;" alt=""
       src="https://px.ads.linkedin.com/collect/?pid={{linkedInId}}&fmt=gif"/>
</noscript>

<div class="mall-slide" *ngIf="isStart">
  <div class="slid-content">
    <div class="mall-show-slide fade" *ngFor="let item of imageData;">
      <img src="{{item}}" style="width:100%">
    </div>
    <div class="mall-carousel-ind">
      <ul>
        <li *ngFor="let item of imageData; let i = index;" [class.mall-this]="startIndex - 1 === i"></li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="!isStart">
  <img src="{{imageData[0]}}" style="width:100%">
</div>


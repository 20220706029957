<div class="card mb-3 orderList ">
  <div class="row no-gutters">
    <div class="col-md-3">

      <img class="lazy card-img" src="{{drink.imgSrc}}"
           alt=""/>
    </div>
    <div class="col-md-9 ">
      <div class="card-body pl-3 pr-3">
        <h2 class="card-title mt-0">{{drink.localizations[translate.currentLang].name}}</h2>
      </div>
    </div>
  </div>
</div>

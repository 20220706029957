<div class="row banner-img-wrapper pt-5">
  <div class="col-6 px-0 px-md-4 ">
    <div class="row">
      <div class="col-12 pl-0 pl-md-3 banner-img-half">
        <img [src]="urls[0]"
             alt="{{title | translate}}"
             title="{{title| translate}}">
      </div>
    </div>
    <div class="row">
      <div class="col-12 pl-0 pl-md-3 banner-img-half-padding">
        <img  [src]="urls[1]"
              alt="{{title | translate}}"
              title="{{title | translate}}">
      </div>
    </div>
  </div>
  <div class="col-6 banner-img pr-0 pr-md-3 ">
    <img  [src]="urls[2]"
          alt="{{title | translate}}"
          title="{{title | translate}}">
  </div>
</div>

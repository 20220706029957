import { Injectable } from '@angular/core';
import {CurrencyRepository} from "../repositories/currency.repository";
import {MyStorageService} from "./my-storage.service";
import {Constants} from "../utils/constants";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  currentCurrencyCode:string;
  currencyCodeChange: Subject<string> = new Subject<string>();

  constructor(
    public currencyRepository: CurrencyRepository,
    public myStorageService: MyStorageService
  ) {
    this.currentCurrencyCode = this.getFromStore();
  }

  getCurrencies(){
    return this.currencyRepository.getCurrencies();
  }

  getFromStore(fallback = Constants.DEFAULT_CURRENCY): string{
    return this.myStorageService.getFromCookies(Constants.STORAGE_KEYS.CURRENCY, fallback);
  }

  saveToStore(currencyCode: string): void{
    this.myStorageService.saveToCookies(Constants.STORAGE_KEYS.CURRENCY, currencyCode);
    this.currentCurrencyCode = this.getFromStore();
    this.currencyCodeChange.next(this.currentCurrencyCode);
  }
}

import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {ClosableComponent} from "../closable-component";
import {RestaurantRepository} from "../../repositories/restaurant.repository";
import {MyStorageService} from "../../services/my-storage.service";
import {Constants} from "../../utils/constants";
import {ProductRepository} from "../../repositories/product.repository";

@Component({
  selector: 'app-restaurant-selector',
  templateUrl: './restaurant-select-modal.component.html',
  styleUrls: ['./restaurant-select-modal.component.scss']
})
export class RestaurantSelectModalComponent extends ClosableComponent implements OnInit {
  public restaurants: any[] = [];
  public selectedRestaurantHistoryId;
  public deliveryOff: boolean = true;

  constructor(
    public bsModalRef: BsModalRef,
    private productRepository: ProductRepository,
    private restaurantRepository: RestaurantRepository,
    private storageService: MyStorageService
  ) {
    super(bsModalRef)
  }

  ngOnInit(): void {
    this.restaurants = this.storageService.getFromStore(Constants.STORAGE_KEYS.WHOLE_RESTAURANTS, []);

    if (this.restaurants.length > 0) {
      this.selectedRestaurantHistoryId = this.restaurants[0].historyId;
      this.deliveryOff = false;
    } else {
      this.deliveryOff = true;
    }

  }

  restaurantSelected(restaurant: any) {
    this.selectedRestaurantHistoryId = restaurant.historyId;
    this.setRestaurant(restaurant);
  }

  setRestaurant(restaurant: any) {
    this.storageService.saveToCookies(Constants.STORAGE_KEYS.RESTAURANT, this.selectedRestaurantHistoryId);
    this.storageService.saveToCookies(Constants.STORAGE_KEYS.LANGUAGE, restaurant.defaultLanguage.toLowerCase());
    this.storageService.saveToCookies(Constants.STORAGE_KEYS.CURRENCY, restaurant.defaultCurrency.toLowerCase());
    this.closeWithResponse(true)
  }
}

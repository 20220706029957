<div class="row mdlb-ui-padding mb-5" style="margin-bottom:100px; margin-top:0px" *ngIf="product">
  <div class="col-md-12">
    <div class="row product-img-wrapper px-sm-0 p-md-0">
      <div class="col-lg-5 col-xl-6 mdlb-product-img p-0 p-md-0">
        <img class="product-img" src="{{productUrl}}" alt="{{product.name}}"
             title="{{product.name}}">
      </div>
      <div class="col-lg-7 col-xl-6 px-sm-2 px-0">
        <div class="row">
          <div class="col-12 px-sm-2 px-0">
            <h1 class="detail-card-header">{{product.name}}</h1>
          </div>
          <div class="col-12 p-0">
              <span class="badge badge-pill badge-dark mdlb-product-details-price-pill m-1">
                {{product.prices[currencyService.currentCurrencyCode].price | number}}
                {{currencyService.currentCurrencyCode | uppercase}}
                / {{'product.person' | translate}}
              </span>
<!--            <span class="badge badge-pill badge-dark mdlb-product-details-price-pill m-1">-->
<!--              {{product.giftCard.validityInDay}} {{'giftCard.day' | translate}} {{'giftCard.validity' | translate}}-->
<!--            </span>-->
            <ng-container *ngFor="let restaurant of product.restaurants">
              <span class="badge badge-pill badge-dark mdlb-product-details-price-pill m-1">
              {{restaurant.name}}
              </span>
            </ng-container>

            <p class="mdlb-product-details-desc pl-2">
              {{product.title}}
            </p>

            <div class="row align-items-center justify-content-end"
                 style="margin-bottom: 24px; margin-top: 24px">
              <div class="col-auto p-sm-2 p-1">
                                    <span class="font-weight-bolder"
                                          style="line-height: 38px;margin-top:auto; margin-bottom: auto; padding-top:8px; padding-bottom: 8px;cursor: default">
                                        {{personCount *
                                    product.prices[currencyService.currentCurrencyCode].grossPrice | number}}
                                      {{currencyService.currentCurrencyCode | uppercase}}
                                    </span>
              </div>
              <div class="col-auto p-sm-2 p-1">
                <div class="mdlb-product-person-count-selector">
                                        <span class="mdlb-ps-box ps-link"
                                              (click)="decrementPersonCount()">-</span>
                  <span class="mdlb-ps-box ps-count">{{personCount}}</span>
                  <span class="mdlb-ps-box ps-link"
                        (click)="incrementPersonCount()">+</span>
                </div>
              </div>

              <div class="p-sm-2 p-1 col-12 col-sm-auto">
                <button class=" btn btn-primary w-100"
                        style="margin-top:auto; margin-bottom: auto; padding-top:8px; padding-bottom: 8px; "
                        (click)="addToCart()"
                >
                  <span style="line-height: 38px;"> {{'product.toCart' | translate}}</span>
                </button>
              </div>
            </div>

            <hr/>
            <app-extra-info
              [optionPackage]="product.giftCard.optionMap.DELIVERY.optionPackagesWithAndOperation"></app-extra-info>

          </div>

        </div>
      </div>
      <div class="col-12 px-0">
        <div class="row justify-content-end no-gutters">
          <div class="p-sm-2 col-12 col-sm-auto ">
            <a href="/"
               class="btn btn-outline-primary w-100 ">
              {{'shoppingCartModal.continueShopping' | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-0">
        <div class="mdlb-delivery-ui-gray text-left m-0 mt-4 mb-2">
          <h3>{{'product.description' | translate}}</h3>
          <div class="row mt-3">
            <div class="col-12 p-0">
              <p class="mdlb-product-details-desc" [innerHtml]="product.description">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="product">
  <app-recommended-product [productId]="product.id"
                           [restaurantHistoryId]="restaurantHistoryId"></app-recommended-product>
</ng-container>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RepositoryService} from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantRepository extends RepositoryService {
  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  getAll(){
    const path = 'webapi/api/restaurants';
    return super.get(path)
  }
  getAllForDelivery(){
    const path = 'webapi/api/delivery-restaurants';
    return super.get(path)
  }
}

import {Component, OnInit} from '@angular/core';
import {BannerLayoutComponentModel} from '../../banner-layout-component.model';
import {BannerContentModel} from '../banner-content.model';
import {TranslateService} from '@ngx-translate/core';
import {AnalyticsService} from '../../../../services/analytics.service';

@Component({
  selector: 'app-title-desc-two-link-content',
  templateUrl: './title-desc-two-link-content.component.html',
  styleUrls: ['./title-desc-two-link-content.component.scss']
})
export class TitleDescTwoLinkContentComponent implements OnInit, BannerLayoutComponentModel<BannerContentModel> {
  data: BannerContentModel;
  slug: string;
  isYellow = false;

  constructor(
    private translateService: TranslateService,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnInit(): void {
  }

  navigate(link: string, text: string): void {
    const nextPage: string = this.translateService.instant(link);
    if (nextPage.includes('!#')) {
      document.getElementById(nextPage.substr(2)).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    } else {
      this.analyticsService.buttonClickEvent(text, 'title-description-and-link-content', nextPage);
      window.location.href = nextPage;
    }

  }

}

<style>
  .tr-hide{
    display: none;
  }
</style>
<div class="mdlb-ui-padding tr-{{contentData.show | translate}}" [ngClass]="{'banner-card':!isYellow, 'banner-card-yellow':isYellow}" >
  <div class="row" [ngClass]="{'flex-row-reverse':bannerLayoutType.isReverse}">
    <div class="align-self-center {{bannerLayoutType.contentColSize}}">
      <ng-template viewAnchor [data]="contentData" [isContent]="true"></ng-template>
    </div>
    <div class="{{bannerLayoutType.imgColSize}}">
      <ng-template viewAnchor [data]="imgUrls" [isContent]="false"></ng-template>
    </div>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {CartService} from "../../services/cart.service";
import {CartElement} from "../../models/cart-models";
import {CurrencyService} from "../../services/currency.service";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cart-elements',
  templateUrl: './cart-elements.component.html',
  styleUrls: ['./cart-elements.component.scss']
})
export class CartElementsComponent implements OnInit {
  @Input() cartElement: CartElement;
  @Input() isEditable: boolean = true;
  @Input() bsModalRef: BsModalRef;

  constructor(
    public cartService: CartService,
    public currencyService: CurrencyService,
    public translateService: TranslateService,
    private attachmentUrlService: AttachmentUrlService
  ) {
  }

  ngOnInit(): void {
    if (this.cartElement) {
      this.cartElement.imgSrc = this.attachmentUrlService.getScaleWidthImageUrl(
        this.cartElement.productFull.attachments[1].attachment.localization, 600
      );
    }
  }

  removeProduct(cartElement: any) {
    this.cartService.removeProduct(cartElement);

  }

  updateCart(cartElement: any) {
    this.cartService.updateCartElement(cartElement);
  }

  decrementPersonCount(cartElement: any) {
    if ((cartElement.personCount - 1) == 0) {
      this.removeProduct(cartElement);
    }
    if ((this.cartElement.personCount - 1) >= this.cartElement.productFull.giftCard.minPurchaseAmount) {
      this.cartElement.personCount--;
    }
    this.updateCart(cartElement);
  }

  incrementPersonCount(cartElement: any) {
    if (this.cartElement.productFull.giftCard.minPurchaseAmount <= this.cartElement.productFull.giftCard.personCount) {
      if ((this.cartElement.personCount + 1) <= this.cartElement.productFull.giftCard.personCount) {
        cartElement.personCount++;
      }
    } else {
      cartElement.personCount++;
    }
    this.updateCart(cartElement);
  }

  getRestaurantName() {
    const restaurant = this.cartElement.productFull.restaurants.filter(restaurant => {
      return restaurant.historyId == this.cartElement.restaurantHistoryId
    })[0];
    return restaurant ? restaurant.name : '';
  }
}

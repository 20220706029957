import {Directive, Input, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[viewAnchor]'
})
export class ViewAnchorDirective {
  @Input() data: any;
  @Input() isContent: boolean;

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}

<div class="card mb-3 orderList ">
  <div class="row no-gutters">
    <div class="col-md-3">

     <img class="lazy card-img" src="{{food.imgSrc}}"
           alt=""/>
    </div>
   <div class="col-md-9 ">
      <div class="card-body pl-3 pr-3">
        <h2 class="card-title mt-0">{{food.localizations[translate.currentLang].name}}</h2>

        <div itemprop="description">
          <div class="card-text">{{'guests.cookingTime' | translate}}: <span class="font-weight-bold">{{food.recipes[0].activeCookingTime }} {{'guests.minute' | translate}}</span></div>
          <div class="card-text">{{'guests.kitchenExperienceLevel' | translate}}: <span class="font-weight-bold">{{'enum.kitchenExpertLevel.'+food.recipes[0].kitchenExperienceLevel | translate}}</span></div>
        </div>

      </div>
    </div>
  </div>
</div>


<div class="col-md-12 text-center" [ngSwitch]="ratingSubmitted">
  <div class="col-12"  *ngSwitchCase="false">
    <p class="mdlb-after-para" translate="afterpaymentPage.ratePayment"></p>

    <div class="rating large star-icon color-default">
      <ngb-rating [max]="5" [(rate)]="rate" [readonly]="false"></ngb-rating>
    </div>

    <div *ngIf="rate <=3  && rate !=0">
                            <textarea rows="4" class="form-control"
                                      placeholder="{{'afterpaymentPage.leaveFeedback' | translate}}"
                                      [(ngModel)]="review"></textarea>
    </div>

    <button class="btn btn-primary" style="margin-top: 40px" (click)="submitReview()"
                 *ngIf="rate > 0"
                  translate="afterpaymentPage.submitButton"></button>

        </div>
        <div class="col-12" *ngSwitchCase="true">
          <p class="mdlb-after-para" translate="afterpaymentPage.thanksForRating"></p>
          <a class="btn btn-primary"  style="margin-top: 40px" href="#" [href]="'/'"
             translate="afterpaymentPage.backToMainPage"></a>

        </div>
      </div>

      <!--<div class="row formContainer" [ngSwitch]="ratingSubmitted">-->
<!--  <div class="col-12" *ngSwitchCase="false">-->
<!--    <p class="my-1 mr-2 centered w-100" translate="afterpaymentPage.ratePayment"></p>-->

<!--    <div class="centered w-100">-->
<!--      <ngb-rating [max]="5" [(rate)]="rate" [readonly]="false"></ngb-rating>-->
<!--    </div>-->
<!--    <div *ngIf="rate <=3  && rate !=0" class="centered w-100 mt-2 ">-->
<!--                            <textarea rows="4" class="connectArea"-->
<!--                                      placeholder="{{'afterpaymentPage.leaveFeedback' | translate}}"-->
<!--                                      [(ngModel)]="review"></textarea>-->
<!--    </div>-->

<!--    <div class="centered buttonWrapper">-->
<!--      <button class="btn btn-primary longButton mt-3" (click)="submitReview()"-->
<!--              *ngIf="rate > 0"-->
<!--              translate="afterpaymentPage.submitButton"></button>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-12" *ngSwitchCase="true">-->
<!--    <p class="my-1 mr-2 centered" translate="afterpaymentPage.thanksForRating"></p>-->
<!--    <div class="centered buttonWrapper">-->
<!--      <a class="btn btn-primary longButton margT20" href="#" ng-href="/"-->
<!--         translate="afterpaymentPage.backToMainPage"></a>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<style>

  .rating.color-ok .star-container .star svg {
    fill: #F7CF47 !important;
  }

  .rating.color-ok .star-container .star i {
    color: #F7CF47 !important;
    border-radius: 3px;
  }

  .rating.color-positive .star-container .star svg {
    fill: #F7CF47 !important;
  }

  .rating.color-positive .star-container .star i {
    color: #F7CF47 !important;
    border-radius: 3px;
  }

  .rating.color-negative .star-container .star svg {
    fill: #F7CF47 !important;
  }

  .rating.color-negative .star-container .star i {
    color: #F7CF47 !important;
    border-radius: 3px;
  }

</style>

<div class="row banner-img-wrapper">
  <div class="col-9 px-0 px-md-4">
    <div class="row">
      <div class="col-8  banner-img-half pl-0 pl-md-3">
        <img [src]="urls[0]"
             alt="{{title | translate}}"
             title="{{title| translate}}">
      </div>
      <div class="col-4 banner-img-half">
        <img [src]="urls[1]"
             alt="{{title | translate}}"
             title="{{title | translate}}">
      </div>
    </div>
    <div class="row ">
      <div class="col-12 banner-img-half-padding  pl-0 pl-md-3">
        <img [src]="urls[2]"
             alt="{{title | translate}}"
             title="{{title | translate}}">
      </div>
    </div>
  </div>
  <div class="col-3 banner-img pr-0 pr-md-3">
    <img [src]="urls[3]"
         alt="{{title | translate}}"
         title="{{title | translate}}">
  </div>
</div>

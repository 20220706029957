
<div class="row">
  <div class="col-12 px-0 mdlb-welcome-top-delivery-img-wrapper">
    <div class="mdlb-delivery-top">
      <!--      <div class="row bg-dark p-3 mdlb-ui-border-radius">-->
      <div class="row">
        <div class="col-12 text-left">
                <h1>{{'delivery.newTopHeading' | translate}}</h1>
        </div>
        <div class="col-12 text-left">
                <p>{{'delivery.newTopPara' | translate}}</p>
        </div>
        <div class="col-12 text-left">
          <button (click)="scrollTo(productList)"
                  class="btn btn-primary mr-3">{{'delivery.ProductButtonText' | translate}}</button>
          <button (click)="goTo(redeemLink)"
                  class="btn btn-secondary">{{'delivery.redeemButtonText' | translate}}</button>
        </div>
      </div>
    </div>
    <div class="mdlb-grey-shadow"></div>
    <img class="mdlb-welcome-top-image" [src]="topImg"
         alt="Foglalj most, akár ajándékutalvány nélkül is!"
         title="Foglalj most, akár ajándékutalvány nélkül is!" (error)="topImg = 'assets/images/banner_img.jpg'">
  </div>
</div>
<div class="mdlb-welcome-container mdlb-welcome-delivery-container">

 <app-static-gray-banner></app-static-gray-banner>

  <app-banner
    [isYellow]="true"
    [bannerLayoutType]="bannerOne.layout"
    [imgLayoutComponent]="bannerOne.img"
    [imgUrls]="bannerOne.imgUrls"
    [contentLayoutComponent]="bannerOne.content"
    [contentData]="bannerOne.contentData"
  ></app-banner>

  <div #productList>
          <app-product-list></app-product-list>
  </div>

    <app-footer-tab></app-footer-tab>

  <app-banner
    [bannerLayoutType]="bannerTwo.layout"
    [imgLayoutComponent]="bannerTwo.img"
    [imgUrls]="bannerTwo.imgUrls"
    [contentLayoutComponent]="bannerTwo.content"
    [contentData]="bannerTwo.contentData"
    [isYellow]="false"
  ></app-banner>

  <app-banner
    [bannerLayoutType]="bannerThree.layout"
    [imgLayoutComponent]="bannerThree.img"
    [imgUrls]="bannerThree.imgUrls"
    [contentLayoutComponent]="bannerThree.content"
    [contentData]="bannerThree.contentData"
    [isYellow]="true"
  ></app-banner>

  <app-banner
    [bannerLayoutType]="bannerFour.layout"
    [imgLayoutComponent]="bannerFour.img"
    [imgUrls]="bannerFour.imgUrls"
    [contentLayoutComponent]="bannerFour.content"
    [contentData]="bannerFour.contentData"
    [isYellow]="false"
  ></app-banner>




</div>


<span #clickSim></span>





import {Component, OnInit} from '@angular/core';
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {TranslateService} from "@ngx-translate/core";
import {BannerAttributesInterface} from "../../components/banner/banner-attributes.interface";
import {
  TitleAndDescContentComponent
} from "../../components/banner/banner-content-layout/title-and-desc-content/title-and-desc-content.component";
import {BannerContentModel} from "../../components/banner/banner-content-layout/banner-content.model";
import {BannerLayout} from "../../components/banner/banner-layout.model";
import {
  ImgLayoutVerticalThreeComponent
} from "../../components/banner/banner-img-layout/img-layout-vertical-three/img-layout-vertical-three.component";
import {ImgLayoutOneComponent} from "../../components/banner/banner-img-layout/img-layout-one/img-layout-one.component";


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  topImg: any;
  bannerOne: BannerAttributesInterface = {
    content: TitleAndDescContentComponent,
    contentData: new BannerContentModel("", "product.tabOne.title", "product.tabOne.description"),
    img: ImgLayoutVerticalThreeComponent,
    imgUrls: ['product.tabOne.imgOne', 'product.tabOne.imgTwo', 'product.tabOne.imgThree'],
    layout: BannerLayout.LEFT50
  };
  bannerTwo: BannerAttributesInterface = {
    content: TitleAndDescContentComponent,
    contentData: new BannerContentModel("", "product.tabTwo.title", "product.tabTwo.description"),
    img: ImgLayoutOneComponent,
    imgUrls: ['product.tabTwo.imgOne', 'product.tabTwo.imgTwo', 'product.tabTwo.imgThree'],
    layout: BannerLayout.LEFT50
  };
  bannerThree: BannerAttributesInterface = {
    content: TitleAndDescContentComponent,
    contentData: new BannerContentModel("", "product.tabThree.title", "product.tabTwo.description"),
    img: ImgLayoutVerticalThreeComponent,
    imgUrls: ['product.tabThree.imgOne', 'product.tabThree.imgTwo', 'product.tabThree.imgThree'],
    layout: BannerLayout.LEFT50
  };

  constructor(private attachmentUrlService: AttachmentUrlService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.topImg = this.attachmentUrlService.getScaleWidthImageUrlBySlug('delivery-top-background', 1400);
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {BillingData, User} from "../../models/order-payload";
import {CartService} from "../../services/cart.service";
import {Constants} from "../../utils/constants";
import {OrderService} from "../../services/order.service";
import {ToasterService} from "../../services/toaster.service";
import {DiscountRepository} from "../../repositories/discount.repository";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AnalyticsService} from "../../services/analytics.service";
import {CurrencyService} from "../../services/currency.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit {
  bsModalRef: BsModalRef;
  @Input() isEditable: boolean = true;
  @Input() deliveryData: any = {};
  @Input() billingData: any = {};
  @Input() restaurantAddress: any;

  public billingAddress: BillingData = new BillingData();
  public deliveryAddress: BillingData = new BillingData();
  public orderData: any = {};
  public invoiceRequired: boolean = false;
  public discount: any;
  public cart: any;
  public cities: any;
  public countries: string[] = [];
  public deliveryCountry = "HU";
  public deliveryCity = "Budapest";
  public addressTypes: any;
  public message: string = "";
  public billingIsSame = true;
  public discountCode: string = "";
  public subscribe: boolean = false;

  constructor(
    public cartService: CartService,
    public orderService: OrderService,
    public currencyService: CurrencyService,
    public translate:TranslateService,
    public toastr: ToasterService,
    public discountRepository: DiscountRepository,
    private analyticsService: AnalyticsService,
    private modalService: BsModalService) {
  }

  ngOnInit(): void {
    this.cart = this.cartService.cart;
    this.orderData = this.cart.orderData;
    this.addressTypes = Constants.ADDRESS_TYPES;
    if (this.orderData != null) {
      this.message = this.orderData.message;
      this.billingIsSame = this.orderData.billingIsSame;
    }

      if (this.isEditable) {
        this.deliveryAddress.address.country = this.cartService.deliveryRestaurantAddress.country;
        this.deliveryCountry = this.cartService.deliveryRestaurantAddress.country;
        this.deliveryAddress.address.city = this.cartService.deliveryRestaurantAddress.city;
        this.deliveryCity = this.cartService.deliveryRestaurantAddress.city;
      }

      /** 2021.05.21 - Krisz: Az if utolsó feltételének semmi értelme, mindig true lesz -- benne hagyom, mert nem tudom mit szeretett volna a szerző elérni vele */
      if (this.orderData != null && this.orderData.billingData != null && this.orderData.billingData != {}) {
        this.billingAddress = this.orderData.billingData;
        if (this.billingAddress.taxNumber && this.billingAddress.taxNumber.length > 0) {
          this.invoiceRequired = true;
        }
      } else {
        this.billingAddress.address.addressType = Constants.ADDRESS_TYPES[0];
      }
      if (this.orderData != null && this.orderData.deliveryData != null && this.orderData.deliveryData != {}) {
        this.deliveryAddress = this.orderData.deliveryData;
        this.deliveryCountry = this.deliveryAddress.address.country;
        this.deliveryCity = this.deliveryAddress.address.city;
      } else {
        this.deliveryAddress.address.addressType = Constants.ADDRESS_TYPES[0];
      }
  }

  sendOrderToCheck() {
    if (this.isEditable) {
      this.orderService.addItemOrderdata(this.billingAddress, this.deliveryAddress, this.message, this.billingIsSame);
      let user = new User(this.billingAddress.email);
      this.orderService.sendOrderToBackendStartPayment(user);
    } else {
      this.orderService.finalizeOrder(this.subscribe);
    }
  }

  checkDiscount() {
    this.discountRepository.getDiscountByCodeAndOrigin(this.discountCode, "DELIVERY").subscribe((data: any) => {
      if (data.hasMinPurchaseValue == true && (this.cartService.cart.total + this.cartService.cart.discount ) < data.minPurchaseValue) {
        this.toastr.showWarningRawMessage("discount.title",this.translate.instant('discount.minPurchaseValue') + ": " + data.minPurchaseValue);
        return false;
      }
      this.discount = data;
      this.cartService.addDiscount(this.discount);
      this.toastr.showSuccess("discount.title", "discount.message")
    });
  }

}

<div class="container-fluid my-5" [hidden]="!isLoggedIn">
  <div class="row mb-5">
    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 ">
      <form>
        <div class="row formContainer">
          <h1 translate="profilPage.header"></h1>
          <div class="col-md-6 col-xs-12 ">

            <div class="row">
              <div class="col-12">
                <label class="my-1 mr-2" translate="profilPage.familyName"></label>
                <input class="form-control" type="text"
                       [(ngModel)]="profilePayload.firstName" [ngModelOptions]="{standalone: true}" required/>
              </div>
              <div class="col-12">
                <label class="my-1 mr-2" translate="profilPage.userName"></label>
                <input class="form-control" type="text"
                       [(ngModel)]="profilePayload.userName"  [ngModelOptions]="{standalone: true}" required/>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="row">
              <div class="col-12">
                <label class="my-1 mr-2" translate="profilPage.givenName"></label>
                <input class="form-control" type="text"
                       [(ngModel)]="profilePayload.lastName" [ngModelOptions]="{standalone: true}" required/>
              </div>
              <div class="col-12">
                <label class="my-1 mr-2" translate="profilPage.email"></label>
                <p class="mt-2">{{authUser.email}}
                  <span [hidden]="!authUser.emailVerified"><img src="../../../assets/images/pipa.svg"
                                                                alt="{{'profilPage.eamilVerified' |translate}}:{{'general.yes' |translate}}"
                                                                title="{{'profilPage.eamilVerified' |translate}}:{{'general.yes' |translate}}"></span>
                  <span [hidden]="authUser.emailVerified"><img src="../../../assets/images/pipa.svg"
                                                               alt="{{'profilPage.eamilVerified' |translate}}:{{'general.no' |translate}}"
                                                               title="{{'profilPage.eamilVerified' |translate}}:{{'general.no' |translate}}"></span>
                </p>
              </div>
              <div class="col-12 my-3">
                <button class="btn btn-primary longButton" (click)="sendProfile()">
                  {{'profilPage.modify' | translate}}
                </button>
                <button class="btn btn-primary longButton" (click)="resetPassword()">
                  {{'profilPage.newPassword' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


import {Injectable} from "@angular/core";
import {DataMiningRepository} from "../repositories/data-mining.repository";
import {MyStorageService} from "./my-storage.service";
import {Constants} from "../utils/constants";
import {TranslateService} from "@ngx-translate/core";
import {
  browserActive,
  buttonClickModel,
  changeLangModel,
  checkoutModelBarion, checkoutModelDataMiner, dataMiningModel,
  pageOutLinkModel,
  pageViewModel,
  productModelBarion, productViewModelBarion,
  purchaseModelAnalytics,
  purchaseModelFacebook,
  purchaseProductModelAnalytics,
  purchaseProductModelFacebook,
  sendFormModel,
  startFormModel, UtmModel
} from "../models/data-mining-models";
import {Cart} from "../models/cart-models";
import {ActivatedRoute} from "@angular/router";
import {Utilities} from "../utils/utilities";
import {CookieService} from "ngx-cookie-service";

declare const gtag: Function;
declare const fbq: Function;
declare const bp: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private dataMiningRepository: DataMiningRepository,
              private window: Window,
              private translate: TranslateService,
              private storageService: MyStorageService,
              private route: ActivatedRoute,
              private cookieService: CookieService) {
    this.setUtmParameters();
  }

  sendDataForMinig (subTopic, model) {
      let dataModel = new dataMiningModel();
      dataModel.restaurantHistoryId = this.storageService.getFromCookies(Constants.STORAGE_KEYS.RESTAURANT, null);
      dataModel.cookieId = this.getGoogleSessionId();
      dataModel.sourceOrigin = this.window.location.origin;
      dataModel.eventType = Constants.KAFKA_TOPICS.ANALYTICS;
      dataModel.eventSubType = subTopic;
      dataModel.data = model;
      dataModel.origin=this.getUtmParameters();

    this.dataMiningRepository.sendDataForMining(dataModel).subscribe(response => {
        console.log(response);
      });
  }

  addToCartEvent(product, number, personCount) {
      let model = new productModelBarion();
      model.id = product.historyId.toString();
      model.name = product.name;
      model.quantity = number;
      model.totalItemPrice = String(number * product.prices["huf"].grossPrice * personCount);
      model.unitPrice = String(product.prices["huf"].grossPrice);
      bp('track', 'addToCart', model);
      fbq('track', 'AddToCart', {
          content_type: 'Product',
          content_name: product.name,
          content_ids: [product.historyId],
          currency: 'HUF',
          value: product.prices["huf"].grossPrice
      });
      gtag("event", "submit", {
          event_category: "Purchase",
          event_label: "Add to Cart",
          value: number * product.prices["huf"].grossPrice * personCount
      });

      model.direction = "add";
      this.sendDataForMinig("addToCart", model);

  }

  removeCartElementEvent(product, number){
      let model = new productModelBarion();
      model.id = product.historyId.toString();
      model.name = product.name;
      model.quantity = number;
      model.totalItemPrice = String(number * product.prices["huf"].grossPrice);
      model.unitPrice = String(number * product.prices["huf"].grossPrice);
      model.direction = "remove";
      this.sendDataForMinig("removeFromCart", model);
  }

  updateCartElementEvent(product, number, personCount){
      let model = new productModelBarion();
      model.id = product.historyId.toString();
      model.name = product.name;
      model.quantity = number;
      model.totalItemPrice = String(number * product.prices["huf"].grossPrice * personCount);
      model.unitPrice = String(product.prices["huf"].grossPrice);
      model.direction = "remove";
      this.sendDataForMinig("updateInCart", model);
  }

  productViewEvent(product) {
      let model = new productViewModelBarion();
      model.id = product.historyId.toString();
      model.name = product.name;
      model.unitPrice = String(product.prices["huf"].grossPrice);
      bp('track', 'contentView', model);
      fbq('track', 'PageView', {
          content_type: 'Product',
          content_name: product.name,
          content_ids: [product.historyId],
          currency: 'HUF',
          value: product.prices["huf"].grossPrice
      });
      gtag("event", "click", {
          event_category: "Product",
          event_label: "Details"
      });
      this.sendDataForMinig("productView", model);
  }

  initiateCheckoutEvent(cart: Cart, step:number) {
      let model = new checkoutModelBarion();
      let facebookModel = new purchaseModelFacebook();
      facebookModel.value = cart.total;
      model.revenue = cart.total;
      model.step = step;

      if (cart.cartModel.discountFull != null && typeof cart.cartModel.discountFull.code !== 'undefined') {
            model.coupon=(cart.cartModel.discountFull.code);
      }
      model.contents = [];
      facebookModel.contents = [];
      cart.cartModel.cartElements.forEach((products, index) => {
          let fbElement = new purchaseProductModelFacebook();
          let element = new productModelBarion();
          element.id = products.productFull.historyId.toString();
          element.name = products.productFull.name;
          element.quantity = String(products.count);
          element.totalItemPrice = String(products.count * products.productFull.prices["huf"].grossPrice);
          element.unitPrice = String(products.count * products.productFull.prices["huf"].grossPrice);
          fbElement.id = products.productFull.historyId;
          fbElement.quantity = products.count;
          facebookModel.contents.push(fbElement);
          model.contents.push(element);
      });
      bp('track', 'initiateCheckout', model);
      if (step == 0) {
          fbq('track', 'InitiateCheckout', facebookModel);
          this.sendDataForMinig("initiateCheckout", model);
      }
      if (step == 1) {
          fbq('track', 'AddPaymentInfo', facebookModel);
          this.sendDataForMinig("AddPaymentInfo", model);
      }
      return model;

  }

  purchaseEvent(cart:Cart, step:number, tid) {
      let model = new checkoutModelBarion();
      let facebookModel = new purchaseModelFacebook();
      let analyticsModel = new purchaseModelAnalytics();
      facebookModel.value = cart.total;
      analyticsModel.transaction_id = tid;
      analyticsModel.value = cart.total;
      model.revenue = cart.total;
      model.step = step;

      if (cart.cartModel.discountFull != null && typeof cart.cartModel.discountFull.code !== 'undefined') {
        model.coupon=(cart.cartModel.discountFull.code);
      }

      model['contents'] = [];
      facebookModel['contents'] = [];
      analyticsModel['items'] = [];
      cart.cartModel.cartElements.forEach((products, index) => {

          let element = new productModelBarion();
          let fbElement = new purchaseProductModelFacebook();
          let analyticsElement = new purchaseProductModelAnalytics();
          element.id = products.productFull.historyId.toString();
          element.name = products.productFull.name;
          element.quantity = String(products.count);
          element.totalItemPrice = String(products.count * products.productFull.prices["huf"].grossPrice);
          element.unitPrice = String(products.productFull.prices["huf"].grossPrice);
          fbElement.id = products.productFull.historyId;
          fbElement.quantity = products.count;
          analyticsElement.id = products.productFull.historyId.toString();
          analyticsElement.name = products.productFull.name;
          analyticsElement.quantity = products.count;
          analyticsElement.price = String(products.productFull.prices["huf"].grossPrice);
          facebookModel.contents.push(fbElement);
          analyticsModel.items.push(analyticsElement);
          model.contents.push(element);
      });
      bp('track', 'purchase', model);
      fbq('track', 'Purchase', facebookModel);
      gtag('event', 'purchase', analyticsModel);
      this.sendDataForMinig("Purchase", model);
      return model;

  }

  initiatePaymentEvent(cart: Cart,orderId) {
      let model = new checkoutModelBarion();
      let dtmModel = new  checkoutModelDataMiner();
      model.revenue = cart.total;
      model.step = 2;
      dtmModel.revenue = cart.total;
      dtmModel.step = 2;
      dtmModel.orderNumber=orderId

      if (cart.cartModel.discountFull != null && typeof cart.cartModel.discountFull.code !== 'undefined') {
        model.coupon=(cart.cartModel.discountFull.code);
      }

      model['contents'] = [];
      cart.cartModel.cartElements.forEach( (products, index) => {

          let element = new productModelBarion();
          element.id = products.productFull.historyId.toString();
          element.name = products.productFull.name;
          element.quantity = String(products.count);
          element.totalItemPrice = String(products.count * products.productFull.prices["huf"].grossPrice);
          element.unitPrice = String(products.count * products.productFull.prices["huf"].grossPrice);
          model.contents.push(element);
          dtmModel.contents.push(element);

      });
      bp('track', 'initiatePurchase', model);
      fbq('track', 'InitiateCheckout');

      gtag("event", "submit", {
          event_category: "Contact",
          event_label: "General Contact"
      });
      this.sendDataForMinig("Purchase", dtmModel);
      return model;

  }

  grantConsentEvent() {
      bp('consent', 'grantConsent');
      this.sendDataForMinig("grantConsent", {});
  }

  leanEvent(label) {
      gtag("event", "click", {
          event_category: "LeanEvent",
          event_label: label
      });
      this.sendDataForMinig(label, {});
  }

  pageViewDefaultEvent() {
    let width = window.screen.width;
    let height = window.screen.height;
    let language = this.translate.currentLang;
    let browser = window.navigator.appVersion;
    let platform = window.navigator.platform;
    let referrer=document.referrer;
    fbq('track', 'PageView');
    this.sendDataForMinig("PageView", new pageViewModel(width, height, language, browser, platform,referrer));
  }

  afterpaymentReviewEvent() {
     gtag("event", "submit", {
          event_category: "Review",
          event_label: "Purchase Review"
      });
      this.sendDataForMinig("purchaseReview", {});
  }

  pdfDownloadEvent() {
      gtag("event", "click", {
          event_category: "Product",
          event_label: "pdf"
      });
      this.sendDataForMinig("pdfDownload", {});
  }

  setUtmParameters() {
      let oldValue = this.getUtmParameters();
      let utmModel = new UtmModel(
        this.route.snapshot.paramMap.get('utm_source'),
        this.route.snapshot.paramMap.get('utm_medium'),
        this.route.snapshot.paramMap.get('utm_campaign'),
        this.route.snapshot.paramMap.get('utm_content'),
        this.route.snapshot.paramMap.get('utm_term'));
      console.log(utmModel);
      if (oldValue != utmModel) {
        this.storageService.saveToStore('utmParams', utmModel);
      }
  }

  getUtmParameters() {
    return this.storageService.getFromStore('utmParams', new UtmModel('','','','',''));
  }

  changeLanguageEvent(lang) {
    this.sendDataForMinig("changeLanguage", new changeLangModel(lang));
  }

  pageOutLinkEvent(link, linkId){
    this.sendDataForMinig("pageOutLink", new pageOutLinkModel(link, linkId));
  }

  browserActiveEvent(){
    this.sendDataForMinig("browserActive", new browserActive("active"));
  }

  browserInactiveEvent(){
    this.sendDataForMinig("browserActive", new browserActive("inactive"));
  }

  sendStartForm(formName, domElementName){
    this.sendDataForMinig("startForm", new startFormModel(formName, domElementName));
  }

  buttonClickEvent(buttonId, pagePosition, destination){
    this.sendDataForMinig("buttonClick", new buttonClickModel(buttonId, pagePosition, destination));
  }

  getGoogleSessionId() {
      let cc = "unknown";
      let cookie=this.cookieService.get("_ga");


     if(cookie != undefined){
       cc=cookie;
      }
      return cc;
  }

  /** Add Google Analytics Script Dynamically */
  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + Utilities.getGATrackingId();
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', Utilities.getGATrackingId(), { send_page_view: false });
  }

  addBPScript() {
    let bpScript: HTMLScriptElement = document.createElement('script');
    // Insert a script tag on the top of the head to load bp.js
    bpScript = document.createElement("script");
    bpScript.async = true;
    bpScript.src = 'https://pixel.barion.com/bp.js';
    document.head.prepend(bpScript);
    window['barion_pixel_id'] = Utilities.getBarionPixelId();

    // Send init event
    bp('init', 'addBarionPixelId', window['barion_pixel_id']);
  }

  addLinkedInScript(){
    let scriptA: HTMLScriptElement = document.createElement("script");
    scriptA.innerHTML =  '_linkedin_partner_id = "'+Utilities.getLinkedInId()+'";'+
      'window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];'+
      'window._linkedin_data_partner_ids.push(_linkedin_partner_id);';
    let scriptB: HTMLScriptElement = document.createElement("script");
    scriptB.type = "text/javascript";
    scriptB.async = true;
    scriptB.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    document.head.prepend(scriptB);
    document.head.append(scriptA);
  }

}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-order-reservation-elements',
  templateUrl: './order-reservation-elements.component.html',
  styleUrls: ['./order-reservation-elements.component.scss']
})
export class OrderReservationElementsComponent implements OnInit, OnChanges {

  @Input() reservation:any;
  timeEnd:Date;
  timeStart:Date;

  constructor() { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.reservation != undefined){
      const splitTime = this.reservation.reservationTime.split(':');
      let d = new Date(1999,8, 9,splitTime[0] ,splitTime[1], 0, 0);
      let d1 = new Date(d);
      d1.setMinutes(d1.getMinutes() + this.reservation.durationInMinutes);
      this.timeStart = d;
      this.timeEnd = d1;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import {CartService} from "../../services/cart.service";
import {TranslateService} from "@ngx-translate/core";
import {add} from "ngx-bootstrap/chronos";
import {OrderService} from "../../services/order.service";

@Component({
  selector: 'app-order-address',
  templateUrl: './order-address.component.html',
  styleUrls: ['./order-address.component.scss']
})
export class OrderAddressComponent implements OnInit {
  cart: any;
  acceptPrivacy: any;
  acceptTerms: any;
  subscribe: any = false;

  constructor(public cartService: CartService, private translateService: TranslateService, private orderService: OrderService) {
  }

  ngOnInit(): void {
    this.cart = this.cartService.cart;
    console.log(this.cart);
  }

  sendOrderToBackend() {
    this.orderService.finalizeOrder(this.subscribe);
  }

  formattedAddress(address: any) {
    const street = this.translateService.instant('streets.' + address.addressType);
    let country = this.translateService.instant('countries.' + address.country);
    if (address.country.length > 2) {
      country = address.country;
    }
    return country + ', ' + address.zipCode + ' ' + address.city + ', ' + address.address + ' ' + street + ' ' + address.houseNumber;
    /**
     * address: "Magyar"
     addressType: "OTHER"
     city: "Budapest"
     country: "HU"
     houseNumber: "Barátúr u. 1/a"
     zipCode: "7394"*/
  }
}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UserRepository} from "../../repositories/user.repository";

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  private encodedEmail;
  public email;
  public subscribeSuccessful = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private userRepository: UserRepository
  ) { }

  ngOnInit(): void {
    this.encodedEmail = this.activeRoute.snapshot.paramMap.get('encodedEmail');
    this.email = atob(this.encodedEmail);
  }

  unsubscribe() {
    this.userRepository.unsubscribe(this.encodedEmail).subscribe(response => {
      this.subscribeSuccessful = true;
    })
  }
}

import { Component, OnInit } from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {UserRepository} from "../../repositories/user.repository";
import {Constants} from "../../utils/constants";
import {IPayload} from "../../models/IPayload";
import {ToasterService} from "../../services/toaster.service";

export class ProfilePayload{
  public lastName:string;
  public firstName:string;
  public userName:string;

  constructor(lastName: string, firstName: string, userName: string) {
    this.lastName = lastName;
    this.firstName = firstName;
    this.userName = userName;
  }
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public isLoggedIn = false;
  authUser: any = {};
  public profilePayload:ProfilePayload = new ProfilePayload("","", "");

  constructor(private keycloakService: KeycloakService, private  userRepository: UserRepository, private toastr:ToasterService) { }

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if (this.isLoggedIn) {
        this.keycloakService.getKeycloakInstance().loadUserProfile().then(result => {
          this.authUser = result;
          this.profilePayload = new ProfilePayload(result.lastName, result.firstName, result.username);
        });
      }
    });
  }

  resetPassword() {
    this.userRepository.updatePassword(Constants.USER_ACTION.UPDATE_PASSWORD).subscribe(response=>{
      this.toastr.showInfo("info", "profilPage.passwordReset");
      setTimeout(()=>{
        this.keycloakService.logout();
      }, 5000);
    });
  }

  sendProfile() {
    const payload:IPayload = {
      id:0,
      type:"update profile",
      attribute:this.profilePayload
    };
    this.userRepository.updateProfile(Constants.USER_ACTION.UPDATE_PROFILE, payload).subscribe(response=>{
      this.toastr.showSuccess("success", "profilPage.successUpdate");
    });
  }
}

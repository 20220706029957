import {Component, OnInit} from '@angular/core';
import {ProductRepository} from "../../repositories/product.repository";
import {ActivatedRoute} from "@angular/router";
import {CartComponent} from "../cart/cart.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CartService} from "../../services/cart.service";
import {AnalyticsService} from "../../services/analytics.service";
import {CurrencyService} from "../../services/currency.service";
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {Constants} from "../../utils/constants";
import {MyStorageService} from "../../services/my-storage.service";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  bsModalRef: BsModalRef;
  public product: any;
  public personCount: number;
  public images: string[]=[];
  productUrl: any;
  restaurantHistoryId: any;

  constructor(
    private productRepository: ProductRepository,
    private attachmentUrlService:AttachmentUrlService,
    private route: ActivatedRoute,
    private window: Window,
    private cart: CartService,
    private modalService: BsModalService,
    private analyticsService: AnalyticsService,
    private myStorageService: MyStorageService,
    public  currencyService: CurrencyService) {

  }

  ngOnInit(): void {
    let slug = this.route.snapshot.paramMap.get('slug');
    this.restaurantHistoryId = this.myStorageService.getFromCookies(Constants.STORAGE_KEYS.RESTAURANT, 1);
    this.loadSelectedProduct(slug);
  }

  private loadSelectedProduct(slug) {
    this.productRepository.getProductBySlug(slug).subscribe((data: any) => {
      this.product = data;
      this.analyticsService.productViewEvent(this.product);
      this.productUrl = this.attachmentUrlService.getScaleWidthImageUrl(data.attachments[1].attachment.localization, 600)
      this.personCount = data.giftCard.minPurchaseAmount;
    });

  }

  addToCart() {
    this.cart.addItemToShoppingCart(this.product, 1, this.personCount);
    this.bsModalRef = this.modalService.show(CartComponent);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  gotoMainPage() {
    document.location.href = "/"
  }

  decrementPersonCount() {
    if ((this.personCount - 1) >= this.product.giftCard.minPurchaseAmount) {
      this.personCount--;
    }
  }

  incrementPersonCount() {
    if(this.product.giftCard.minPurchaseAmount<=this.product.giftCard.personCount){
      if((this.personCount+1) <=this.product.giftCard.personCount){
        this.personCount++;
      }
    }else{
      this.personCount++;
    }
  }
}

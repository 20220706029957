import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RepositoryService} from './repository.service';
import {Constants} from "../utils/constants";
import {ToasterService} from "../services/toaster.service";

@Injectable({
  providedIn: 'root'
})
export class ProductRepository extends RepositoryService{
  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  public getAllProducts(){
    const path = 'webapi/api/products';
    return super.get(path)
  }

  public getRecommendedProducts(id, restaurantHistoryId){
    const path = 'webapi/api/products/delivery/limit/'+id+'/' + restaurantHistoryId;
    return super.get(path)
  }

  public getProductById(id: number){
    const path = 'webapi/api/products/filter/by-id/' + id.toString();
    return super.get(path)
  }
  public getProductBySlug(slug: string){
    const path = 'webapi/api/products/' +slug;
    return super.get(path)
  }

  public getDeliveryProducts(restaurantHistoryId: number){
    const path = 'webapi/api/products/delivery/filter/'+restaurantHistoryId;
    return super.get(path)
  }

  public getWebshopProducts(){
    const path = 'webapi/api/' + Constants.PRODUCT_TYPES.GIFT_CARD + '/products';
    return super.get(path)
  }

  public getExtraProducts(){
    const path = 'webapi/api/' + Constants.PRODUCT_TYPES.PRODUCT + '/products';
    return super.get(path)
  }


}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RepositoryService} from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyRepository extends RepositoryService {
  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  public getCurrencies(){
    const path = 'webapi/api/currencies';
    return super.get(path)
  }

}

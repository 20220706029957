import {IPayload} from "./IPayload";
import {Constants} from "../utils/constants";
import {Cart, CartElement} from "./cart-models";

export class OrderPayload implements IPayload{
  id: number;
  type: string;
  token: any;
  attribute: OrderPayloadAttribute;

  constructor(user: any, cart: Cart, currencyCode: string, token: any) {
    this.attribute = new OrderPayloadAttribute();
    this.type = Constants.REQUEST_ORIGIN.DELIVERY;
    this.id = 0;
    this.token = token;
    this.attribute.user.email = user.email?user.email:cart.orderData.billingData.email;
    if(user.subscribed) {
      this.attribute.user.subscribed = user.subscribed;
    }
    this.attribute.billingData.id = cart.orderData.billingData.id;
    this.attribute.billingData.firstName = cart.orderData.billingData.firstName;
    this.attribute.billingData.lastName = cart.orderData.billingData.lastName;
    this.attribute.billingData.email = cart.orderData.billingData.email;
    this.attribute.billingData.phoneNumber = cart.orderData.billingData.phoneNumber;
    this.attribute.billingData.taxNumber = cart.orderData.billingData.taxNumber;
    this.attribute.billingData.companyName = cart.orderData.billingData.companyName;
    this.attribute.billingData.address.country = cart.orderData.billingData.address.country;
    this.attribute.billingData.address.city = cart.orderData.billingData.address.city;
    this.attribute.billingData.address.addressType = cart.orderData.billingData.address.addressType;
    this.attribute.billingData.address.address = cart.orderData.billingData.address.address;
    this.attribute.billingData.address.houseNumber = cart.orderData.billingData.address.houseNumber;
    this.attribute.billingData.address.zipCode = cart.orderData.billingData.address.zipCode;
    this.attribute.deliveryData.id = cart.orderData.deliveryData.id;
    this.attribute.deliveryData.firstName = cart.orderData.deliveryData.firstName;
    this.attribute.deliveryData.lastName = cart.orderData.deliveryData.lastName;
    this.attribute.deliveryData.phoneNumber = cart.orderData.deliveryData.phoneNumber;
    this.attribute.deliveryData.email = cart.orderData.deliveryData.email;
    this.attribute.deliveryData.companyName = cart.orderData.deliveryData.companyName;
    this.attribute.deliveryData.address.country = cart.orderData.deliveryData.address.country;
    this.attribute.deliveryData.address.city = cart.orderData.deliveryData.address.city;
    this.attribute.deliveryData.address.addressType = cart.orderData.deliveryData.address.addressType;
    this.attribute.deliveryData.address.address = cart.orderData.deliveryData.address.address;
    this.attribute.deliveryData.address.houseNumber = cart.orderData.deliveryData.address.houseNumber;
    this.attribute.deliveryData.address.zipCode = cart.orderData.deliveryData.address.zipCode;
    this.attribute.cart.secretKey = cart.cartModel.secretKey;
    this.attribute.cart.cartElements = cart.cartModel.cartElements;
    this.attribute.cart.discountId = (cart.cartModel.discountFull == null ? null : cart.cartModel.discountFull.id);
    this.attribute.cart.totalPrice = cart.total;
    this.attribute.message = cart.orderData.message;
    this.attribute.paymentID = cart.orderData.paymentId;
    this.attribute.paymentStatus = cart.orderData.paymentStatus;
    this.attribute.acceptedTerms = true;
    this.attribute.currency = currencyCode;
    this.attribute.registration = user.isRegistration;
    this.attribute.cart.cartElements.forEach((cartElement) => {
      cartElement.productId = cartElement.productFull.id;
    });
  }
}

export class BillingData{
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  taxNumber: string;
  companyName: string;
  address: Address;
  constructor() {
    this.address = new Address();
  }
}

export class Address{
  country: string = null;
  city: string;
  addressType: string;
  address: string;
  houseNumber: string;
  zipCode: string;
}

export class OrderPayloadAttribute {
  user: User;
  billingData: BillingData;
  deliveryData: BillingData;
  cart: OrderPayloadCart;
  message: string;
  paymentID: string;
  paymentStatus: string;
  acceptedTerms: boolean;
  currency: string;
  registration: boolean;
  type: string;
  constructor() {
    this.user = new User('', false);
    this.billingData = new BillingData();
    this.deliveryData = new BillingData();
    this.cart = new OrderPayloadCart();
    this.message = '';
    this.paymentID = null;
    this.paymentStatus = '';
    this.acceptedTerms = true;
    this.currency = 'huf';
    this.registration = false;
    this.type = Constants.REQUEST_ORIGIN.DELIVERY;
  }
}

export class OrderPayloadCart {
  cartElements: CartElement[] = [];
  discountId:number = null;
  totalPrice: number = 0;
  secretKey: string;
}

export class User {
  email: string;
  subscribed: boolean;
  constructor(email: string, subscribed = false) {
    this.email = email;
    this.subscribed = subscribed;
  }
}

class SimpleValueAttribute {
  value: any;
  constructor(value) {
    this.value = value;
  }
}

export class FinalizeOrderPayload implements IPayload{
  id: number;
  type: string;
  token: any;
  attribute: SimpleValueAttribute;
  constructor(token, secretKey) {
    this.id = 0;
    this.type = 'FinalizeOrder';
    this.token = token;
    this.attribute = new SimpleValueAttribute(secretKey);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RepositoryService} from './repository.service';
import {Constants} from "../utils/constants";
import {IPayload} from "../models/IPayload";
import {ToasterService} from "../services/toaster.service";

@Injectable({
  providedIn: 'root'
})
export class OrderRepository extends RepositoryService {
  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  public getAllOrderss(){
    const path = 'webapi/api/orders';
    return super.get(path)
  }

  public getOrderById(orderId: number){
    const path = 'webapi/api/orders/get/' + orderId.toString();
    return super.get(path)
  }
  public getOrderBySecretKey(secretKey: string){
    const path = 'webapi/api/orders/get-by/' + secretKey;
    return super.get(path)
  }

  public getOrderByPaymentId(paymentId: string){
    const path = 'webapi/api/orders/' + paymentId;
    return super.get(path)
  }

  public getOrderedProductsDaily(orderId: number){
    const path = 'webapi/api/orders/ordered-products-daily/' + orderId.toString();
    return super.get(path)
  }

  public getOrderedProductsByDay(orderId: number, date: string){
    const path = 'webapi/api/orders/ordered-products-by-day/' + orderId.toString() + '/' + date;
    return super.get(path)
  }

  public saveOrderReview(review: IPayload){
    const path = 'webapi/api/orders/save-review';
    return super.post(path, review)
  }

  public checkCart(cart: IPayload){
    const path = 'webapi/api/orders/check-cart';
    return super.post(path, cart)
  }

  public saveOrder(order: IPayload){
    const path = 'webapi/api/orders';
    return super.post(path, order);
  }

}

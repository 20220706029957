<div class="row">
  <div class="col-12 px-0 mdlb-slider-img-wrapper">
    <img class="mdlb-welcome-top-image" [src]="topImg"
         alt="Foglalj most, akár ajándékutalvány nélkül is!"
         title="Foglalj most, akár ajándékutalvány nélkül is!" (error)="topImg = 'assets/images/banner_img.jpg'">
  </div>
</div>
<div class="mdlb-welcome-container connect-ui-padding">
  <div class="row w-100 mt-5">
    <div class="col-12">
      <app-stepper [currentStep]="stepNumber" [steps]="steps"></app-stepper>
    </div>
  </div>
  <div class="row mdlb-connect-box ">
    <h2 class="mdlb-order-heading col-12 mb-5" translate="orderSummarizePage.reservationTitle"></h2>
    <div class="col-md-12">
      <app-order-reservation-elements [reservation]="reservations[0]"></app-order-reservation-elements>
    </div>

    <h2 class="mdlb-order-heading col-12 mb-5" translate="orderSummarizePage.packageTitle"></h2>
    <div class="col-md-12">
      <app-order-reservation-product [cartElement]="cartElements[0]"></app-order-reservation-product>
    </div>
  </div>

  <div class="row mdlb-connect-box mt-5">
    <h2 class="mdlb-order-heading col-12 mb-5" translate="orderSummarizePage.guestsTitle"></h2>
    <div class="col-md-12">
      <app-foods [reservation]="reservations[0]"></app-foods>
    </div>
  </div>

  <div class="row mdlb-connect-box mt-5 mb-5">
    <h2 class="mdlb-order-heading col-12 mb-5" translate="orderSummarizePage.addressesTitle"></h2>
    <div class="col-md-12">
      <app-order-address></app-order-address>
    </div>
  </div>

</div>


<div class="row banner-img-wrapper">
  <div class="col-4  banner-img">
    <img [src]="urls[0]"
         alt="{{title | translate}}"
         title="{{title | translate}}">
  </div>
  <div class="col-8  banner-img">
    <img [src]="urls[1]"
         alt="{{title | translate}}"
         title="{{title | translate}}">
  </div>
</div>

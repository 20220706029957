import {IPayload} from "./IPayload";

export class reviewPayload implements IPayload{
  id: number;
  type: string;
  attribute: reviewAttributes;
  constructor(paymentId, rating, message) {
    this.id = 0;
    this.type = 'paymentReview';
    this.attribute = new reviewAttributes(paymentId, rating, message);
  }
}
class reviewAttributes {
  paymentId: string;
  rating: number;
  message: string;
  constructor(paymentId, rating, message) {
    this.paymentId= paymentId;
    this.rating = rating;
    this.message=message;
  }
}


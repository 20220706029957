import { Component, OnInit } from '@angular/core';
import {MyStorageService} from "../../services/my-storage.service";
import {AnalyticsService} from "../../services/analytics.service";

@Component({
  selector: 'app-cookiebar',
  templateUrl: './cookiebar.component.html',
  styleUrls: ['./cookiebar.component.scss']
})
export class CookiebarComponent implements OnInit {
  public  acceptedCookieTab=false;
  constructor(public storage:MyStorageService, public analyticsService:AnalyticsService) { }
  getCookie(){
    this.acceptedCookieTab = this.storage.getFromCookies("acceptCookie", false)

  }
  acceptCookie(){
    this.storage.saveToCookiesStringCustomExpiration("acceptCookie", "true",15);
    this.analyticsService.grantConsentEvent();
    this.acceptedCookieTab=true;
  }
  ngOnInit(): void {
    this.getCookie();
  }

}

import {Constants} from "../utils/constants";
import {BillingData} from "./order-payload";

export class Cart {
  language: string;
  cartModel: CartModel;
  total: number;
  discount: number;
  orderData: OrderData;

  constructor() {
    this.language = 'hu';
    this.cartModel = new CartModel();
    this.total = 0;
    this.discount = 0;
    this.orderData = null;
  }
}

export class CartModel {
  cartElements: CartElement[];
  private _discountFull: Discount;
  discount = {id: null};
  secretKey: string;

  constructor() {
    this.cartElements = [];
    this._discountFull = null;
    this.discount = {id: null};
    this.secretKey = null;
  }


  get discountFull(): Discount {
    return this._discountFull;
  }

  set discountFull(value: Discount) {
    this._discountFull = value;
    if (value == null) {
      this.discount.id = null;
    }
    else {
      this.discount.id = this._discountFull.id;
    }
  }
}

export class Discount {
  id: number;
  code: string;
  type: string;
  discountValue: number;
  prices: any[];
  specificProductIds: any[];
}

export class CartElement {
  productFull: any;
  count: number;
  personCount: number;
  productId:number;
  serial: number;
  restaurantHistoryId: number;
  imgSrc:string = "";

  constructor(productFull: any, count: number, personCount: number, serial: number, restaurantHistoryId: number) {
    this.productFull = productFull;
    this.count = count;
    this.personCount = personCount;
    this.serial = serial;
    this.productId = this.productFull.id;
    this.restaurantHistoryId = restaurantHistoryId;
  }
}

export class OrderData {
  id: number;
  secretKey:string;
  billingData: BillingData;
  deliveryData: BillingData;
  message: string;
  paymentStatus: string;
  paymentId: string;
  billingIsSame:boolean = true;

  constructor(billingData, deliveryData, message, billingIsSame) {
    this.id = null;
    this.billingData = billingData;
    this.deliveryData = deliveryData;
    this.message = message;
    this.paymentStatus = null;
    this.paymentId = null;
    this.billingIsSame = billingIsSame;
  }
}

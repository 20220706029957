export class Stepper {
  title: string;
  uri: string;
  uriToAnalytics: string;
  disabled: boolean;
  external: boolean;

  constructor(title: string, uri: string = null, uriToAnalytics:string = null, external = false, disabled: boolean = false) {
    this.title = title;
    this.uri = uri;
    this.uriToAnalytics = uriToAnalytics;
    this.disabled = disabled;
    this.external = external;
  }
}

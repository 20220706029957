export class BannerLayoutType {
  public isReverse:boolean;
  public contentColSize:string;
  public imgColSize:string;


  constructor(isReverse: boolean, contentColSize: string, imgColSize: string) {
    this.isReverse = isReverse;
    this.contentColSize = contentColSize;
    this.imgColSize = imgColSize;
  }

  public static createL50(){
    return new BannerLayoutType(false, 'col-md-6', 'col-md-6');
  }
  public static createR50(){
    return new BannerLayoutType(true, 'col-md-6', 'col-md-6');
  }
  public static createL33(){
    return new BannerLayoutType(false, 'col-md-4', 'col-md-8');
  }
  public static createL66(){
    return new BannerLayoutType(false, 'col-md-8', 'col-md-4');
  }
  public static createR33(){
    return new BannerLayoutType(true, 'col-md-4', 'col-md-8');
  }
  public static createR66(){
    return new BannerLayoutType(true, 'col-md-8', 'col-md-4');
  }
}
export class BannerLayout {
  static LEFT50:BannerLayoutType = BannerLayoutType.createL50();
  static RIGHT50:BannerLayoutType = BannerLayoutType.createR50();
  static LEFT33:BannerLayoutType = BannerLayoutType.createL33();
  static LEFT66:BannerLayoutType = BannerLayoutType.createL66();
  static RIGHT33:BannerLayoutType = BannerLayoutType.createR33();
  static RIGHT66:BannerLayoutType = BannerLayoutType.createR66();
}


import {BannerLayoutComponentModel} from '../../banner-layout-component.model';
import {AttachmentUrlService} from '../../../../services/attachment-url.service';
import {TranslateService} from '@ngx-translate/core';


export class ImgCommonLayoutComponent implements BannerLayoutComponentModel<any[]> {
  data: any[];
  slug: string;
  isYellow = false;
  urls: any[] = [];
  title = 'banner.defaultImg';

  constructor(public attachmentUrlService: AttachmentUrlService, public translate: TranslateService) {
  }

  protected initImages(): void {
    this.data.forEach(imgKey => {
      console.log(this.slug + '.' + imgKey);
      this.translate.get(this.slug + '.' + imgKey).subscribe(translate => {
        const imgOne = this.attachmentUrlService.getScaleWidthImageUrlBySlug(translate, 800);
        this.urls.push(imgOne);
      });
    });
  }

}

<div class="row mdlb-ui-padding mb-5">
  <div class="col-md-12">
    <h4 class="mdlb-order-heading">{{'delivery.otherThings' | translate}}</h4>
  </div>
</div>
<div class="row mdlb-ui-padding">
  <div class="col-md-12">
    <ul class="nav nav-tabs " id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
           aria-controls="home" aria-selected="true">{{'delivery.deliveryInstructionH' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
           aria-controls="profile" aria-selected="false">{{'delivery.deliveryToolsH' |
            translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
           aria-controls="contact" aria-selected="false">{{'delivery.deliveryCookerH' |
            translate}}</a>
      </li>
    </ul>
    <div class="tab-content" style="margin-top: -40px"  id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <app-banner
          [bannerLayoutType]="tabBannerOne.layout"
          [imgLayoutComponent]="tabBannerOne.img"
          [imgUrls]="tabBannerOne.imgUrls"
          [contentLayoutComponent]="tabBannerOne.content"
          [contentData]="tabBannerOne.contentData"
        ></app-banner>
      </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <app-banner
            [bannerLayoutType]="tabBannerTwo.layout"
            [imgLayoutComponent]="tabBannerTwo.img"
            [imgUrls]="tabBannerTwo.imgUrls"
            [contentLayoutComponent]="tabBannerTwo.content"
            [contentData]="tabBannerTwo.contentData"
          ></app-banner>


      </div>
      <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
        <app-banner
          [bannerLayoutType]="tabBannerThree.layout"
          [imgLayoutComponent]="tabBannerThree.img"
          [imgUrls]="tabBannerThree.imgUrls"
          [contentLayoutComponent]="tabBannerThree.content"
          [contentData]="tabBannerThree.contentData"
        ></app-banner>
      </div>
    </div>
  </div>
</div>

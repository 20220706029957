import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Utilities} from '../utils/utilities';
import {IPayload} from '../models/IPayload';
import {ToasterService} from "../services/toaster.service";

export abstract class RepositoryService {
  private serviceUrl:string;
  protected httpClient: HttpClient;

  protected constructor() {
    this.serviceUrl = Utilities.getServiceUrl();
  }

  public get(path:string, options?:any): Observable<any>{
    return this.httpClient.get(this.serviceUrl+path, options).pipe(retry(0));
  }
  public post(path:string, body:IPayload, options?:any): Observable<any>{
    return this.httpClient.post(this.serviceUrl+path, body, options).pipe(retry(0));
  }
  public put(path:string, body:IPayload, options?:any): Observable<any>{
    return this.httpClient.put(this.serviceUrl+path, body, options).pipe(retry(0));
  }
  public patch(path:string, body?:IPayload, options?:any): Observable<any>{
    return this.httpClient.patch(this.serviceUrl+path, body, options).pipe(retry(0));
  }
  public delete(path:string, options?:any): Observable<any>{
    return this.httpClient.delete(this.serviceUrl+path, options).pipe(retry(0));
  }

}


<div class="row">
  <div class="col-12">

    <table class="table iconImgContainer">
      <thead class="thead-dark">
      <tr>

        <th scope="col" translate="productDetailPage.allergenTitle"></th>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor="let allergen of allergens">
        <td>
          <img class="lazy iconImg mr-2"  src="{{helper.getScaleWidthImageUrl(allergen.attachment.localization, 600)}}"
          alt=""/>  {{allergen.localizations[translate.currentLang].name }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {ProductRepository} from "../../repositories/product.repository";
import {RestaurantRepository} from "../../repositories/restaurant.repository";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CartComponent} from "../cart/cart.component";
import {Constants} from "../../utils/constants";
import {MyStorageService} from "../../services/my-storage.service";


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  public filteredProducts: any[] = [];
  public restaurants: any[] = [];
  public selectedRestaurantId: number;
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private productRepository: ProductRepository,
    private restaurantRepository: RestaurantRepository,
    private storageService: MyStorageService
  ) {
  }

  ngOnInit(): void {
    this.loadAllProducts();
  }

  open() {
    this.bsModalRef = this.modalService.show(CartComponent);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  loadAllProducts() {
    this.selectedRestaurantId = this.storageService.getFromCookies(Constants.STORAGE_KEYS.RESTAURANT, null);
    if (this.selectedRestaurantId) {
      this.productRepository.getDeliveryProducts(this.selectedRestaurantId).subscribe((data: any[]) => {
        data.forEach(product => {
          this.filteredProducts.push({product: product, personCount: product.giftCard.minPurchaseAmount});
        })

      });
    } else {
      this.filteredProducts = [];
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {BannerLayoutComponentModel} from "../../banner-layout-component.model";
import {BannerContentModel} from "../banner-content.model";
import {TranslateService} from "@ngx-translate/core";
import {AnalyticsService} from "../../../../services/analytics.service";

@Component({
  selector: 'app-title-and-link-content',
  templateUrl: './title-and-link-content.component.html',
  styleUrls: ['./title-and-link-content.component.scss']
})
export class TitleAndLinkContentComponent implements OnInit , BannerLayoutComponentModel<BannerContentModel> {
  data: BannerContentModel;
  slug: string;
  isYellow = false;
  constructor(
    private translateService: TranslateService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }

  navigate() {
    const nextPage:string = this.translateService.instant(this.data.link);
    if(nextPage.includes("!#")){
      document.getElementById(nextPage.substr(2)).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }else{
      this.analyticsService.buttonClickEvent(this.data.linkText,'title-description-and-link-content',nextPage);
      window.location.href = nextPage;
    }

  }
}


    <div class="row">
      <div class="col-md-12 px-0">
        <h2 class="mdlb-order-heading" translate="orderdetailsPage.headingLine"></h2>
        <p translate="orderdetailsPage.headingLine3" class="mdlb-order-title" style="margin-bottom: 40px">
          Please provide the
          following data!</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 px-0">
        <form (ngSubmit)="sendOrderToCheck()" ngNativeValidate>
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="text" minlength="2"
                     [(ngModel)]="deliveryAddress.lastName"
                     required
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     name="deliveryAddress.lastName"
                     placeholder="{{'orderdetailsPage.lastName' | translate}}"/>
            </div>
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="text" minlength="2"
                     [(ngModel)]="deliveryAddress.firstName"
                     required
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     name="deliveryAddress.firstName"
                     placeholder="{{'orderdetailsPage.firstName' | translate}}"/>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="email"
                     [(ngModel)]="deliveryAddress.email"
                     required
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     name="deliveryAddress.email"
                     placeholder="{{'orderdetailsPage.emailAddress' | translate}}"/>
            </div>
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="tel" pattern="\+\d{2,3}\d{2}\d{5,8}"
                     placeholder="+36701234567"
                     [(ngModel)]="deliveryAddress.phoneNumber"
                     required
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     name="deliveryAddress.phoneNumber">
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-xs-12">
              <select class="form-control"
                      [(ngModel)]="deliveryAddress.address.country"
                      name="deliveryAddress.country"
                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                      required>
                <option [ngValue]="null" [disabled]="true">{{'countries.placeholder' | translate}}</option>
                <option [ngValue]="deliveryCountry"> {{'countries.' + deliveryCountry | translate}}</option>
              </select>
            </div>
            <div class="col-md-4 col-xs-12">
              <select class="form-control"
                      [(ngModel)]="deliveryAddress.address.city"
                      name="deliveryAddress.city"
                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                      required>
                <option [ngValue]="deliveryCity">{{deliveryCity}}</option>
              </select>
            </div>

            <div class="col-md-4 col-xs-12">
              <input class="form-control" type="text"
                     [(ngModel)]="deliveryAddress.address.zipCode"
                     pattern="[0-9]{4}"
                     name="deliveryAddress.zipCode"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'orderdetailsPage.zipcode' | translate}}"/>

            </div>
          </div>

          <!-- Delivery street + type + houseNumber -->
          <div class="row">
            <div class="col-md-4 col-xs-12">
              <input class="form-control" type="text"
                     [(ngModel)]="deliveryAddress.address.address"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     name="deliveryAddress.address"
                     required
                     placeholder="{{'orderdetailsPage.street' | translate}}"/>
            </div>
            <div class="col-md-4 col-xs-12">
              <select class="form-control"
                      [(ngModel)]="deliveryAddress.address.addressType"
                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                      required
                      name="deliveryAddress.addressType">
                <option *ngFor="let addressType of addressTypes"
                        [ngValue]="addressType"> {{'streets.' + addressType | translate}}</option>
              </select>

            </div>
            <div class="col-md-4 col-xs-12">

              <input class="form-control" type="text"
                     [(ngModel)]="deliveryAddress.address.houseNumber"
                     name="deliveryAddress.houseNumber"
                     required
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'orderdetailsPage.houseNumber' | translate}}"/>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12">
              <div class="custom-control custom-switch invoiceRequired mb-3">
                <input type="checkbox" class="custom-control-input" id="customSwitches2"
                       [(ngModel)]="billingIsSame"
                       name="deliveryIsSame"
                       [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false">
                <label class="custom-control-label"
                       for="customSwitches2">{{'orderdetailsPage.copyData' | translate }}</label>
              </div>
              <div class="custom-control custom-switch invoiceRequired mb-3" *ngIf="billingIsSame==false">
                <input type="checkbox" class="custom-control-input" id="customSwitches"
                       [(ngModel)]="invoiceRequired" name="invoiceRequired" [disabled]="isEditable==false">
                <label class="custom-control-label"
                       for="customSwitches">{{'orderdetailsPage.invoiceRequired' | translate}}</label>
              </div>
            </div>
          </div>

          <!-- Billing first + lastname -->
          <div class="row mt-3" [hidden]="billingIsSame==true && isEditable==true">
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="text" minlength="2"
                     [(ngModel)]="billingAddress.lastName"
                     [required]="billingIsSame==false"
                     name="lastName"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'orderdetailsPage.lastName' | translate}}"/>
            </div>
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="text" minlength="2"
                     [(ngModel)]="billingAddress.firstName"
                     [required]="billingIsSame==false"
                     name="firstName"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'orderdetailsPage.firstName' | translate}}"/>
            </div>
          </div>
          <div class="row" [hidden]="!invoiceRequired && isEditable">
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="text"
                     [(ngModel)]="billingAddress.companyName"
                     name="companyName"
                     [disabled]="cart.cartModel.cartElements.length==0 || invoiceRequired==false || isEditable==false"
                     [required]="invoiceRequired==true"
                     placeholder="{{'orderdetailsPage.company' | translate}}"/>
            </div>
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="text"
                     [(ngModel)]="billingAddress.taxNumber"
                     name="taxNumber"
                     [disabled]="cart.cartModel.cartElements.length==0 || invoiceRequired==false || isEditable==false"
                     [required]="invoiceRequired==true"
                     placeholder="{{'orderdetailsPage.taxNumber' | translate}}"/>
            </div>
          </div>

          <!-- Billing email + phone -->
          <div class="row" [hidden]="billingIsSame==true  && isEditable==true">
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="email"
                     [(ngModel)]="billingAddress.email"
                     [required]="billingIsSame==false"
                     name="email"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'orderdetailsPage.emailAddress' | translate}}"/>
            </div>
            <div class="col-md-6 col-xs-12">
              <input class="form-control" type="tel" pattern="\+\d{2,3}\d{2}\d{5,8}"
                     placeholder="+36701234567"
                     [(ngModel)]="billingAddress.phoneNumber"
                     [required]="billingIsSame==false"
                     name="phoneNumber"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
              />
            </div>
          </div>

          <!-- Billing country + city + zip -->
          <div class="row" [hidden]="billingIsSame==true  && isEditable==true">
            <div class="col-md-4 col-xs-12">
              <input type="text" class="form-control"
                     [(ngModel)]="billingAddress.address.country"
                     name="address.country"
                     [required]="billingIsSame==false"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'countries.placeholder' | translate}}"/>
            </div>
            <div class="col-md-4 col-xs-12">
              <input class="form-control" type="text"
                     [(ngModel)]="billingAddress.address.city"
                     [required]="billingIsSame==false"
                     name="address.city"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'orderdetailsPage.city' | translate}}"/>
            </div>
            <div class="col-md-4 col-xs-12">
              <input class="form-control" type="text"
                     [(ngModel)]="billingAddress.address.zipCode"
                     pattern="[0-9]{4}"
                     [required]="billingIsSame==false"
                     name="address.zipCode"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'orderdetailsPage.zipcode' | translate}}"/>

            </div>
          </div>

          <!-- Billing street + type + houseNumber -->
          <div class="row" [hidden]="billingIsSame==true  && isEditable==true">
            <div class="col-md-4 col-xs-12">
              <input class="form-control" type="text"
                     [(ngModel)]="billingAddress.address.address"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     name="address.address"
                     [required]="billingIsSame==false"
                     placeholder="{{'orderdetailsPage.street' | translate}}"/>
            </div>
            <div class="col-md-4 col-xs-12">
              <select class="form-control"
                      [(ngModel)]="billingAddress.address.addressType"
                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                      [required]="billingIsSame==false"
                      name="address.addressType">
                <option *ngFor="let addressType of addressTypes"
                        [ngValue]="addressType"> {{'streets.' + addressType | translate}}</option>
              </select>

            </div>
            <div class="col-md-4 col-xs-12">
              <input class="form-control" type="text"
                     [(ngModel)]="billingAddress.address.houseNumber"
                     name="address.houseNumber"
                     [required]="billingIsSame==false"
                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                     placeholder="{{'orderdetailsPage.houseNumber' | translate}}"/>
            </div>
          </div>


          <!--TODO ide jönnek a címek -->


          <div class="row ">
            <div class="col-12">
                                <textarea rows="8" class="form-control"
                                          [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"
                                          placeholder="{{'orderdetailsPage.message' | translate }}"
                                          [(ngModel)]="message"
                                          name="message"> </textarea>
            </div>
          </div>

          <div class="row ">
            <div class="col-md-9 col-xs-12"
                 *ngIf="cart.cartModel.discount.type == undefined &&cart.cartModel.cartElements.length!=0  ">
              <input type="text" class="form-control " [(ngModel)]="discountCode" name="discountCode"
                     placeholder="{{ 'orderdetailsPage.coupon' | translate }}">

            </div>
            <div class="col-md-3 col-xs-12"
                 *ngIf="cart.cartModel.discount.type == undefined && cart.cartModel.cartElements.length!=0  ">

              <button type="button" class="btn btn-outline-primary form-control"
                      (click)="checkDiscount()">{{
                  'orderdetailsPage.couponButton' | translate }}
              </button>
            </div>
          </div>
          <div class="row mt-5 mb-5">
            <div class="col-md-12 text-center">
              <button *ngIf="cart.cartModel.cartElements.length>0" class="btn btn-info mdlb-form-button"
                      translate="connectPage.submitButton"></button>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-12 mdlb-footer-barion-frame text-left">
              <p class="mdlb-order-title">{{'orderdetailsPage.detailsTextPay' | translate}}</p>
              <a style="padding-left: -15px" href="https://www.barion.com" target="_blank"
                 title="footerbar.paymentProvider"><img height="32"
                                                        alt="footerbar.paymentProvider"
                                                        src="assets/images/logo/barion-newl.svg" style="">
              </a>
            </div>
          </div>

        </form>
      </div>
    </div>


<!--<div class="row"-->
<!--     [hidden]="(cartService.cart.cartModel.discountFull && cartService.cart.cartModel.discountFull.id && cartService.cart.cartModel.cartElements.length>0) || isEditable==false">-->
<!--  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 formContainer">-->
<!--    <div class="row">-->
<!--      <div class="col-md-12 col-xs-12">-->
<!--        <h2 class="couponHeading mb-3">{{'discount.title' | translate}}</h2>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <div class="col-md-8 col-xs-12"-->
<!--      >-->
<!--        <input type="text" class="form-control couponInput " [(ngModel)]="discountCode" name="discountCode"-->
<!--               placeholder="{{ 'orderdetailsPage.coupon' | translate }}">-->

<!--      </div>-->
<!--      <div class="col-md-4 col-xs-12">-->
<!--        <button type="button" class="btn btn-primary cartButtonBlack"-->
<!--                (click)="checkDiscount()">{{'orderdetailsPage.couponButton' | translate }}-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="row mb-5 mt-3">-->
<!--  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 formContainer">-->


<!--    <form (ngSubmit)="sendOrderToCheck()" ngNativeValidate>-->
<!--      <div class="row">-->
<!--        <div class="col-md-8 offset-md-2">-->
<!--          &lt;!&ndash; Delivery data h2 &ndash;&gt;-->
<!--          <h2 translate="orderdetailsPage.deliveryH2"></h2>-->

<!--          &lt;!&ndash; Delivery first + lastname &ndash;&gt;-->
<!--          <div class="row mt-3">-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text" minlength="2"-->
<!--                     [(ngModel)]="deliveryAddress.lastName"-->
<!--                     required-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     name="deliveryAddress.lastName"-->
<!--                     placeholder="{{'orderdetailsPage.lastName' | translate}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text" minlength="2"-->
<!--                     [(ngModel)]="deliveryAddress.firstName"-->
<!--                     required-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     name="deliveryAddress.firstName"-->
<!--                     placeholder="{{'orderdetailsPage.firstName' | translate}}"/>-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Delivery email + phone &ndash;&gt;-->
<!--          <div class="row">-->
<!--            <div class="col-md-12 col-xs-12">-->
<!--              <input class="form-control" type="email"-->
<!--                     [(ngModel)]="deliveryAddress.email"-->
<!--                     required-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     name="deliveryAddress.email"-->
<!--                     placeholder="{{'orderdetailsPage.emailAddress' | translate}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-12 col-xs-12">-->
<!--              <input class="form-control" type="tel" pattern="\+\d{2,3}\d{2}\d{5,8}"-->
<!--                     placeholder="+36701234567"-->
<!--                     [(ngModel)]="deliveryAddress.phoneNumber"-->
<!--                     required-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     name="deliveryAddress.phoneNumber"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Delivery country + city + zip &ndash;&gt;-->
<!--          <div class="row">-->
<!--            <div class="col-md-12 col-xs-12">-->
<!--              <select class="form-control"-->
<!--                      [(ngModel)]="deliveryAddress.address.country"-->
<!--                      name="deliveryAddress.country"-->
<!--                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                      required>-->
<!--                <option [ngValue]="deliveryCountry"> {{'countries.' + deliveryCountry | translate}}</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <select class="form-control"-->
<!--                      [(ngModel)]="deliveryAddress.address.city"-->
<!--                      name="deliveryAddress.city"-->
<!--                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                      required>-->
<!--                <option [ngValue]="deliveryCity">{{deliveryCity}}</option>-->
<!--              </select>-->
<!--            </div>-->

<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="deliveryAddress.address.zipCode"-->
<!--                     pattern="[0-9]{4}"-->
<!--                     name="deliveryAddress.zipCode"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     placeholder="{{'orderdetailsPage.zipcode' | translate}}"/>-->

<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Delivery street + type + houseNumber &ndash;&gt;-->
<!--          <div class="row">-->
<!--            <div class="col-md-12 col-xs-12">-->
<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="deliveryAddress.address.address"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     name="deliveryAddress.address"-->
<!--                     required-->
<!--                     placeholder="{{'orderdetailsPage.street' | translate}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <select class="form-control"-->
<!--                      [(ngModel)]="deliveryAddress.address.addressType"-->
<!--                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                      required-->
<!--                      name="deliveryAddress.addressType">-->
<!--                <option *ngFor="let addressType of addressTypes"-->
<!--                        [ngValue]="addressType"> {{'streets.' + addressType | translate}}</option>-->
<!--              </select>-->

<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->

<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="deliveryAddress.address.houseNumber"-->
<!--                     name="deliveryAddress.houseNumber"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     placeholder="{{'orderdetailsPage.houseNumber' | translate}}"/>-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Message &ndash;&gt;-->
<!--          <div class="row ">-->
<!--            <div class="col-12">-->
<!--                             <textarea rows="4" class="connectArea h-75"-->
<!--                                       [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                                       placeholder="{{'orderdetailsPage.message' | translate }}"-->
<!--                                       [(ngModel)]="message"-->
<!--                                       name="message"> </textarea>-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Invoice swich &ndash;&gt;-->
<!--          <div class="row ">-->
<!--            <div class="col-12 mt-4">-->

<!--              <div class="custom-control custom-switch invoiceRequired mb-3">-->
<!--                <input type="checkbox" class="custom-control-input" id="customSwitches"-->
<!--                       [(ngModel)]="invoiceRequired" name="invoiceRequired" [disabled]="isEditable==false">-->
<!--                <label class="custom-control-label"-->
<!--                       for="customSwitches">{{'orderdetailsPage.invoiceRequired' | translate}}</label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Billing company + tax &ndash;&gt;-->
<!--          <div class="row mb-4" [hidden]="!invoiceRequired && isEditable">-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="billingAddress.companyName"-->
<!--                     name="companyName"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || invoiceRequired==false || isEditable==false"-->
<!--                     [required]="invoiceRequired==true"-->
<!--                     placeholder="{{'orderdetailsPage.company' | translate}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="billingAddress.taxNumber"-->
<!--                     name="taxNumber"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || invoiceRequired==false || isEditable==false"-->
<!--                     [required]="invoiceRequired==true"-->
<!--                     placeholder="{{'orderdetailsPage.taxNumber' | translate}}"/>-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Delivery-Billing is same switch &ndash;&gt;-->
<!--          <div class="row" *ngIf="isEditable">-->
<!--            <div class="col-12">-->
<!--              <div class="custom-control custom-switch invoiceRequired mb-3">-->
<!--                <input type="checkbox" class="custom-control-input" id="customSwitches2"-->
<!--                       [(ngModel)]="billingIsSame"-->
<!--                       name="deliveryIsSame"-->
<!--                       [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false">-->
<!--                <label class="custom-control-label" for="customSwitches2">{{'orderdetailsPage.copyData' | translate }}</label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Billing data h2 &ndash;&gt;-->
<!--          <h2 translate="orderdetailsPage.billingH2" class="mt-5"-->
<!--              [hidden]="billingIsSame==true && isEditable==true"></h2>-->

<!--          &lt;!&ndash; Billing first + lastname &ndash;&gt;-->
<!--          <div class="row mt-3" [hidden]="billingIsSame==true && isEditable==true">-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text" minlength="2"-->
<!--                     [(ngModel)]="billingAddress.lastName"-->
<!--                     [required]="billingIsSame==false"-->
<!--                     name="lastName"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     placeholder="{{'orderdetailsPage.lastName' | translate}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text" minlength="2"-->
<!--                     [(ngModel)]="billingAddress.firstName"-->
<!--                     [required]="billingIsSame==false"-->
<!--                     name="firstName"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     placeholder="{{'orderdetailsPage.firstName' | translate}}"/>-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Billing email + phone &ndash;&gt;-->
<!--          <div class="row" [hidden]="billingIsSame==true  && isEditable==true">-->
<!--            <div class="col-md-12 col-xs-12">-->
<!--              <input class="form-control" type="email"-->
<!--                     [(ngModel)]="billingAddress.email"-->
<!--                     [required]="billingIsSame==false"-->
<!--                     name="email"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     placeholder="{{'orderdetailsPage.emailAddress' | translate}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-12 col-xs-12">-->
<!--              <input class="form-control" type="tel" pattern="\+\d{2,3}\d{2}\d{5,8}"-->
<!--                     placeholder="+36701234567"-->
<!--                     [(ngModel)]="billingAddress.phoneNumber"-->
<!--                     [required]="billingIsSame==false"-->
<!--                     name="phoneNumber"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Billing country + city + zip &ndash;&gt;-->
<!--          <div class="row" [hidden]="billingIsSame==true  && isEditable==true">-->
<!--            <div class="col-md-12 col-xs-12">-->
<!--              <select class="form-control"-->
<!--                      [(ngModel)]="billingAddress.address.country"-->
<!--                      name="address.country"-->
<!--                      [required]="billingIsSame==false"-->
<!--                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                      >-->

<!--                <option *ngFor="let country of countries"-->
<!--                        [ngValue]="country"> {{'countries.' + country | translate }}</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="billingAddress.address.city"-->
<!--                     [required]="billingIsSame==false"-->
<!--                     name="address.city"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     placeholder="{{'orderdetailsPage.city' | translate}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="billingAddress.address.zipCode"-->
<!--                     pattern="[0-9]{4}"-->
<!--                     [required]="billingIsSame==false"-->
<!--                     name="address.zipCode"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     placeholder="{{'orderdetailsPage.zipcode' | translate}}"/>-->

<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Billing street + type + houseNumber &ndash;&gt;-->
<!--          <div class="row" [hidden]="billingIsSame==true  && isEditable==true">-->
<!--            <div class="col-md-12 col-xs-12">-->
<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="billingAddress.address.address"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     name="address.address"-->
<!--                     [required]="billingIsSame==false"-->
<!--                     placeholder="{{'orderdetailsPage.street' | translate}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--              <select class="form-control"-->
<!--                      [(ngModel)]="billingAddress.address.addressType"-->
<!--                      [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                      [required]="billingIsSame==false"-->
<!--                      name="address.addressType">-->
<!--                <option *ngFor="let addressType of addressTypes"-->
<!--                        [ngValue]="addressType"> {{'streets.' + addressType | translate}}</option>-->
<!--              </select>-->

<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->

<!--              <input class="form-control" type="text"-->
<!--                     [(ngModel)]="billingAddress.address.houseNumber"-->
<!--                     name="address.houseNumber"-->
<!--                     [required]="billingIsSame==false"-->
<!--                     [disabled]="cart.cartModel.cartElements.length==0 || isEditable==false"-->
<!--                     placeholder="{{'orderdetailsPage.houseNumber' | translate}}"/>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
<!--      <div class="row aszfCheckbox" *ngIf="isEditable==false">-->
<!--        <div class="row">-->
<!--          <div class="col-md-8 offset-md-2">-->
<!--            <h2>{{'orderdetailsPage.acceptH2' | translate }}</h2>-->
<!--            <div class="col-12 acceptTerms ">-->
<!--              <label for="acceptPrivacy" class="my-1 mr-2 aszfCheckboxLabel"> <input id="acceptPrivacy"-->
<!--                                                                                     class="form-control "-->
<!--                                                                                     type="checkbox"-->
<!--                                                                                     required/><a-->
<!--                href="{{ 'seohref.privacyPrivacy' | translate}}" target="_blank" title="{{'orderdetailsPage.acceptPrivacy'-->
<!--                            | translate}}"><span class="aszfLink">{{'orderdetailsPage.acceptPrivacy'-->
<!--                | translate}}</span></a></label>-->
<!--            </div>-->
<!--            <div class="col-12 acceptTerms ">-->
<!--              <label for="acceptAszf" class="my-1 mr-2 aszfCheckboxLabel"> <input id="acceptAszf"-->
<!--                                                                                  class="form-control "-->
<!--                                                                                  type="checkbox"-->
<!--                                                                                  required/><a-->
<!--                href="{{ 'seohref.privacyAszf' | translate}}" target="_blank" title="{{'orderdetailsPage.acceptTerms'-->
<!--                            | translate}}"><span class="aszfLink">{{'orderdetailsPage.acceptTerms'-->
<!--                | translate}}</span></a></label>-->
<!--            </div>-->
<!--            <div class="col-12 acceptTerms ">-->
<!--              <label for="subscribe" class="my-1 mr-2 aszfCheckboxLabel">-->
<!--                <input id="subscribe" class="form-control" type="checkbox" name="subscribe" [(ngModel)]="subscribe"/>-->
<!--                <span translate="subscribe.checkboxLabel"></span>-->
<!--              </label>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="row ">-->
<!--        <div class="col-md-6 col-xs-12">-->
<!--          <button class="btn btn-primary cartButton ng-scope" type="button" *ngIf="isEditable==true"-->
<!--                  (click)="gotoMainPage()" translate="shoppingCartModal.continueShopping">-->
<!--          </button>-->

<!--          &lt;!&ndash;  <p-->
<!--               *ngIf="((cartService.cart.cartModel.discountFull | json) == '{}' ||  cartService.cart.cartModel.discountFull ==null) && isEditable==true"-->
<!--               class="detailsText">{{'orderdetailsPage.couponText' | translate}}<a href="javascript:void(0)"-->
<!--                                                                                   (click)="openDiscountModal()"-->
<!--                                                                                   class="font-weight-bold text-black-50"> {{'orderdetailsPage.couponLink' | translate}}</a> {{'orderdetailsPage.couponText2' | translate}}-->
<!--               !</p>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="col-md-6 col-xs-12">-->
<!--          <button class="btn btn-primary cartButtonBlack" *ngIf="isEditable==true"-->
<!--                  translate="orderdetailsPage.submitButton"></button>-->

<!--          <button class="btn btn-primary cartButtonBlack" *ngIf="isEditable==false"-->
<!--          >{{'orderdetailsPage.payButton' | translate}}-->
<!--            : {{cartService.cart.total | number}} {{currencyService.currentCurrencyCode | uppercase}}</button>-->
<!--          <p class="detailsText">{{'orderdetailsPage.detailsTextPay' | translate}}</p>-->
<!--          <a class="barionLogo" href="https://www.barion.com" target="_blank"-->
<!--             alt="footerbar.paymentProvider"><img class="lazy"-->
<!--                                                  alt="footerbar.paymentProvider"-->
<!--                                                  src="../../../assets/images/barion.svg" style="">-->
<!--          </a>-->
<!--        </div>-->

<!--      </div>-->
<!--    </form>-->

<!--  </div>-->
<!--</div>-->


<div class="row product-img-wrapper">
  <div class="col-md-3 col-lg-2 col-sm-12 mdlb-product-img px-sm-1 px-0">
    <img  class="cart-img"  src="{{cartElement.imgSrc}}"
         alt="{{cartElement.productFull.name}}"
         title="{{cartElement.productFull.name}}">
  </div>
  <div class="col-md-9 col-lg-10 col-sm-12 p-0">
    <div class="row">
      <div class="col-10 m-0 p-0">
        <h2 class="mdlb-cart-h2 mt-1">
          {{cartElement.productFull.nameMap[translateService.currentLang]}}
        </h2>
      </div>
      <div class="col-2 text-right p-0">
        <a style="font-size: 23px;" href="#" (click)="removeProduct(cartElement)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 col-md-12 px-0">
        <div class="row">
          <div class="col-12 px-md-1 px-0">
                <span class="badge badge-pill badge-dark mdlb-product-details-price-pill">
                  {{cartElement.productFull.prices[currencyService.currentCurrencyCode].price | number}}
                  {{currencyService.currentCurrencyCode | uppercase}} /
                  {{'product.person' | translate}}
                </span>
            <p class="mdlb-cart-details">{{cartElement.productFull.titleMap[translateService.currentLang]}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 px-0">
        <div class="row">

          <div class="col-12 text-center">
            <div class="row align-items-end justify-content-end"
                 style="margin-bottom: 40px; margin-top: 24px">
              <div class="col-auto">
                <label class="mdlb-cart-label">{{'cart.personCount' | translate}}</label>
                <div class="mdlb-product-person-count-selector">
                                        <span class="mdlb-ps-box ps-link"
                                              (click)="decrementPersonCount(cartElement)">-</span>
                  <span class="mdlb-ps-box ps-count">{{cartElement.personCount}}</span>
                  <span class="mdlb-ps-box ps-link"
                        (click)="incrementPersonCount(cartElement)">+</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <hr/>
            <div class="row">
              <div class="offset-md-4 col-md-8 text-right p-0">
                <label class="mdlb-cart-label">
                  {{cartElement.count}} {{'cart.package' | translate}} /
                  {{cartElement.personCount}} {{'cart.person' | translate}}
                </label>
                <p class="mdlb-product-details-price">
                  {{cartElement.count * cartElement.personCount *
                cartElement.productFull.prices[currencyService.currentCurrencyCode].grossPrice | number}}
                  {{currencyService.currentCurrencyCode | uppercase}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<div class="row banner-img-wrapper mdlb-cart-padding">-->
<!--  <div class="col-md-6 mdlb-product-img px-sm-1 px-0">-->
<!--    <img src="{{cartElement.imgSrc}}"-->
<!--         alt="{{cartElement.productFull.name}}"-->
<!--         title="{{cartElement.productFull.name}}">-->
<!--  </div>-->
<!--  <div class="col-md-6 px-md-3 px-0">-->
<!--    <div class="row">-->
<!--      <div class="col-12 px-sm-1 px-0">-->
<!--        <h2 class="mdlb-cart-h2 mt-sm-1 mt-5">-->
<!--          {{cartElement.productFull.name}}-->
<!--        </h2>-->
<!--      </div>-->
<!--      <div class="col-12 px-md-1 px-0">-->
<!--                <span class="badge badge-pill badge-dark mdlb-product-details-price-pill"-->
<!--                      *ngIf="cartElement.productFull.prices.length!=0"-->
<!--                      itemprop="offers" itemscope itemtype="http://schema.org/Offer">-->
<!--                                <span itemprop="price"-->
<!--                                      content="{{cartElement.productFull.prices[currencyService.currentCurrencyCode].price}}">-->
<!--                                      {{cartElement.productFull.prices[currencyService.currentCurrencyCode].price | number}}-->
<!--                                </span>-->
<!--                                    <span itemprop="priceCurrency"-->
<!--                                          content="{{currencyService.currentCurrencyCode | uppercase}}"> {{currencyService.currentCurrencyCode | uppercase}}</span>-->
<!--                                / {{'product.person' | translate}}-->
<!--                            </span>-->
<!--        <p class="mdlb-cart-details" >{{cartElement.productFull.title}}</p>-->
<!--&lt;!&ndash;        <p class="mdlb-cart-details" *ngIf="!isEditable">{{getRestaurantName()}}, {{cartElement.personCount}} főre </p>&ndash;&gt;-->

<!--        <div class="col-md-12" style="padding: 0 30px 0 0 "-->
<!--             *ngIf="cartElement.productFull.giftCard.optionMap.DELIVERY.optionPackagesWithAndOperation['1'] != undefined">-->

<!--          <ul class="mdlb-product-extra-list"-->
<!--              *ngIf="cartElement.productFull.giftCard.optionMap.DELIVERY.optionPackagesWithAndOperation['1'] != undefined">-->
<!--            <li-->
<!--              *ngFor="let connectedProd of cartElement.productFull.giftCard.optionMap.DELIVERY.optionPackagesWithAndOperation | keyvalue"-->
<!--              class="extraAndContainer">-->
<!--        <span *ngFor="let connectedOrProd of connectedProd.value.productsWithOrOperationNames; let i = index">-->
<!--            <span *ngIf="i>0"> {{'product.orTitle' | translate}}</span>-->
<!--          {{connectedOrProd}}-->
<!--        </span>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <hr />-->
<!--        <div class="row justify-content-between" style="margin-bottom: 40px; margin-top: 24px"-->
<!--             >-->
<!--          <div class="col-md-6">-->
<!--            <label class="mdlb-cart-label">{{'cart.personCount' | translate}}</label>-->
<!--            <div class="mdlb-product-person-count-selector">-->
<!--                                        <span class="mdlb-ps-box ps-link"-->
<!--                                              (click)="decrementPersonCount(cartElement)">-</span>-->
<!--              <span class="mdlb-ps-box ps-count">{{cartElement.personCount}}</span>-->
<!--              <span class="mdlb-ps-box ps-link"-->
<!--                    (click)="incrementPersonCount(cartElement)">+</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-6 text-right">-->
<!--            <p class="mdlb-product-details-price" >-->
<!--              {{cartElement.count * cartElement.personCount *-->
<!--            cartElement.productFull.prices[currencyService.currentCurrencyCode].grossPrice | number}}-->
<!--              {{currencyService.currentCurrencyCode | uppercase}}-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

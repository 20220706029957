import {Component, HostListener, OnInit, Pipe} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AnalyticsService} from './services/analytics.service';
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router} from '@angular/router';
import {Constants} from './utils/constants';
import {MyStorageService} from './services/my-storage.service';
import {RestaurantSelectModalComponent} from './modals/restaurant-select-modal/restaurant-select-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Utilities} from './utils/utilities';
import {RestaurantRepository} from './repositories/restaurant.repository';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private analyticsService: AnalyticsService,
    private myStorage: MyStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private restaurantRepository: RestaurantRepository
  ) {
    this.translate.addLangs(Constants.SYSTEM_LANGUAGES);

    this.analyticsService.addGAScript();
    this.analyticsService.addBPScript();
    this.analyticsService.addLinkedInScript();

    this.router.events.subscribe((event) => {
      /** Code to Track Page View  */
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        });

        this.analyticsService.pageViewDefaultEvent();
      }
    });
  }

  savedRestaurantId: any = null;
  title = 'angularDelivery';

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.analyticsService.browserActiveEvent();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.analyticsService.browserInactiveEvent();

  }

  ngOnInit(): void {
    const defaultLanguage = this.myStorage.getStoredOrDefaultLanguage();
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);

    this.restaurantRepository.getAllForDelivery().subscribe(restaurants => {
      this.myStorage.saveToStore(Constants.STORAGE_KEYS.WHOLE_RESTAURANTS, restaurants);
      this.checkForStoredRestaurant(restaurants);
    });
  }

  private checkForStoredRestaurant(restaurants) {
    this.savedRestaurantId = this.myStorage.getFromCookies(Constants.STORAGE_KEYS.RESTAURANT, null);
    const paramRestaurantHistoryId = this.route.snapshot.queryParamMap.get(Constants.QUERY_PARAMS.RESTAURANT);
    const existingParamHistory = restaurants.find(restaurant => restaurant.historyId == paramRestaurantHistoryId);
    const savedExisting = restaurants.find(restaurant => restaurant.historyId == this.savedRestaurantId);

    if (paramRestaurantHistoryId && existingParamHistory) {
      this.myStorage.saveToCookies(Constants.STORAGE_KEYS.RESTAURANT, parseInt(paramRestaurantHistoryId));
    } else if (!this.savedRestaurantId || !savedExisting) {
      const config = Utilities.createModalConfig({}, 'modal-sm');
      const modalRef: BsModalRef = this.modalService.show(RestaurantSelectModalComponent, config);
      modalRef.content.onCloseWithResponse.subscribe(response => {
        window.location.reload();
      });
    }
  }


}


@Pipe({
  name: 'phone'
})
export class PhonePipe {
  transform(rawNum: string): string {
    rawNum = rawNum.trim().replace(/^\+/, '');
    const firstSectionStr = rawNum.slice(0, 2);
    const midSectionStr = rawNum.slice(2, 5);
    const lastSectionStr = rawNum.slice(5);

    return `+${firstSectionStr} ${midSectionStr} ${lastSectionStr}`;
  }
}

<h2 class="banner-title">{{data.title | translate}}</h2>
<p class="banner-description">{{data.description | translate}}</p>
<div class="row-when-if-need-margin justify-content-center">
  <div class="col">
    <a class="btn btn-primary mdlb-banner-button"
       title="{{data.title | translate}}"
       href="javascript:void(0)"
       (click)="navigate(data.link, data.linkText)">
      {{data.linkText | translate}}
    </a>
    <a class="btn btn-secondary mdlb-banner-button ml-3"
       title="{{data.title | translate}}"
       href="javascript:void(0)"
       (click)="navigate(data.linkTwo, data.linkTwoText)">
      {{data.linkTwoText | translate}}
    </a>
  </div>
</div>

<div class="row mdlb-ui-padding mdlb-product-box">
  <div class="col-12">
    <h1 class="mdlb-product-h1 mt-5">{{'delivery.productList.title' | translate}}</h1>
  </div>
  <div class="col-12 mdlb-product-list">
    <div class="row" id="productList" itemscope itemtype="http://schema.org/ItemList"
         *ngIf="filteredProducts.length>0">
      <div class="col-lg-6 col-xl-4 col-sm-12 mdlb-product-ui-margin" *ngFor="let product of filteredProducts">
        <span itemprop="numberOfItems" class="d-none">{{filteredProducts.length}}</span>
        <app-product [product]="product"></app-product>
      </div>
    </div>
    <div class="row" *ngIf="filteredProducts.length == 0">
      <div class="col-sm-12">
        <p class="w-100 text-center" translate="shop.emptyList"></p>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {ProductComponent} from "./pages/product/product.component";
import {OrderDetailsComponent} from "./pages/order-details/order-details.component";
import {OrderSummarizeComponent} from "./pages/order-summarize/order-summarize.component";
import {ConfirmationComponent} from "./pages/confirmation/confirmation.component";
import {ProfileComponent} from "./pages/profile/profile.component";
import {AppAuthGuard} from "./app.authguard";
import {UnsubscribeComponent} from "./pages/unsubscribe/unsubscribe.component";

const routes: Routes = [{path: "", component: HomeComponent},
  {path: "product/:slug", component: ProductComponent},
  {path: "termek/:slug", component: ProductComponent},
  {path: "produkt/:slug", component: ProductComponent},
  {path: "rendelesi-adatok/:cartSecretKey", component: OrderDetailsComponent},
  {path: "order-details/:cartSecretKey", component: OrderDetailsComponent},
  {path: "rendelesi-osszesito/:cartSecretKey", component: OrderSummarizeComponent},
  {path: "order-summary/:cartSecretKey", component: OrderSummarizeComponent},
  {path: "sikeres-rendeles", component: ConfirmationComponent},
  {path: "confirmation", component: ConfirmationComponent},
  {path: "profil", component: ProfileComponent, canActivate: [AppAuthGuard] },
  {path: "profile", component: ProfileComponent, canActivate: [AppAuthGuard] },
  {path: 'leiratkozas/:encodedEmail', component: UnsubscribeComponent},
  {path: 'unsubscribe/:encodedEmail', component: UnsubscribeComponent}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


import {BrowserModule} from '@angular/platform-browser';
import {NgModule,APP_INITIALIZER} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './pages/home/home.component';
import {ComponentsModule} from "./components/components.module";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ModalModule} from 'ngb-modal';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ComponentLoaderFactory} from "ngx-bootstrap/component-loader";
import {PositioningService} from "ngx-bootstrap/positioning";
import {ProductComponent} from './pages/product/product.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {OrderDetailsComponent} from './pages/order-details/order-details.component';
import {OrderSummarizeComponent} from './pages/order-summarize/order-summarize.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import {LoaderService} from "./services/loader.service";
import {LoaderInterceptor} from "./interceptors/loader.interceptor";
import { ProfileComponent } from './pages/profile/profile.component';
import {initializer} from "./utils/app-init";
import {KeycloakService, KeycloakAngularModule} from 'keycloak-angular';
import {AppAuthGuard} from "./app.authguard";
import {Constants} from "./utils/constants";
import {Utilities} from "./utils/utilities";
import {MyStorageService} from "./services/my-storage.service";
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';
import {DirectiveModule} from "./directive/directive.module";
import { RestaurantSelectModalComponent } from './modals/restaurant-select-modal/restaurant-select-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductComponent,
    OrderDetailsComponent,
    OrderSummarizeComponent,
    ConfirmationComponent,
    ProfileComponent,
    UnsubscribeComponent,
    RestaurantSelectModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    ComponentsModule,
    HttpClientModule,
    KeycloakAngularModule,
    ModalModule,
    FormsModule,
    RecaptchaV3Module,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }), // ToastrModule added
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient, MyStorageService]
      }
    }),
    DirectiveModule
  ],
  providers: [
    LoaderService,
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdBg9gUAAAAAApE7u8jOelM2M_mPngkeHshfDlF'},
    {provide: Window, useValue: window},
    {provide: APP_INITIALIZER, useFactory: initializer, deps: [KeycloakService], multi: true},
    BsModalService,
    ComponentLoaderFactory,
    PositioningService,
    BsModalRef,
    AppAuthGuard,
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function httpTranslateLoader(http: HttpClient, myStorage: MyStorageService) {
  if (!myStorage.checkKey('turnOffTranslations')){
    return new TranslateHttpLoader(http, Utilities.getTranslateUrl(),'/'+Constants.ENVIRONMENT);
  }
  else{
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }
}

<div class="row">
  <div class="col-md-12">
    <div class="row ">
      <div class="col-md-6 col-xs-12 px-0 px-sm-2">
        <h4 class="mdlb-aszf-heading">{{'orderdetailsPage.billingData' | translate }}</h4>
        <div class="row" *ngIf="cart.orderData.billingData.taxNumber">
          <div class="col-md-6 align-self-center text-left px-0">
            <label class="mdlb-summ-label" translate="orderdetailsPage.taxNumber"></label>
          </div>
          <div class="col-md-6 align-self-center text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.billingData.taxNumber}}</p>
          </div>
        </div>
        <div class="row" *ngIf="cart.orderData.billingData.companyName">
          <div class="col-md-6 align-self-center text-left px-0">
            <label class="mdlb-summ-label" translate="orderdetailsPage.companyName"></label>
          </div>
          <div class="col-md-6 align-self-center text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.billingData.companyName}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 align-self-center text-left px-0">
            <label class="mdlb-summ-label" translate="orderdetailsPage.lastName"></label>
          </div>
          <div class="col-md-6 align-self-center text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.billingData.lastName}}</p>
          </div>
        </div>
        <div class="row">

          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label" translate="orderdetailsPage.firstName"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.billingData.firstName}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label" translate="orderdetailsPage.emailAddress"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.billingData.email}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label"
                   translate="orderdetailsPage.phoneNumber"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.billingData.phoneNumber}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label"
                   translate="orderdetailsPage.address"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{formattedAddress(cart.orderData.billingData.address)}}</p>
          </div>
        </div>

      </div>
      <div class="col-md-6 col-xs-12 px-0 px-sm-2">
        <h4 class="mdlb-aszf-heading">{{'orderdetailsPage.deliveryData' | translate }}</h4>
        <div class="row">
          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label" translate="orderdetailsPage.lastName"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.deliveryData.lastName}}</p>
          </div>
        </div>
        <div class="row">

          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label" translate="orderdetailsPage.firstName"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.deliveryData.firstName}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label" translate="orderdetailsPage.emailAddress"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.deliveryData.email}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label"
                   translate="orderdetailsPage.phoneNumber"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{cart.orderData.deliveryData.phoneNumber}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-left px-0">
            <label class="mdlb-summ-label"
                   translate="orderdetailsPage.address"></label>
          </div>
          <div class="col-md-6 text-left px-0">
            <p class="mdlb-summ-span">{{formattedAddress(cart.orderData.deliveryData.address)}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-12 col-xs-12 px-0 px-sm-2" *ngIf="cart.orderData.message!=''">
        <div class="row ">
          <div class="col-md-12 px-0">
            <label class="mdlb-summ-label"
                   translate="orderdetailsPage.message"></label>
          </div>
          <div class="col-md-12 px-0">
            <span class="mdlb-summ-span">{{cart.orderData.message}}</span>
          </div>
        </div>
      </div>
    </div>

    <form (ngSubmit)="sendOrderToBackend()" ngNativeValidate>
      <div class="row">
        <div class="col-md-6 col-xs-12 px-0 px-sm-2">
          <h4 class="mdlb-aszf-heading">{{'orderdetailsPage.acceptH2' | translate }}</h4>
          <div class="form-group">
            <div class="custom-control custom-checkbox mdlb-custom-control">
              <input type="checkbox" class="custom-control-input" id="customCheck2"
                     [(ngModel)]="acceptPrivacy" name="acceptPrivacy"
                     required
              >
              <label class="custom-control-label mdlb-aszf-label" for="customCheck2">
                {{'orderdetailsPage.acceptPrivacy' | translate}}
                <a href="{{ 'seohref.privacyPrivacy' | translate}}"
                   target="_blank"
                   title="{{'orderdetailsPage.acceptPrivacy' | translate}}">
                  {{'orderdetailsPage.linkAcceptPrivacy' | translate}}</a>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox mdlb-custom-control">
              <input type="checkbox" class="custom-control-input " id="customCheck3"
                     [(ngModel)]="acceptTerms" name="acceptTerms"
                     required
              >
              <label class="custom-control-label mdlb-aszf-label" for="customCheck3">
                {{'orderdetailsPage.acceptTerms' | translate}}
                <a href="{{ 'seohref.privacyAszf' | translate}}"
                   target="_blank"
                   title="{{'orderdetailsPage.acceptPrivacy' | translate}}">
                  {{'orderdetailsPage.linkAcceptTerms' | translate}}</a>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox mdlb-custom-control">
              <input name="subscribe" type="checkbox" class="custom-control-input" id="customCheck4"
                     [(ngModel)]="subscribe"
              >
              <label class="custom-control-label mdlb-aszf-label" for="customCheck4">
                {{'orderdetailsPage.subscribeLabel' | translate}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5 mb-5">
        <div class="col-md-12 text-center">
          <button *ngIf="cart.cartModel.cartElements.length!=0" class=" btn btn-info mdlb-form-button"
                  translate="orderdetailsPage.payButton"></button>
        </div>
      </div>

    </form>

    <div class="row ">
      <div class="col-md-12 mdlb-footer-barion-frame text-left ">
        <p class="mdlb-order-title">{{'orderdetailsPage.detailsTextPay' | translate}}</p>
        <a style="padding-left: 15px" href="https://www.barion.com" target="_blank"
           title="footerbar.paymentProvider"><img class="pl-0"
                                                  height="32"
                                                  alt="footerbar.paymentProvider"
                                                  src="assets/images/logo/barion-newl.svg" style="">
        </a>
      </div>
    </div>
  </div>
</div>

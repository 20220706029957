import { Component, OnInit } from '@angular/core';
import {BannerLayoutComponentModel} from "../../banner-layout-component.model";
import {BannerContentModel} from "../banner-content.model";

@Component({
  selector: 'app-title-content',
  templateUrl: './title-content.component.html',
  styleUrls: ['./title-content.component.scss']
})
export class TitleContentComponent implements OnInit, BannerLayoutComponentModel<BannerContentModel> {
  slug: string;
  data: BannerContentModel;
  isYellow = false;

  constructor() { }

  ngOnInit(): void {
  }

}

export class productModelBarion {
  currency: string;
  id: string;
  name: string;
  quantity: string;
  totalItemPrice: string;
  unit: string;
  unitPrice: string;
  direction: string;

  constructor() {
    this.currency = 'HUF';
    this.id  = '';
    this.name = '';
    this.quantity = '';
    this.totalItemPrice = '';
    this.unit = 'pcs.';
    this.unitPrice = '';
    this.direction = '';
  }
}

export class productViewModelBarion {
  contentType: string;
  currency: string;
  id: string;
  name: string;
  quantity: number;
  unit: string;
  unitPrice: string;

  constructor() {
    this.contentType = 'Product';
    this.currency = 'HUF';
    this.id = '';
    this.name = "";
    this.quantity = 1;
    this.unit = 'pcs.';
    this.unitPrice = '';
  }
}

export class checkoutModelBarion {
  contents: any[];
  currency: string;
  revenue: number;
  step: number;
  contentType: string;
  coupon: string;

  constructor() {
    this.contents = [];
    this.currency = 'HUF';
    this.revenue = 0;
    this.step = 0;
    this.contentType = 'Product';
    this.coupon = '';
  }
}
export class checkoutModelDataMiner {
  contents: any[];
  currency: string;
  revenue: number;
  step: number;
  contentType: string;
  coupon: string;
  orderNumber:string;

  constructor() {
    this.contents = [];
    this.currency = 'HUF';
    this.revenue = 0;
    this.step = 0;
    this.contentType = 'Product';
    this.coupon = '';
    this.orderNumber="";
  }
}


export class payStartModelBarion {
  contents: any[];
  currency: string;
  revenue: number;
  step: number;
  contentType: string;
  coupon: string;

  constructor() {
    this.contents = [];
    this.currency = 'HUF';
    this.revenue = 0;
    this.step = 0;
    this.contentType = 'Product';
    this.coupon = '';
  }
}

export class changeLangModel {
  languageCode: string;

  constructor(lang) {
    this.languageCode = lang;
  }
}

export class buttonClickModel {
  buttonId: any;
  pagePosition: any;
  destination: any;

  constructor(buttonId, pagePosition, destination){
    this.buttonId = buttonId;
    this.pagePosition = pagePosition;
    this.destination = destination;
  }
}

export class sendFormModel {
  formName: string;
  question: string;
  answer: string;

  constructor(formName, question) {
    this.formName = formName;
    this.question = question;
    this.answer = '???';
  }
}

export class pageViewModel {
  screen: string;
  language: string;
  referral: string;
  browser: string;
  platform: string;

  constructor(width, height, language, browser, platform,referral ) {
    this.screen = width+"*"+height;
    this.language = language;
    this.referral = referral;
    this.browser = browser;
    this.platform = platform;
  }
}

export class browserActive {
  direction: any;

  constructor (direction) {
    this.direction = direction;
  }
}

export class pageOutLinkModel {
  link: any;
  linkId: any;

  constructor(link, linkId) {
    this.link = link;
    this.linkId = linkId;
  }
}

export class purchaseModelFacebook {
  value: number;
  currency: string;
  contents: any[];
  content_type: string;

  constructor() {
    this.value = 0;
    this.currency = 'HUF';
    this.contents = [];
    this.content_type = 'product';
  }
}

export class purchaseProductModelFacebook {
  id: number;
  quantity: number;

  constructor() {
    this.id= 0;
    this.quantity = 0
  }
}

export class startFormModel{
  domElementName: string;
  formName: string;

  constructor(formName, domElementName){
    this.formName = formName;
    this.domElementName = domElementName;
  }
}

export class purchaseModelAnalytics {
  items: any[];
  shipping: number;
  tax: number;
  currency: string;
  value: number;
  affiliation: string;
  transaction_id: string;

  constructor() {
    this.transaction_id = '';
    this.affiliation = 'shop.makeryworld.com';
    this.value = 0;
    this.currency = 'HUF';
    this.tax = 0;
    this.shipping = 0;
    this.items = [];
  }
}

export class purchaseProductModelAnalytics {
  id: string;
  name: string;
  list_name: string;
  brand: string;
  category: string;
  quantity: number;
  price: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.list_name = 'ProductList';
    this.brand = 'Makery';
    this.category = 'Vouchers';
    this.quantity = 2;
    this.price = '2.0';
  }
}

export class dataMiningModel {
  cookieId: string;
  sourceOrigin: string;
  eventType: string;
  eventSubType: string;
  data: string;
  origin: any;
  restaurantHistoryId: number;

  constructor() {
    this.cookieId = '';
    this.sourceOrigin = '';
    this.eventType = '';
    this.eventSubType = '';
    this.data = '';
    this.origin = {};
  }
}

export class UtmModel {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;

  constructor(utm_source, utm_medium, utm_campaign, utm_content, utm_term) {
    this.utm_source = utm_source;
    this.utm_medium = utm_medium;
    this.utm_campaign = utm_campaign;
    this.utm_content = utm_content;
    this.utm_term = utm_term;
  }
};

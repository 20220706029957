<header>
  <nav class="navbar navbar-dark navbar-expand-xl bg-primary mdlb-header">
    <div class="d-flex flex-grow-1">
      <a class="navbar-brand d-none d-xl-inline-block" href="/">
        <img class="" src="assets/images/logo/makery-logo.svg" alt="Makery Global Webshop"
             title="Makery Global Webshop">
      </a>
      <a class="navbar-brand d-xl-none d-inline-block" href="/">
        <img class="" src="assets/images/logo/makery-logo.svg" alt="Makery Global Webshop"
             title="Makery Global Webshop">
      </a>
      <div class="w-100 text-right mt-4">
        <button class="navbar-toggler badge badge-pill badge-light" type="button" data-toggle="collapse" data-target="#myNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
    <div class="collapse navbar-collapse flex-grow-1 text-right " id="myNavbar">
      <ul class="navbar-nav ml-auto flex-nowrap">
        <li class="nav-item" *ngFor="let element of menuElements">
          <a class="nav-link m-2 menu-item mdlb-color-white mdlb-nav-link"
             href="{{element.seoHref | translate}}{{getNavExtra(element)}}">{{element.title | translate}}</a>
        </li>

        <li class="nav-item">
          <a class="nav-link badge badge-pill badge-light menu-item m-2 p-2" href="javascript:void(0)"
             (click)="selectRestaurant()"
             id="navbarDropdownMenuLink3">
            <img width="22px" src="assets/images/icon/makery.svg"
                 alt="Makery Global Webshop"
                 title="Makery Global Webshop"/>
            <span class="mdlb-header-dropdown mr-2 ml-2 mdlb-color-white">{{selectedRestaurant.baseAddress.city | uppercase}}</span>
          </a>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link badge badge-pill badge-light menu-item m-2 p-2" href="#"
             id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img width="22px" src="assets/images/icon/world.svg" alt="Makery Global Webshop"
                 title="Makery Global Webshop"/>
            <span class="mdlb-header-dropdown mr-2 ml-2 mdlb-color-white">{{translate.currentLang | uppercase}}</span>
          </a>
          <div class="dropdown-menu bg-primary" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item mdlb-color-white"
               href="javascript:void(0)"
               (click)="switchLang('en')">
              {{'headerBar.en' | translate}}
            </a>
            <a class="dropdown-item mdlb-color-white"
               href="javascript:void(0)"
               (click)="switchLang(selectedRestaurant.defaultLanguage)">
              {{'headerBar.'+selectedRestaurant.defaultLanguage | translate}}
            </a>
<!--            <a class="dropdown-item mdlb-color-white"-->
<!--               href="javascript:void(0)"-->
<!--               (click)="switchLang('sk')">-->
<!--              {{'headerBar.slovakian' | translate}}-->
<!--            </a>-->
<!--            <a class="dropdown-item mdlb-color-white"-->
<!--               href="javascript:void(0)"-->
<!--               (click)="switchLang('de')">-->
<!--              {{'headerBar.german' | translate}}-->
<!--            </a>-->
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link badge badge-pill badge-light menu-item m-2 p-2" href="#"
             id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img width="22px" src="assets/images/icon/world.svg" alt="Makery Global Webshop"
                 title="Makery Global Webshop"/>
            <span class="mdlb-header-dropdown mr-2 ml-2 mdlb-color-white">
                           {{currencyService.currentCurrencyCode | uppercase}}
                        </span>
          </a>

          <div class="dropdown-menu bg-primary mdlb-header-dropdown-menu-left"
               aria-labelledby="navbarDropdownMenuLink2">
            <a class="dropdown-item mdlb-color-white"
               href="javascript:void(0)"
               *ngFor="let currency of currencies"
               (click)="changeCurrency(currency.code)">{{currency.code | uppercase}}
            </a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link badge badge-pill mdlb-cart-badge menu-item m-2 p-2"
             (click)="openShoppingCart()" href="javascript:void(0)">
            <img width="22px" src="assets/images/icon/comment.svg"
                 alt="{{'headerBar.shoppingCart' | translate}}"
                 title="{{'headerBar.shoppingCart' | translate}}"/>
          </a>
        </li>

      </ul>
    </div>
  </nav>
</header>
<div class="mdlb-floating-cart">
  <a (click)="openShoppingCart()" href="javascript:void(0)"  title="{{'headerBar.shoppingCart' | translate}}">
    <img width="40px" src="assets/images/icon/comment.svg" alt="{{'headerBar.shoppingCart' | translate}}"  title="{{'headerBar.shoppingCart' | translate}}"/></a>
  <span class="mdlb-cart-counter">{{ productCount }}</span>
</div>


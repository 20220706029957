import {Component, Input, OnInit} from '@angular/core';
import {OrderRepository} from "../../repositories/order.repository";
import {reviewPayload} from "../../models/review-payload";
import {AnalyticsService} from "../../services/analytics.service";

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input() paymentId: string;
  public  rate: number = 5;
  public  review: string = "";
  public payload: reviewPayload;
  public  ratingSubmitted: boolean=false;

  constructor(
    private orderRepository: OrderRepository,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }
  submitReview(){
    this.payload= new reviewPayload(this.paymentId,this.rate,this.review);
    this.orderRepository.saveOrderReview(this.payload).subscribe((data: any) => {
      this.ratingSubmitted=true
    });
    this.analyticsService.afterpaymentReviewEvent();
  }
}

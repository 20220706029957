
<div class="card mdlb-product-card h-100" itemprop="itemListElement" itemscope itemtype="http://schema.org/Product">

    <span class="badge badge-pill badge-dark mdlb-product-price" *ngIf="product.product.prices.length!=0"
          itemprop="offers" itemscope itemtype="http://schema.org/Offer">
        <span itemprop="price"
              content="{{product.product.prices[currencyService.currentCurrencyCode].price}}">
            {{product.product.prices[currencyService.currentCurrencyCode].price | number}}
        </span>
        <span itemprop="priceCurrency" content="{{currencyService.currentCurrencyCode | uppercase}}"> {{currencyService.currentCurrencyCode | uppercase}}</span>
        / {{'product.person' | translate}}

    </span>
  <img class="card-img-top" itemprop="image"
       src="{{urlService.getScaleWidthImageUrl(product.product.attachments[1].attachment.localization, 600)}}"
       alt="{{product.product.name}}"/>
  <div class="card-body" style="">
    <h2 class="card-title">{{product.product.name}}</h2>
    <p class="card-text">{{product.product.title}}</p>

  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col-xl-6 col-lg-12 mb-xl-0 mb-2  px-md-1 px-0">
        <a href="javascript:void(0)"
           (click)="openLink()"
           class="btn btn-outline-primary w-100">
          {{'product.details' | translate}}
        </a>
      </div>
      <div class="col-xl-6 col-lg-12 mb-lg-2 mb-sm-2 mb-xs-2 px-md-1 px-0" >

        <a href="javascript:void(0)" class="btn btn-primary w-100"
           (click)="addToShoppingCart(product.product, product.personCount)"
        > {{'product.toCart' | translate}}</a>
      </div>
    </div>
  </div>
</div>



<!--<div class="card h-100 " itemprop="itemListElement" itemscope itemtype="http://schema.org/Product">-->
<!--  <div class="card-img-top cardHeader">-->
<!--    &lt;!&ndash;<div class="cardOverlay d-block d-md-none d-inline-block">-->
<!--      <div class="deliveryEyeButtonHolder "><a-->
<!--        href="{{ 'seohref.product' | translate }}/{{product.product.slug}}/{{product.personCount}}"-->
<!--        class="btn deliveryEyeButton"></a></div>-->
<!--      <div class="deliveryCartButtonHolder"><a href="#" class="btn  deliveryCartButton"-->
<!--                                               (click)="addToShoppingCart(product.product, product.personCount)"></a>-->
<!--      </div>-->

<!--    </div>&ndash;&gt;-->
<!--    <div>-->
<!--      <img-->
<!--        class="lazy deliveryImg " itemprop="image"-->
<!--        src="{{helper.getScaleWidthImageUrl(product.product.attachments[1].attachment.localization, 600)}}"-->
<!--        alt="{{product.product.name}}"/>-->

<!--      <p class="card-text priceDeliveryTag d-flex align-items-end" [hidden]="product.product.prices.length!=0"-->
<!--         itemprop="offers" itemscope itemtype="http://schema.org/Offer">-->
<!--                               <span>-->
<!--                                    <span itemprop="price"-->
<!--                                    >{{product.product.prices[currencyService.currentCurrencyCode].grossPrice | number}} </span>-->
<!--                <span-->
<!--                  itemprop="priceCurrency"-->
<!--                > {{currencyService.currentCurrencyCode | uppercase}}&nbsp;/ {{ 'main.personCountTitle' | translate }}</span>-->
<!--                                </span>-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="card-body">-->
<!--    <h2 class="card-title"-->
<!--        itemprop="name">{{product.product.name }}-->


<!--      <span class="personCountImageContainer" *ngIf="product.personCount<7">-->
<!--                            <span class="personCountImages"-->
<!--                                  *ngFor="let count of [].constructor(product.personCount)">-->
<!--                                <img class="lazy" src="../../../assets/images/person.svg"-->
<!--                                     alt="{{product.personCount}} {{ 'main.personCountTitle' | translate}}"/>-->
<!--                            </span>-->
<!--                                </span>-->
<!--      <span class="personCountImageContainer" *ngIf="product.personCount>=7">-->
<!--                            <span class="personCountImages"-->
<!--                                  *ngFor="let count of [].constructor(7)">-->
<!--                                <img class="lazy" src="../../../assets/images/person.svg"-->
<!--                                     alt="{{product.personCount}} {{ 'main.personCountTitle' | translate}}"/>-->
<!--                            </span> +-->
<!--                                </span>-->

<!--    </h2>-->
<!--    <h2 class="row mt-2">-->
<!--            <span class="col-sm-12 text-left" style="">-->
<!--                <a-->
<!--                  style="color:#171716; text-decoration: none; margin-right: 5px; position: relative; top:-3px; border-radius: 100px; background: #fabc09; padding: 0px 6px"-->
<!--                  href="javascript:void(0)" (click)="decrementPersonCount()">-</a>-->
<!--                <span style="color:#171716; font-size: 20px;" class="ng-binding">{{product.personCount}}</span>-->
<!--                <a-->
<!--                  style="color:#171716; text-decoration: none; margin: 0px 5px;  position: relative; top:-3px;  border-radius: 100px; background: #fabc09; padding: 0px 6px"-->
<!--                  href="javascript:void(0)" (click)="incrementPersonCount()">+</a>-->
<!--                <span style="color:#171716; font-size: 20px;"-->
<!--                      class="ng-binding">{{ 'main.personCountTitle' | translate}}</span>-->
<!--                 <span style="color:#171716; font-size: 20px;"> (<span style="color:#171716; font-size: 20px;"-->
<!--                                    >{{product.product.prices[currencyService.currentCurrencyCode].grossPrice*product.personCount | number}} </span>-->
<!--                <span-->
<!--                  style="color:#171716; font-size: 20px;"-->
<!--                > {{currencyService.currentCurrencyCode | uppercase}})</span>-->
<!--                                </span>-->
<!--            </span>-->
<!--    </h2>-->
<!--    <div itemprop="description">-->
<!--      <app-extra-info-->
<!--        [optionPackage]="product.product.giftCard.optionMap.DELIVERY.optionPackagesWithAndOperation " [restaurants]="product.product.restaurants"></app-extra-info>-->
<!--    </div>-->

<!--    <button class="btn  cartButton" (click)="addToShoppingCart(product.product, product.personCount)"-->
<!--            translate="product.buy"></button>-->
<!--  </div>-->

<!--</div>-->

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RepositoryService} from './repository.service';
import {Constants} from "../utils/constants";
import {ToasterService} from "../services/toaster.service";

@Injectable({
  providedIn: 'root'
})
export class DiscountRepository extends RepositoryService {
  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  public getDiscountByCodeAndOrigin(code: string, origin: string){
    const path = 'webapi/api/discounts/' + code;
    const options = { params: new HttpParams().append('origin', origin) };
    return super.get(path,options)
  }

}

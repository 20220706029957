<div id="cookie-bar" class="bg-primary mdlb-footer-cookie-bar" *ngIf="!acceptedCookieTab">
  <div class="row">

    <div class="col-md-7 text-right">
            <span class="mdlb-cookie-text">
            {{ 'cookiebar.text' |translate}}
            </span>
    </div>
    <div class="col-md-4 text-right">
      <button (click)="acceptCookie()" rel="nofollow noopener noreferrer" class="btn-info btn"
              id="cookie-bar-button">{{'cookiebar.button' |translate}}
      </button>
    </div>

  </div>
</div>

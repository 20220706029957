import {Component, Input, OnInit} from '@angular/core';
import {CartService} from "../../services/cart.service";
import {CurrencyService} from "../../services/currency.service";

@Component({
  selector: 'app-order-elements',
  templateUrl: './order-elements.component.html',
  styleUrls: ['./order-elements.component.scss']
})
export class OrderElementsComponent implements OnInit {
  @Input() isEditable: boolean= true;
  @Input() discountRemovable: boolean= true;
  @Input() stepper: number;
  public cart: any={};

  constructor(
    public cartService:CartService,
    public currencyService: CurrencyService
  ) { }

  ngOnInit(): void {
    this.cart=this.cartService.cart;
  }

}

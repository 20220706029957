<div class="row  mdlb-ui-padding connect-ui-padding">
  <div class="col-12 text-center">
    <h1 class="mdlb-connect-h1" translate="orderdetailsPage.summarizeHeading"></h1>
  </div>
  <div class="col-md-12  mdlb-connect-box">
    <div class="row">
      <div class="col-md-12 px-0">
        <h2 class="mdlb-order-heading px-0" style="margin-bottom: 40px"
            translate="orderdetailsPage.headingLineProducts"></h2>
      </div>
    </div>
    <div *ngIf="cart.cartModel.cartElements.length==0">
      <span translate="shoppingCartModal.cartEmpty"></span>
    </div>
    <div class="row" style="" *ngIf="cart.cartModel.cartElements.length>0">
      <div class="col-md-12 px-0">
        <app-cart-elements *ngFor="let element of cartService.cart.cartModel.cartElements" [cartElement]="element"
                           [isEditable]="isEditable"></app-cart-elements>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <hr/>
        <p class="mdlb-summ-discount">
          <span>  {{ 'orderdetailsPage.discountSumText' | translate }} </span>
          <span>{{cartService.getDiscountAmount() | number : 0}} {{currencyService.currentCurrencyCode | uppercase }}</span>
        </p>

        <p class="mdlb-summ-price">
          <span>  {{ 'orderdetailsPage.sumText' | translate }}</span>
          <span>: {{cartService.refreshTotal() | number : 0}}  {{currencyService.currentCurrencyCode | uppercase }}</span>

        </p>
      </div>
    </div>
  </div>
</div>
<!--  <div class="row my-5">-->
<!--    <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0">-->
<!--      <h1 translate="orderdetailsPage.summarizeHeading" *ngIf="isEditable==true"></h1>-->
<!--      <h1 translate="orderdetailsPage.finalizeHeading" *ngIf="isEditable==false"></h1>-->
<!--      <div class="row">-->
<!--        <div class="col-md-4 col-xs-12 ">-->
<!--          <h2 translate="orderdetailsPage.headingLineProducts"></h2>-->
<!--        </div>-->
<!--        <div class="col-md-8 col-xs-12 ">-->
<!--          <p class="w-100 align-items-end goBackToOrder" *ngIf="isEditable==true">{{'orderdetailsPage.backToMenu' | translate}}<a-->
<!--            href="/"> {{'orderdetailsPage.backToMenuLink' |  translate}}</a></p>-->

<!--          <div *ngIf="cart.cartModel.cartElements.length==0">-->
<!--            <span translate="orderdetailsPage.cartEmpty"></span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mt-5">-->
<!--        <div class="col-12">-->
<!--          <div *ngFor="let element of cartService.cart.cartModel.cartElements">-->
<!--            <app-cart-elements [cartElement]="element" [isEditable]="isEditable"></app-cart-elements>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row" *ngIf="(cartService.cart.cartModel.discountFull | json) != '{}' && cartService.cart.cartModel.discountFull !=null">-->
<!--    <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0">-->
<!--      <h2 translate="orderdetailsPage.discountHeading"></h2>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row mt-5" *ngIf="(cartService.cart.cartModel.discountFull | json) != '{}' && cartService.cart.cartModel.discountFull !=null">-->
<!--    <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0">-->
<!--      <div class="orderList" *ngIf="(cartService.cart.cartModel.discountFull | json) != '{}'">-->
<!--        <div class="card mb-3">-->
<!--          <div class="row no-gutters">-->
<!--            <div class="col-md-3">-->
<!--              <img class="lazy card-img" src="../../../assets/images/kupon.jpg"-->
<!--                   alt="{{cartService.cart.cartModel.discountFull.code}}"/>-->
<!--            </div>-->
<!--            <div class="col-md-9 ">-->
<!--              <div class="card-body">-->
<!--                <h2 class="card-title">{{cartService.cart.cartModel.discountFull.code}}-->
<!--                </h2>-->
<!--                <p class="card-text priceTag">-->
<!--                  {{ 'orderdetailsPage.discountText' | translate }}-->

<!--                </p>-->
<!--                <a class="trashLink" *ngIf="discountRemovable"><img-->
<!--                  class="lazy" src="../../../assets/images/trash.svg" (click)="cartService.removeDiscount()"></a>-->

<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->


<!--    </div>-->
<!--  </div>-->
<!-- <div class="row my-5">-->
<!--    <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0">-->
<!--      <div class="modal-footer" *ngIf="cart.cartModel.cartElements.length!=0">-->
<!--        <div class="row">-->
<!--          <div class="col-12">-->
<!--            <p *ngIf="(cartService.cart.cartModel.discountFull | json) != '{}' && cartService.cart.cartModel.discountFull !=null">-->
<!--              <span>  {{ 'orderdetailsPage.discountSumText' | translate }} </span>-->
<!--              <span>{{cartService.cart.discount | number }} {{currencyService.currentCurrencyCode | uppercase }}</span>-->
<!--            </p>-->

<!--            <p>-->
<!--              <span>  {{ 'orderdetailsPage.sumText' | translate }}</span>-->
<!--              <span>: {{cartService.cart.total | number }}  {{currencyService.currentCurrencyCode | uppercase }}</span>-->

<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


<!--  &lt;!&ndash;-->
<!--  <div class="row " [hidden]="cartService.getRemaining()>0">-->
<!--    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 ">-->
<!--      <div class="row">-->
<!--        <div class="col-md-6 col-xs-12">-->
<!--          <h2 translate="orderdetailsPage.headingLine" class="mt-5 ng-scope"></h2>-->
<!--        </div>-->
<!--        <div class="col-md-6 col-xs-12">-->
<!--          <p translate="orderdetailsPage.headingLine3" class="goBackToOrder mt-5 ng-scope"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>&ndash;&gt;-->

import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastr: ToastrService,
              public translate: TranslateService,) {
  }

  showInfo(title, message) {
    this.toastr.info(this.translate.instant(message), this.translate.instant(title));
  }

  showSuccess(title, message) {
    this.toastr.success(this.translate.instant(message), this.translate.instant(title));
  }

  showWarning(title, message) {
    this.toastr.warning(this.translate.instant(message), this.translate.instant(title));
  }

  showWarningRawMessage(title, message) {
    this.toastr.warning(message, this.translate.instant(title));
  }

  showError(title, message) {
    this.toastr.error(this.translate.instant(message), this.translate.instant(title));
  }

  showErrorWithoutTranslate(message, title?) {
    this.toastr.error(message, this.translate.instant(title ? title : 'general.error'));
  }

  showErrorRawMessage(title, message) {
    this.toastr.error(message, this.translate.instant(title));
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-extra-info',
  templateUrl: './extra-info.component.html',
  styleUrls: ['./extra-info.component.scss']
})
export class ExtraInfoComponent implements OnInit {
  @Input() optionPackage:any;
  @Input() restaurants:any[];
  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MenuElement, MenuService} from '../../services/menu.service';
import {Utilities} from "../../utils/utilities";
import {MyStorageService} from "../../services/my-storage.service";
import {RestaurantRepository} from "../../repositories/restaurant.repository";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {CustomNavigationService} from "../../services/custom-navigation.service";
import {CurrencyService} from "../../services/currency.service";
import {Constants} from "../../utils/constants";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public menuElements: MenuElement[];
  public restaurants: any[];
  public barionPixelId: string;
  public linkedInId: string;

  isProduction: boolean;
  turnOffTranslations: boolean;
  externalNavigationExtra = "";

  constructor(public translate: TranslateService,
              private menuService: MenuService,
              private restaurantService: RestaurantRepository,
              private dataManipulation: DataManipulationService,
              private window: Window,
              private customNavigationService: CustomNavigationService,
              private currencyService: CurrencyService,
              private myStorage: MyStorageService) {
    this.barionPixelId = Utilities.getBarionPixelId();
    this.linkedInId = Utilities.getLinkedInId();

    this.menuElements = menuService.getMenu().filter(element => {
      return element.show == "onFooter" || element.show == "onMenuAndFooter";
    });

    this.currencyService.currencyCodeChange.subscribe(value => {
      this.setExternalNavigationExtra();
    });
  }

  ngOnInit(): void {
    this.isProduction = Utilities.isProduction();
    this.turnOffTranslations = this.myStorage.checkKey('turnOffTranslations');
    this.setExternalNavigationExtra();
    this.loadRestaurants();
  }

  private setExternalNavigationExtra() {
    this.externalNavigationExtra = this.customNavigationService.extendUrlWithLangCurrencyAndRestaurantHistoryId("");
  }

  translateOnOff() {
    if (!this.turnOffTranslations) {
      this.myStorage.deleteFromCookies('turnOffTranslations');
    } else {
      this.myStorage.saveToCookies('turnOffTranslations', {});
    }
    this.window.location.reload();
  }

  private loadRestaurants() {
    let restaurants = this.myStorage.getFromStore(Constants.STORAGE_KEYS.WHOLE_RESTAURANTS, []);
    restaurants = this.dataManipulation.addGetNameMethodToList(restaurants);
    restaurants = this.dataManipulation.addLocaleFromBaseAddressToList(restaurants);
    this.restaurants = restaurants;
  }

  getNavExtra(menuElement: MenuElement) {
    return menuElement.external ? this.externalNavigationExtra : "";
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AttachmentUrlService} from "../../services/attachment-url.service";

@Component({
  selector: 'app-drink-element',
  templateUrl: './drink-element.component.html',
  styleUrls: ['./drink-element.component.scss']
})
export class DrinkElementComponent implements OnInit {
  @Input() drink:any;

  constructor(
    public translate: TranslateService,
    private attachmentUrlService:AttachmentUrlService,
  ) { }

  ngOnInit(): void {
    if(this.drink){
      this.drink.imgSrc = this.attachmentUrlService.getScaleWidthImageUrl(this.drink.attachment.localization, 600)
    }
  }

}

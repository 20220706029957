import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AttachmentUrlService} from "../../services/attachment-url.service";

@Component({
  selector: 'app-food-elements',
  templateUrl: './food-elements.component.html',
  styleUrls: ['./food-elements.component.scss']
})
export class FoodElementsComponent implements OnInit {

  @Input() food:any;

  constructor(public translate: TranslateService,private attachmentUrlService:AttachmentUrlService) { }


  ngOnInit(): void {
    if(this.food){
      this.food.imgSrc =this.attachmentUrlService.getScaleWidthImageUrl(this.food.recipes[0].attachments[1].attachment.localization, 600)
    }
  }

}

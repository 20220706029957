import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-guest-element',
  templateUrl: './guest-element.component.html',
  styleUrls: ['./guest-element.component.scss']
})
export class GuestElementComponent implements OnInit {

  constructor() { }
  @Input() guest:any={};
  ngOnInit(): void {
  }

}

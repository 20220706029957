import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {CartService} from "../../services/cart.service";
import {TranslateService} from "@ngx-translate/core";
import {CartRepository} from "../../repositories/cart.repository";
import {Cart} from "../../models/cart-models";
import {AnalyticsService} from "../../services/analytics.service";
import {CurrencyService} from "../../services/currency.service";
import {Constants} from "../../utils/constants";
import {Utilities} from "../../utils/utilities";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    public cartService: CartService,
    public translate:TranslateService,
    private cartRepository: CartRepository,
    private analyticsService: AnalyticsService,
    public currencyService: CurrencyService
  ) { }

  ngOnInit(): void {}

  gotoMainPage() {
    this.bsModalRef.hide();
    document.location.href = "/";
  }

  handlePayment() {
    if (this.cartService.cart.cartModel.secretKey == null) {
      this.cartRepository.save(this.cartService.cart.cartModel, this.currencyService.currentCurrencyCode).subscribe(response => {
        this.cartService.cart.cartModel.secretKey = response.value;
        this.cartService.saveCart();
        this.analyticsService.initiateCheckoutEvent(this.cartService.cart, 0);
        this.cartService.redirectToFoodSelector();
      });
    } else {
      this.analyticsService.initiateCheckoutEvent(this.cartService.cart, 0);
      this.cartService.redirectToFoodSelector();
    }
  }
}

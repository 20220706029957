<div class="row" *ngIf="reservation != undefined">
  <div class="col-lg-3">
    <label translate="reservationDate.restaurant"></label>
    <span class="form-control-view">{{reservation.restaurantName ? reservation.restaurantName : reservation.restaurant.getName()}}</span>
  </div>
  <div class="col-lg-3">
    <label translate="reservationDate.date"></label>
    <span class="form-control-view">{{reservation.reservationDate | date: 'yyyy.MM.dd'}}</span>
  </div>
  <div class="col-lg-3">
    <label translate="reservationDate.time"></label>
    <span class="form-control-view">{{timeStart | date:'HH:mm'}} - {{timeEnd | date:'HH:mm'}}</span>
  </div>
  <div class="col-lg-3">
    <label translate="reservationDate.personCount"></label>
    <span class="form-control-view">{{reservation.personCount}} {{'product.person' | translate}}</span>
  </div>
  <div class="col-12" *ngIf="reservation.survey.length > 0">
    <label translate="reservation.wantedEventType"></label>
    <div class="form-control-view"><span *ngFor="let event of reservation.survey; let i = index">{{i == 0 ? '' : ', '}}{{event | translate}}</span></div>
  </div>
</div>

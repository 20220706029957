import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ProductRepository} from "../../repositories/product.repository";

@Component({
  selector: 'app-recommended-product',
  templateUrl: './recommended-product.component.html',
  styleUrls: ['./recommended-product.component.scss']
})
export class RecommendedProductComponent implements OnInit {

  @Input() productId: number;
  @Input() restaurantHistoryId: number;
  products: any[] = [];

  constructor(private productRepository: ProductRepository) {
  }

  ngOnInit(): void {
    if (this.productId && this.restaurantHistoryId) {
      this.productRepository.getRecommendedProducts(this.productId, this.restaurantHistoryId).subscribe(products => {
        this.products = products;
      })
    }
  }

}

import {Component, OnInit} from '@angular/core';
import {BannerLayoutComponentModel} from "../../banner-layout-component.model";
import {BannerContentModel} from "../banner-content.model";

@Component({
  selector: 'app-title-and-desc-content',
  templateUrl: './title-and-desc-content.component.html',
  styleUrls: ['./title-and-desc-content.component.scss']
})
export class TitleAndDescContentComponent implements OnInit, BannerLayoutComponentModel<BannerContentModel> {
  data: BannerContentModel;
  slug: string;
  isYellow = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}

<div class="row banner-img-wrapper">
  <div class="col-12 banner-img-half-padding px-0 px-md-4">
    <img [src]="urls[0]"
         alt="{{title | translate}}"
         title="{{title | translate}}">
  </div>
  <div class="col-12 px-0 px-md-4">
    <div class="row row-when-if-need-margin">
      <div class="col-6  banner-img-half-padding">
        <img [src]="urls[1]"
             alt="{{title | translate}}"
             title="{{title | translate}}">
      </div>
      <div class="col-6  banner-img-half-padding" >
        <img [src]="urls[2]"
             alt="{{title | translate}}"
             title="{{title | translate}}">
      </div>
    </div>
  </div>
</div>

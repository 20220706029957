export class Constants {
  public static DEFAULT_LANGUAGE = 'en';
  public static SYSTEM_LANGUAGES = ['hu','en','de','sk'];
  public static ENVIRONMENT = 'DELIVERY';
  public static DEFAULT_CURRENCY = 'huf';
  public static DEFAULT_PERSON_COUNT = 2;
  public static USER_ACTION = {
    UPDATE_PASSWORD: "update-password",
    UPDATE_PROFILE: "update-profile"
  };
  public static ADDRESS_CONST = {
    COUNTRIES: [{id: 'HU', itemName: 'HU'}, {id: 'UK', itemName: 'UK'}],
    CITIES: [{id: 'BUDAPEST', itemName: 'BUDAPEST'}],
    ADDRESS_TYPES: [{id: 'ROAD', itemName: 'ROAD'}, {id: 'STREET', itemName: 'STREET'}, {id: 'CIRCLEROAD', itemName: 'CIRCLEROAD'}]
  };
  public static ISIMMEDIATEDELIVERY = [];
  public static STORAGE_KEYS = {
    LANGUAGE:'LANGUAGE',
    CURRENCY:'CURRENCY',
    RESTAURANT:'RESTAURANT',
    WHOLE_RESTAURANTS:'WHOLE_RESTAURANTS'
  };
  public static QUERY_PARAMS = {
    LANGUAGE:'lang',
    CURRENCY:'currency',
    RESTAURANT:'restaurantHistoryId'
  };

  public static PRODUCT_TYPES = {
    GIFT_CARD: 'GIFT_CARD',
    DIY_AT_HOME: 'DIY_AT_HOME',
    PRODUCT: 'PRODUCT',
    SPECIAL_EVENT: 'SPECIAL_EVENT'
  };

  public static VOUCHER_STATUSES = {
    USEABLE: 'usable',
    REJECTED: 'reject',
    USED: 'used'
  };

  public static RESTAURANT_SERVICE_TYPES = {
    RESTAURANT: 'RESTAURANT',
    DELIVERY: 'DELIVERY',
    BOTH: 'BOTH'
  };

  public static REQUEST_ORIGIN = {
    WEBSHOP: 'WEBSHOP',
    DELIVERY: 'DELIVERY'
  };

  public static DELIVERY_MIN = {
    'huf':12000,
    'eur':40
  };

  public static KAFKA_TOPICS = {
    ANALYTICS: 'analytics'
  } ;
  public static AVAILABLE_COUNTRIES= ['HU'];
  public static AVAILABLE_CITIES= ['BUDAPEST'];
  public static ADDRESS_TYPES=[
    'STREET', 'ROAD', 'SQUARE', 'PF',
    'MINE',
    'TUNNEL',
    'LOWER_EMBANKMENT',
    'ARBORETUM',
    'HIGHWAY',
    'CAVE',
    'CRAWLERS',
    'ACCESS_ROAD',
    'MINE_SITE',
    'BASTION',
    'INN',
    'BOATHOUSE',
    'HILL',
    'VINEYARD',
    'VINEYARDS__',
    'UNIVERSITY_CITY',
    'OTHER',
    'BRANCHING',
    'MEMORIAL_ROAD',
    'FORESTERS',
    'FOREST',
    'ALLEY',
    'UPPER',
    'TURN',
    'MAIN_SQUARE',
    'HIGHWAY_',
    'FACTORY',
    'PLANT',
    'FACTORY_TOWN',
    'FRUITY',
    'DAM',
    'BORDER_ROAD',
    'MOUNTAIN',
    'PUBLIC_',
    'PARCEL_NUMBER',
    'HOUSE',
    'BRIDGEHEAD',
    'SCHOOL',
    'PLAYGROUND',
    'GATE',
    'CASTLE',
    'GARDEN',
    'KERTSOR',
    'DISTRICT',
    'VIEWPOINT',
    'KIOSK',
    'REMISE',
    'COLONY',
    'DRAWING_COMPASS',
    'CULTURAL_PARK',
    'HUT',
    'DISEASE',
    'CIRCUS',
    'AREA',
    'CIRCUS_',
    'BOULEVARD',
    'PUBLIC__',
    'WELL',
    'HOUSE_',
    'RESIDENTIAL_QUARTER',
    'GATED_COMMUNITY',
    'RESIDENTIAL',
    'SLOPE',
    'MATURING',
    'PARK',
    'STAIRS',
    'MAJEURE',
    'MILL',
    'HOSPICE',
    'LOW_ROAD',
    'DRIVEWAY',
    'SIDE',
    'JOY',
    'PARK_',
    'PARK__',
    'PARKING',
    'PARTY',
    'PAVILION',
    'MARKET',
    'RESTING',
    'CELLAR',
    'CELLARS',
    'MAILBOX',
    'BARE',
    'COURT',
    'STATION',
    'WHARF',
    'AIRPORT',
    'PART',
    'MEADOW',
    'CORNER',
    'ROW',
    'ROW_',
    'SPORTS_COURT',
    'SPORTS_GROUND',
    'STADIUM',
    'LIDO',
    'AVENUE',
    'AGENT',
    'ISLAND',
    'PUMPING',
    'VINEYARDS',
    'VINEYARDS_',
    'RAMPART',
    'PROMENADE',
    'FARM',
    'FARMSTEADS',
    'SITE',
    'GRAVEYARD',
    'TERE',
    'ROOF',
    'HUTS',
    'WINTER_HARBOR',
    'SPACE',
    'BLOCK',
    'COURT_',
    'ROADS',
    'STREET_',
    'DEER_PARK',
    'HUNTING_LODGE',
    'RAILWAY_STATION',
    'RAILWAY_GUARD_HOUSE',
    'TRAIN_STATION',
    'ROAD_LEADING',
    'VILLASOR',
    'SLAUGHTERHOUSE',
    'CASTLE_',
    'WATERWORKS',
    'VALLEY',
    'SLUICE',
    'NOOK',
    'STATION_',
    'SHADOW',
    'DITCH',
    'PASSAGEWAY',
    'GUARDHOUSE',
    'GUARD_HOUSES',
    'BEAT',
    'PATH',
    'MAJOR_ROAD_HOUSE',
    'RECREATION',
    'COAST_RESORT',
    'RESORT_ROW',
    'COTTAGE_COLONY'
  ];
}

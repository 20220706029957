import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MenuElement, MenuService} from '../../services/menu.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CartComponent} from '../cart/cart.component';
import {KeycloakService} from 'keycloak-angular';
import {Constants} from '../../utils/constants';
import {MyStorageService} from '../../services/my-storage.service';
import {AnalyticsService} from '../../services/analytics.service';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {CurrencyService} from '../../services/currency.service';
import {CustomNavigationService} from '../../services/custom-navigation.service';
import {CartService} from '../../services/cart.service';
import {Utilities} from '../../utils/utilities';
import {RestaurantSelectModalComponent} from '../../modals/restaurant-select-modal/restaurant-select-modal.component';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  languageChangeEvent: Subject<string> = new Subject<string>();
  public localeList: any[] = [
    {code: 'hu', label: 'Magyar'},
    {code: 'en', label: 'Angol'}
  ];
  public isLoggedIn = false;

  authUser: any = {};
  bsModalRef: BsModalRef;
  public menuElements: MenuElement[];
  currencies: any[] = [];
  private externalNavigationExtra = '';
  selectedRestaurant: any;
  productCount = 0;

  constructor(
    public translate: TranslateService,
    private menuService: MenuService,
    public myStorageService: MyStorageService,
    public keycloakService: KeycloakService,
    private modalService: BsModalService,
    private window: Window,
    private router: Router,
    private route: ActivatedRoute,
    public currencyService: CurrencyService,
    private analyticsService: AnalyticsService,
    private cartService: CartService,
    private customNavigationService: CustomNavigationService,
  ) {
    this.menuElements = menuService.getMenu().filter(element => {
      return element.show == 'onMenu' || element.show == 'onMenuAndFooter';
    });
    this.cartService.productCountRefresher = this.productCountRefresher();
  }


  ngOnInit(): void {
    this.checkForForceParams();
    this.loadData();
    this.refreshExternalNavigationExtra();
    const historyId = this.myStorageService.getFromCookies(Constants.STORAGE_KEYS.RESTAURANT, null);
    let restaurants = this.myStorageService.getFromStore(Constants.STORAGE_KEYS.WHOLE_RESTAURANTS, []);
    restaurants = restaurants.find(restaurant => restaurant.historyId == historyId);
    if (restaurants) {
      this.selectedRestaurant = restaurants;
      console.log(this.selectedRestaurant);
    }
    this.cartService.refreshCartObject();
  }

  private checkForForceParams() {
    this.router.events.subscribe((event: ActivationEnd) => {
      /** force language from param */
      const forcedLang = this.route.snapshot.queryParamMap.get(Constants.QUERY_PARAMS.LANGUAGE);
      const storedLang = this.myStorageService.getFromCookies(Constants.STORAGE_KEYS.LANGUAGE, null);
      if (forcedLang && Constants.SYSTEM_LANGUAGES.includes(forcedLang) && (forcedLang != storedLang)) {
        this.translate.use(forcedLang);
        this.myStorageService.saveToCookies(Constants.STORAGE_KEYS.LANGUAGE, forcedLang);
        window.location.reload();
      }
    });

    this.router.events.subscribe((event: ActivationEnd) => {
      /** force currency */
      const currency = this.route.snapshot.queryParamMap.get(Constants.QUERY_PARAMS.CURRENCY);
      if (currency) {
        this.changeCurrency(currency);
        this.checkStoredCurrencyAvailable();
      }
    });
  }

  private loadData() {
    this.currencyService.getCurrencies().subscribe(currencies => {
      this.currencies = currencies;
      this.checkStoredCurrencyAvailable();
    });
  }

  changeCurrency(currencyCode: string) {
    this.currencyService.saveToStore(currencyCode);
    this.refreshExternalNavigationExtra();
  }

  checkStoredCurrencyAvailable() {
    if (this.currencies.length > 0) {
      // Ha van eltárolt currency, akkor megvizsgáljuk, hogy az valid-e
      const storedCurrency = this.currencyService.getFromStore(null);
      if (storedCurrency) {
        if (!this.currencies.find(currency => currency.code == storedCurrency)) {
          this.changeCurrency(Constants.DEFAULT_CURRENCY);
        }
      }
    }
  }

  switchLang(lang: string): void {
    this.translate.use(lang);
    this.myStorageService.saveToCookies(Constants.STORAGE_KEYS.LANGUAGE, lang);
    this.analyticsService.changeLanguageEvent(lang);
    window.location.reload();
  }


  openShoppingCart() {
    this.bsModalRef = this.modalService.show(CartComponent);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  logout() {
    this.keycloakService.logout(this.window.location.origin);
  }

  login() {
    this.keycloakService.login().then(result => {
      console.log(result);
    });
  }

  register() {
    this.keycloakService.register().then(result => {
      console.log(result);
    }).catch(error => {
      console.log(error);
    });
  }

  private refreshExternalNavigationExtra() {
    this.externalNavigationExtra = this.customNavigationService.extendUrlWithLangCurrencyAndRestaurantHistoryId('');
  }

  getNavExtra(menuElement: MenuElement) {
    return menuElement.external ? this.externalNavigationExtra : '';
  }

  selectRestaurant() {
    const config = Utilities.createModalConfig({}, 'modal-sm');
    config.backdrop = true;
    const modalRef: BsModalRef = this.modalService.show(RestaurantSelectModalComponent, config);
    modalRef.content.onCloseWithResponse.subscribe(response => {
      this.cartService.deleteCartData();
      window.location.reload();
    });
  }

  private productCountRefresher() {
    return (count) => {
      this.productCount = count;
    };
  }
}


<div class="container-fluid  noSliderImg" style="background-color: #171716">
  <div class="row ">
    <div class="slider-img-wrapper w-100">
      <div class="img-overlay  d-flex align-items-center">
        <div class="d-block flex-fill">

        </div>
        <video class="video-background w-100"  webkit-playsinline="true"
               playsinline="true"
             muted  loop  preload poster="../../../assets/images/main_img.jpg" #videoPlayer>
          <!--<source src="../../../assets/videos/main_final.mp4" type="video/mp4">-->
        </video>
      </div>
    </div>
  </div>
</div>


<div>
            <span class="mdlb-cart-extra-title mb-1">
                    <i class="fa fa-truck mr-2"></i>
              {{'main.extraTitle' | translate}}
              {{'main.onDelivery' | translate}}
            </span>
  <ul class="list-bullets">
    <ng-container *ngFor="let connectedProd of optionPackage | keyvalue">
      <li>
        <ng-container *ngFor="let connectedOrProd of connectedProd.value.productsWithOrOperationNames; let i = index">
          {{connectedOrProd}} <span *ngIf="i< connectedProd.value.productsWithOrOperationNames.length-1">{{'product.orTitle' | translate}} </span>
        </ng-container>
      </li>
    </ng-container>

  </ul>
</div>

<div class="container-fluid mb-5">
  <div class="row">
    <div class="img-wrapper">
      <div class="img-overlay"></div>
      <img class="lazy" src="../../../assets/images/t.jpg" class="rowImg"
           alt="{{'subscribe.headingLine' | translate}}"
           title="{{'subscribe.headingLine' | translate}}"/>
    </div>
  </div>

  <div class="row formContainer">
    <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 mb-2">
      <div class="row">
        <div class="col-md-12 mt-5">
          <h1 class="my-1 centered" translate="subscribe.headingLine"></h1>
          <p *ngIf="!subscribeSuccessful" class="my-1  centered" translate="subscribe.descriptionText"></p>
          <p *ngIf="subscribeSuccessful" class="my-1  centered" translate="subscribe.subscibeSuccessfulText"></p>
          <p class="my-1 centered"><b>{{email}}</b></p>

          <div *ngIf="!subscribeSuccessful" class="centered buttonWrapper">
            <button class="btn btn-primary longButton mt-3" (click)="unsubscribe()" translate="subscribe.unsubscribetButton"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



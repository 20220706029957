import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RepositoryService} from './repository.service';
import {Constants} from "../utils/constants";
import {IPayload} from "../models/IPayload";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {Utilities} from "../utils/utilities";
import {ToasterService} from "../services/toaster.service";

@Injectable({
  providedIn: 'root'
})
export class DataMiningRepository extends RepositoryService{
  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }
  public sendDataForMining(data: any){
    const path = 'dataminer/api/raw-data/save';
    return this.post(path, data)
  }
}

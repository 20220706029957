export class BannerContentModel {
  private _title: string;
  private _description: string;
  private _link: string;
  private _linkText: string;
  private _linkTwo: string;
  private _linkTwoText: string;
  private _show: string;
  private _slug: string;

  constructor(show: string, title: string, description?: string, link?: string, linkText?: string, linkTwo?: string, linkTwoText?: string) {
    this._show = show;
    this._title = title;
    this._description = description ? description : '';
    this._link = link ? link : '';
    this._linkText = linkText ? linkText : '';
    this._link = linkTwo ? linkTwo : '';
    this._linkText = linkTwoText ? linkTwoText : '';
  }

  get show(): string {
    return this._slug + '.' + this._show;
  }

  set show(value: string) {
    this._show = value;
  }

  get slug(): string {
    return this._slug;
  }

  set slug(value: string) {
    this._slug = value;
  }

  get title(): string {
    return this._slug + '.' + this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get description(): string {
    return this._slug + '.' + this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get link(): string {
    return this._slug + '.' + this._link;
  }

  set link(value: string) {
    this._link = value;
  }

  get linkText(): string {
    return this._slug + '.' + this._linkText;
  }

  set linkText(value: string) {
    this._linkText = value;
  }

  get linkTwo(): string {
    return this._slug + '.' + this._linkTwo;
  }

  set linkTwo(value: string) {
    this._linkTwo = value;
  }

  get linkTwoText(): string {
    return this._slug + '.' + this._linkTwoText;
  }

  set linkTwoText(value: string) {
    this._linkTwoText = value;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ViewAnchorDirective} from "./view-anchor.directive";



@NgModule({
  declarations: [ViewAnchorDirective],
  imports: [
    CommonModule
  ],
  exports:[
    ViewAnchorDirective
  ]
})
export class DirectiveModule { }

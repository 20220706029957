<div class="mdlb-delivery-ui-gray text-left">
  <h3>{{'delivery.greyh3' | translate}}</h3>
  <p>{{'delivery.greyp' | translate}}</p>
  <div class="row mt-5">
    <div class="col-md-4 text-center">
      <div class="badge badge-pill mdlb-dummy-icon">
        <img src="assets/images/icon/gift.svg" title="" alt="">
      </div>
      <h3>{{'home.sixPack.gift.name' | translate}}</h3>
      <p>{{'home.sixPack.gift.description' | translate}} </p>
    </div>
    <div class="col-md-4 text-center">
      <div class="badge badge-pill mdlb-dummy-icon">
        <img src="assets/images/icon/partner.svg" title="" alt="">
      </div>
      <h3>{{'home.sixPack.partner.name' | translate}}</h3>
      <p>{{'home.sixPack.partner.description' | translate}} </p>
    </div>
    <div class="col-md-4 text-center">
      <div class="badge badge-pill mdlb-dummy-icon">
        <img src="assets/images/icon/main_course.svg" title="" alt="">
      </div>
      <h3>{{'home.sixPack.main_course.name' | translate}}</h3>
      <p>{{'home.sixPack.main_course.description' | translate}} </p>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-4 text-center">
      <div class="badge badge-pill mdlb-dummy-icon">
        <img src="assets/images/icon/cook.svg" title="" alt="">
      </div>
      <h3>{{'home.sixPack.cook.name' | translate}}</h3>
      <p>{{'home.sixPack.cook.description' | translate}} </p>
    </div>
    <div class="col-md-4 text-center">
      <div class="badge badge-pill mdlb-dummy-icon">
        <img src="assets/images/icon/family.svg" title="" alt="">
      </div>
      <h3>{{'home.sixPack.family.name' | translate}}</h3>
      <p>{{'home.sixPack.family.description' | translate}} </p>
    </div>
    <div class="col-md-4 text-center">
      <div class="badge badge-pill mdlb-dummy-icon">
        <img src="assets/images/icon/teambuilding.svg" title="" alt="">
      </div>
      <h3>{{'home.sixPack.teambuilding.name' | translate}}</h3>
      <p>{{'home.sixPack.teambuilding.description' | translate}} </p>
    </div>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from "./header/header.component";
import {FooterComponent} from './footer/footer.component';
import {SliderComponent} from './slider/slider.component';
import {ProductListComponent} from './product-list/product-list.component';
import {CartComponent} from './cart/cart.component';
import {TranslateModule} from "@ngx-translate/core";
import {VideoplayerComponent} from './videoplayer/videoplayer.component';
import {StepperComponent} from './stepper/stepper.component';
import {ExtraInfoComponent} from './extra-info/extra-info.component';
import {FooterTabComponent} from './footer-tab/footer-tab.component';
import {ModalModule} from "ngb-modal";
import {CartListComponent} from './cart-list/cart-list.component';
import {ProductDetailsComponent} from './product-details/product-details.component';
import {AllergensComponent} from './allergens/allergens.component';
import {OrderElementsComponent} from './order-elements/order-elements.component';
import {CartElementsComponent} from './cart-elements/cart-elements.component';
import {FormsModule} from "@angular/forms";
import {OrderFormComponent} from './order-form/order-form.component';
import {ProductComponent} from './product/product.component';
import {FoodsComponent} from './foods/foods.component';
import {FoodElementsComponent} from './food-elements/food-elements.component';
import {ReservationElementsComponent} from './reservation-elements/reservation-elements.component';
import {GuestElementComponent} from './guest-element/guest-element.component';
import {LoaderComponent} from "./loader/loader.component";
import {CookiebarComponent} from './cookiebar/cookiebar.component';
import {RatingComponent} from './rating/rating.component';
import {NgbRatingModule} from "@ng-bootstrap/ng-bootstrap";
import {ProductCounterComponent} from './product-counter/product-counter.component';
import {DrinkElementComponent} from './drink-element/drink-element.component';
import {BannerComponent} from './banner/banner.component';
import {ImgLayout33Component} from './banner/banner-img-layout/img-layout33/img-layout33.component';
import {DirectiveModule} from "../directive/directive.module";
import {ImgLayout66Component} from './banner/banner-img-layout/img-layout66/img-layout66.component';
import {ImgLayoutOneComponent} from './banner/banner-img-layout/img-layout-one/img-layout-one.component';
import {ImgLayoutThreeComponent} from './banner/banner-img-layout/img-layout-three/img-layout-three.component';
import {ImgLayoutVerticalThreeComponent} from './banner/banner-img-layout/img-layout-vertical-three/img-layout-vertical-three.component';
import {ImgLayoutFourComponent} from './banner/banner-img-layout/img-layout-four/img-layout-four.component';
import {TitleAndDescContentComponent} from './banner/banner-content-layout/title-and-desc-content/title-and-desc-content.component';
import {TitleAndLinkContentComponent} from './banner/banner-content-layout/title-and-link-content/title-and-link-content.component';
import {TitleDescAndLinkContentComponent} from './banner/banner-content-layout/title-desc-and-link-content/title-desc-and-link-content.component';
import {TitleContentComponent} from './banner/banner-content-layout/title-content/title-content.component';
import {OrderAddressComponent} from './order-address/order-address.component';
import {OrderReservationElementsComponent} from './order-reservation-elements/order-reservation-elements.component';
import {OrderReservationProductComponent} from './order-reservation-product/order-reservation-product.component';
import {PhonePipe} from "../app.component";
import { StaticGrayBannerComponent } from './static-gray-banner/static-gray-banner.component';
import { RecommendedProductComponent } from './recommended-product/recommended-product.component';
import {
  TitleDescTwoLinkContentComponent
} from "./banner/banner-content-layout/title-desc-two-link-content/title-desc-two-link-content.component";


@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    ProductListComponent,
    CartComponent,
    VideoplayerComponent,
    StepperComponent,
    ExtraInfoComponent,
    FooterTabComponent,
    CartListComponent,
    ProductDetailsComponent,
    AllergensComponent,
    OrderElementsComponent,
    CartElementsComponent,
    OrderFormComponent,
    ProductComponent,
    FoodsComponent,
    FoodElementsComponent,
    ReservationElementsComponent,
    GuestElementComponent,
    CookiebarComponent,
    RatingComponent,
    ProductCounterComponent,
    BannerComponent,
    DrinkElementComponent,
    ImgLayout33Component,
    ImgLayout66Component,
    ImgLayoutOneComponent,
    ImgLayoutThreeComponent,
    ImgLayoutVerticalThreeComponent,
    ImgLayoutFourComponent,
    TitleAndDescContentComponent,
    TitleAndLinkContentComponent,
    TitleDescAndLinkContentComponent,
    TitleDescTwoLinkContentComponent,
    TitleContentComponent,
    OrderAddressComponent,
    OrderReservationElementsComponent,
    OrderReservationProductComponent,
    PhonePipe,
    StaticGrayBannerComponent,
    RecommendedProductComponent
  ],
  exports: [
    BannerComponent,
    ImgLayout33Component,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    ProductListComponent,
    CartComponent,
    VideoplayerComponent,
    StepperComponent,
    ExtraInfoComponent,
    FooterTabComponent,
    CartListComponent,
    ProductDetailsComponent,
    AllergensComponent,
    OrderElementsComponent,
    CartElementsComponent,
    OrderFormComponent,
    ProductComponent,
    FoodsComponent,
    FoodElementsComponent,
    ReservationElementsComponent,
    GuestElementComponent,
    CookiebarComponent,
    RatingComponent,
    ProductCounterComponent,
    BannerComponent,
    DrinkElementComponent,
    OrderAddressComponent,
    OrderReservationElementsComponent,
    OrderReservationProductComponent,
    PhonePipe,
    StaticGrayBannerComponent,
    RecommendedProductComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    FormsModule,
    NgbRatingModule,
    DirectiveModule
  ]
})
export class ComponentsModule {
}

import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss']
})
export class VideoplayerComponent implements OnInit {
  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  @Input()  videoFile: string;

  constructor() {
  }

  ngOnInit(): void {
    //setTimeout(()=>{this.videoPlayer.nativeElement.play();}, 3000);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CartRepository} from "../../repositories/cart.repository";
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {Utilities} from "../../utils/utilities";

@Component({
  selector: 'app-order-summarize',
  templateUrl: './order-summarize.component.html',
  styleUrls: ['./order-summarize.component.scss']
})
export class OrderSummarizeComponent implements OnInit {
  public reservations:any[] = [];
  public cartElements:any[] = [];
  public readonly stepNumber: number = 4;
  topImg: any;
  public steps;
  private secretKey: string;
  constructor(
    private route: ActivatedRoute,
    private cartRepository: CartRepository,
    private attachmentUrlService:AttachmentUrlService
  ) {

    this.secretKey = this.route.snapshot.paramMap.get('cartSecretKey');
    this.steps = Utilities.getDeliveryReservationSteps(this.secretKey);
  }

  ngOnInit(): void {
    this.topImg = this.attachmentUrlService.getScaleWidthImageUrlBySlug('delivery-top-background', 1400);
    this.cartRepository.getBySecretKey(this.secretKey).subscribe(response => {
      this.cartElements = response.cartElements;
      for (let cartElement of response.cartElements){
        for(let deliveryReservation of cartElement.deliveryReservations) {
          this.reservations.push(deliveryReservation.reservation);
        }
      }
    })
  }

}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Utilities} from "../../utils/utilities";
import {Constants} from "../../utils/constants";
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {BannerLayout} from "../../components/banner/banner-layout.model";
import {ImgLayout66Component} from "../../components/banner/banner-img-layout/img-layout66/img-layout66.component";
import {
  ImgLayoutThreeComponent
} from "../../components/banner/banner-img-layout/img-layout-three/img-layout-three.component";
import {
  ImgLayoutVerticalThreeComponent
} from "../../components/banner/banner-img-layout/img-layout-vertical-three/img-layout-vertical-three.component";
import {BannerContentModel} from "../../components/banner/banner-content-layout/banner-content.model";
import {BannerAttributesInterface} from "../../components/banner/banner-attributes.interface";
import {TranslateService} from "@ngx-translate/core";
import {CustomNavigationService} from "../../services/custom-navigation.service";
import {
  TitleDescAndLinkContentComponent
} from "../../components/banner/banner-content-layout/title-desc-and-link-content/title-desc-and-link-content.component";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('clickSim', {static: true}) clickSim: ElementRef;
  shopTopBg = {
    hu: {slug: 'delivery-top-background'},
    en: {slug: 'delivery-top-background'},
    de: {slug: 'delivery-top-background'},
    sk: {slug: 'delivery-top-background'}
  };
  topImg = "banner_img.jpg";
  redeemLink:string;

  bannerOne: BannerAttributesInterface = {
    content: TitleDescAndLinkContentComponent,
    contentData: new BannerContentModel("home.bannerOne.show","home.bannerOne.title", "home.bannerOne.description", 'home.bannerOne.link', 'home.bannerOne.linkText'),
    img: ImgLayoutVerticalThreeComponent,
    imgUrls: ['home.bannerOne.imgOne', 'home.bannerOne.imgTwo', 'home.bannerOne.imgThree'],
    layout: BannerLayout.LEFT50
  };
  bannerTwo: BannerAttributesInterface = {
    content: TitleDescAndLinkContentComponent,
    contentData: new BannerContentModel("home.bannerTwo.show","home.bannerTwo.title","home.bannerTwo.description", 'home.bannerTwo.link', 'home.bannerTwo.linkText'),
    img: ImgLayoutThreeComponent,
    imgUrls: ['home.bannerTwo.imgOne', 'home.bannerTwo.imgTwo', 'home.bannerTwo.imgThree'],
    layout: BannerLayout.LEFT50
  };
  bannerThree: BannerAttributesInterface = {
    content: TitleDescAndLinkContentComponent,
    contentData: new BannerContentModel("home.bannerThree.show","home.bannerThree.title", "home.bannerThree.description", 'home.bannerThree.link', 'home.bannerThree.linkText'),
    img: ImgLayoutVerticalThreeComponent,
    imgUrls: ['home.bannerThree.imgOne', 'home.bannerThree.imgTwo', 'home.bannerThree.imgThree', 'home.bannerThree.imgFour'],
    layout: BannerLayout.RIGHT50
  };
  bannerFour: BannerAttributesInterface = {
    content: TitleDescAndLinkContentComponent,
    contentData: new BannerContentModel("home.bannerFour.show","home.bannerFour.title", "home.bannerFour.description", 'home.bannerFour.link', 'home.bannerFour.linkText'),
    img: ImgLayoutVerticalThreeComponent,
    imgUrls: ['home.bannerFour.imgOne', 'home.bannerFour.imgTwo', 'home.bannerFour.imgThree', 'home.bannerFour.imgFour'],
    layout: BannerLayout.LEFT50
  };


  constructor(private attachmentUrlService: AttachmentUrlService,
              private translate: TranslateService,
              private customNavigationService: CustomNavigationService) { }

  ngOnInit(): void {
    this.redeemLink = this.customNavigationService.extendUrlWithLangCurrencyAndRestaurantHistoryId(Utilities.getRedeemRedirectUrl());
    this.topImg = this.attachmentUrlService.getScaleWidthImageUrl(this.shopTopBg, 1400);
    this.todayIsImmediate();
    setTimeout(() => {
      this.clickSim.nativeElement.click();
    }, 200);
  }

  todayIsImmediate() {
    let today = new Date();
    return Constants.ISIMMEDIATEDELIVERY.includes(today.getDay());
  }


  scrollTo(el: HTMLElement) {
    el.scrollIntoView();
  }

  goTo(link: string) {
    window.location.href = link;
  }
}

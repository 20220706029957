import {Component, OnInit} from '@angular/core';
import {
  TitleAndDescContentComponent
} from "../banner/banner-content-layout/title-and-desc-content/title-and-desc-content.component";
import {BannerContentModel} from "../banner/banner-content-layout/banner-content.model";
import {BannerLayout} from "../banner/banner-layout.model";
import {ImgLayoutOneComponent} from "../banner/banner-img-layout/img-layout-one/img-layout-one.component";
import {
  TitleDescAndLinkContentComponent
} from "../banner/banner-content-layout/title-desc-and-link-content/title-desc-and-link-content.component";

@Component({
  selector: 'app-footer-tab',
  templateUrl: './footer-tab.component.html',
  styleUrls: ['./footer-tab.component.scss']
})
export class FooterTabComponent implements OnInit {
  tabBannerOne = {
    content: TitleDescAndLinkContentComponent,
    contentData: new BannerContentModel("", "delivery.deliveryInstructionH", "delivery.deliveryInstruction", 'delivery.deliveryInstructionLink', 'delivery.deliveryInstructionLinkText'),
    img: ImgLayoutOneComponent,
    imgUrls: ['delivery.deliveryInstructionImgOne'],
    layout: BannerLayout.LEFT50
  };
  tabBannerTwo = {
    content: TitleAndDescContentComponent,
    contentData: new BannerContentModel("", "delivery.deliveryToolsH", "delivery.deliveryTools"),
    img: ImgLayoutOneComponent,
    imgUrls: ['delivery.deliveryToolsImgOne'],
    layout: BannerLayout.RIGHT50
  };
  tabBannerThree = {
    content: TitleAndDescContentComponent,
    contentData: new BannerContentModel("", "delivery.deliveryCookerH", "delivery.deliveryCooker"),
    img: ImgLayoutOneComponent,
    imgUrls: ['delivery.deliveryCookerImgOne'],
    layout: BannerLayout.LEFT50
  };

  constructor() {
  }

  ngOnInit(): void {
  }

}

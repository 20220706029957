<div class="row banner-img-wrapper" *ngIf="product != undefined">
  <div class="col-md-4 mdlb-product-img-sm px-sm-1 px-0">
    <img src="{{attachmentUrlService.getScaleWidthImageUrl(product.attachments[1].attachment.localization, 600)}}"
         alt="{{product.name}}"
         title="{{product.name}}">
  </div>
  <div class="col-md-8 px-md-3 px-0">
    <div class="row">
      <div class="col-12 px-sm-1 px-0">
        <h2 class="mdlb-cart-h2 mt-sm-1 mt-5">
          {{product.name}}
        </h2>
      </div>
      <div class="col-12 px-md-1 px-0">

        <p class="mdlb-cart-details" >{{product.description}}</p>


        <div class="col-md-12" style="padding: 0 30px 0 0 "
             *ngIf="product.giftCard.optionMap.DELIVERY.optionPackagesWithAndOperation['1'] != undefined">

          <ul class="mdlb-product-extra-list"
              *ngIf="product.giftCard.optionMap.DELIVERY.optionPackagesWithAndOperation['1'] != undefined">
            <li
              *ngFor="let connectedProd of product.giftCard.optionMap.DELIVERY.optionPackagesWithAndOperation | keyvalue"
              class="extraAndContainer">
        <span *ngFor="let connectedOrProd of connectedProd.value.productsWithOrOperationNames; let i = index">
            <span *ngIf="i>0"> {{'product.orTitle' | translate}}</span>
          {{connectedOrProd}}
        </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row banner-img-wrapper row-when-if-need-margin" *ngIf="product!=undefined">
  <div class="col-md-12 text-right">
    <hr/>
    <p class="mdlb-summ-discount">
      <span>  {{ 'orderdetailsPage.discountSumText' | translate }} </span>
      <span>{{cartService.getDiscountAmount() | number : 0}} {{currencyService.currentCurrencyCode | uppercase }}</span>
    </p>

    <p class="mdlb-summ-price">
      <span>  {{ 'orderdetailsPage.sumText' | translate }}</span>
      <span>: {{cartService.refreshTotal() | number : 0}}  {{currencyService.currentCurrencyCode | uppercase }}</span>

    </p>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CartService} from "../../services/cart.service";
import {MyStorageService} from "../../services/my-storage.service";
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {TranslateService} from "@ngx-translate/core";
import {CartRepository} from "../../repositories/cart.repository";
import {Utilities} from "../../utils/utilities";

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  public readonly stepNumber: number = 3;
  orderIsEditable = true;
  redirectUrl: any = null;
  topImg: any;
  cartElements:any[];
  reservations:any[] = [];
  public steps;
  private secretKey: string;

  constructor(
    private route: ActivatedRoute,
    private cartService: CartService,
    private storageService: MyStorageService,
    private attachmentUrlService: AttachmentUrlService,
    private cartRepository: CartRepository,
    private translate: TranslateService) {

    this.secretKey = this.route.snapshot.paramMap.get('cartSecretKey');
    this.steps = Utilities.getDeliveryReservationSteps(this.secretKey);
  }

  ngOnInit(): void {
    this.topImg = this.attachmentUrlService.getScaleWidthImageUrlBySlug('delivery-top-background', 1400);
    this.cartService.refreshBySecretKey(this.secretKey);
    this.redirectUrl = this.storageService.getFromStore('barionRedirect', null);
    this.orderIsEditable = (this.redirectUrl == null);
    this.cartRepository.getBySecretKey(this.secretKey).subscribe(response => {
      this.cartElements = response.cartElements;
      for (let cartElement of response.cartElements){
        for(let deliveryReservation of cartElement.deliveryReservations) {
          this.reservations.push(deliveryReservation.reservation);
        }
      }
    })
  }

  revokeProcess() {
    this.cartService.resetCartData();
    window.location.href = '/';
  }

  continueProcess() {
    window.location.href = this.redirectUrl;
  }
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-foods',
  templateUrl: './foods.component.html',
  styleUrls: ['./foods.component.scss']
})
export class FoodsComponent implements OnInit, OnChanges{

  @Input() reservation:any;
  guests: any[] = [];
  emptyImg: any;
  constructor(public attachmentUrlService:AttachmentUrlService, private translateService:TranslateService) { }

  ngOnInit(): void {
    this.emptyImg = this.attachmentUrlService.getScaleWidthImageUrlBySlug('guest-empty-diy', 600);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(this.reservation != undefined){
      this.guests = this.reservation.guests;
      this.guests.forEach(guest =>{
        guest.preorderedDiys.forEach(diy =>{
          diy.name = diy.localizations[this.translateService.currentLang].name;
          diy.src = '';
          if(diy.recipes.length>0 && diy.recipes[0].attachments['1']){
              diy.src = this.attachmentUrlService.getScaleWidthImageUrl(diy.recipes[0].attachments['1'].attachment.localization, 600);
          }
        });
        guest.preorderedDrinks.forEach(drink =>{
          drink.name = drink.localizations[this.translateService.currentLang].name;
          let scaleWidthImageUrl = 'assets/images/default.jpg'
          if(drink.attachments['1']) {
            const scaleWidthImageUrl = this.attachmentUrlService.getScaleWidthImageUrl(drink.attachments['1'].attachment.localization, 600);
            console.log(scaleWidthImageUrl);
          }
          drink.src =  scaleWidthImageUrl;
        });
      });
    }
  }

}

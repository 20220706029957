export const environment = {
  production: true,
  keycloak: {
    issuer: 'https://authentication.makeryworld.com/auth/',
    realm: 'Makery',
    clientId: 'makery-webshop-prod',
  },
  serviceUrl: 'https://webshop-api.makeryworld.com/',
  translateUrl:'https://webshop-api.makeryworld.com/download/translations/',
  menuRedirectUrl: 'https://reservation.makeryworld.com/delivery/',
  redeemRedirectUrl:'http://reservation.makeryworld.com/voucher/',
  acceptCookiesDomain: 'makeryworld.com',
  GA_TRACKING_ID: 'UA-104499273-7',
  BARION_PIXEL_ID: 'BP-bEjj0tQwSc-9D',
  LINKED_IN_ID: '2437658'
};

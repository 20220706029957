import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import {MyStorageService} from '../services/my-storage.service';
import {Constants} from '../utils/constants';
import {ErrorHandlerService} from "../services/error-handler.service";


@Injectable()
export class LoaderInterceptor {
  private static XHR_TASKS = 0;

  constructor(public loaderService: LoaderService,
              private myStorage:MyStorageService,
              private errorHandlerService: ErrorHandlerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({ headers: req.headers.set('Accept-Language', this.myStorage.getStoredOrDefaultLanguage()+'-HU') });

    /** Dataminer-es hívásokra ne dobjon fel karikázást */
    if(req.url.includes("dataminer")){
      return next.handle(clonedRequest).pipe(
        catchError((err: any) => {
          let errorNew = new Error('Unknown error!');
          errorNew.name = err.status;
          errorNew.message = (err.error.attribute != undefined &&
            err.error.attribute.message != undefined &&
            err.error.attribute.message != null) ? err.error.attribute.message: err.message;
          console.warn(errorNew.name + "\n" + errorNew.message);
          return of(err);
        })
      );
    }

    this.loaderService.show();
    LoaderInterceptor.XHR_TASKS++;

    return next.handle(clonedRequest).pipe(
      catchError((err: any) => {
        this.errorHandlerService.handleError(err);
        return of(err);
      }),
      finalize(() => {
        LoaderInterceptor.XHR_TASKS--;
        if(LoaderInterceptor.XHR_TASKS == 0) {
          this.loaderService.hide()
        }
      })
    );
  }
}

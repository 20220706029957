<div class="modal-header">
  <h2>{{'restaurant.selectModal.title' | translate}}</h2>
</div>
<div class="modal-body">
  <img src="assets/images/restaurants_map.jpg" class="rounded" alt="restaurants_map" />

  <div class="d-flex flex-md-row flex-wrap flex-column justify-content-center" *ngIf="!deliveryOff">
    <button type="button" (click)="restaurantSelected(restaurant)" class="btn btn-primary mt-3 mx-md-2" *ngFor="let restaurant of restaurants">
      {{restaurant.name}}
    </button>
  </div>

  <div class="text-center mt-5 mdlb-form-button-container" *ngIf="deliveryOff">
    <p>{{'delivery.deliveryOff.text' | translate}}</p>
    <a  href="{{'delivery.deliveryOff.link' | translate}}" class="btn btn-primary">{{'delivery.deliveryOff.linkText' | translate}}</a>
  </div>
</div>

<ng-container *ngIf="products.length>0">
  <div class="row mdlb-ui-padding">
    <div class="col-12 mdlb-product-list ">
      <h2 class="mb-5 mdlb-recommended-h2">{{'product.recommended' | translate}}</h2>
      <div class="row">
        <div class="col-lg-6 col-xl-4 col-sm-12 ">
          <app-product [product]="{product:products[0], personCount: products[0].giftCard.minPurchaseAmount}"></app-product>
        </div>
        <div class="col-lg-6 col-xl-4 col-sm-12">
          <app-product [product]="{product:products[1], personCount: products[1].giftCard.minPurchaseAmount}"></app-product>
        </div>
        <div class="col-lg-6 col-xl-4 col-sm-12  d-xl-block d-sm-block d-lg-none d-md-none ">
          <app-product [product]="{product:products[2], personCount: products[2].giftCard.minPurchaseAmount}"></app-product>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RepositoryService} from './repository.service';
import {Constants} from "../utils/constants";
import {ToasterService} from "../services/toaster.service";

@Injectable({
  providedIn: 'root'
})
export class AllergenRepository extends RepositoryService {
  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  public getAllAllergens(){
    const path = 'webapi/api/allergens';
    return super.get(path)
  }

  public getAllergenById(id: number){
    const path = 'webapi/api/allergens/' + id.toString();
    return super.get(path)
  }

}

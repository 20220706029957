import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";

export class ClosableComponent {
  public onClose: Subject<boolean>;
  public onCloseWithResponse: Subject<Object>;
  public bsModalRef: BsModalRef;


  constructor(bsModalRef:BsModalRef) {
    this.bsModalRef = bsModalRef;
    this.onClose = new Subject();
    this.onCloseWithResponse = new Subject();
  }

  close(){
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  closeWithResponse(response:Object){
    this.onCloseWithResponse.next(response);
    this.bsModalRef.hide();
  }
}

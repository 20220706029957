import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AllergenRepository} from "../../repositories/allergen.repository";

@Component({
  selector: 'app-allergens',
  templateUrl: './allergens.component.html',
  styleUrls: ['./allergens.component.scss']
})
export class AllergensComponent implements OnInit {
  public allergens: any[] = null;
  constructor(private allergenRepository: AllergenRepository,   public translate: TranslateService,) { }

  ngOnInit(): void {
    this.loadAllergens();

  }

  private loadAllergens() {
    this.allergenRepository.getAllAllergens().subscribe((data: any) => {
      this.allergens = data;
    });
  }
}

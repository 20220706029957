<ng-container *ngIf="steps != undefined">
  <div class="row mb-5">
    <div class="col align-self-center p-0">
      <div class="mdlb-dummy-stepper">
        <div class="row justify-content-center">
          <ng-container *ngFor="let stepElement of steps; let i = index">
            <div class="col text-center align-self-center d-none d-lg-block" *ngIf="i>0">
              <div class="mdlb-ui-lane"></div>
            </div>
            <div class="col badge-col text-center" [attr.data.index]="i">
              <div class="badge badge-pill mdlb-dummy-icon" (click)="goto(i)" [ngClass]=
                "{
            'clickable': this.currentStep > i && !stepElement.disabled,
            'active-step': this.currentStep >= i && !stepElement.disabled,
            'disabled-step': this.currentStep < i || stepElement.disabled
            }"
              >
                <img src="assets/images/icon/{{stepElement.title}}.svg" title="{{stepElement.title | translate}}"
                     alt="">
                <p *ngIf="currentStep == i" class="d-none d-lg-block"><strong><u>{{stepElement.title | translate}}</u></strong></p>
                <p *ngIf="currentStep != i" class="d-none d-lg-block">{{stepElement.title | translate}}</p>
              </div>
            </div>
          </ng-container>
        </div>
        <p class="d-block d-lg-none d-xl-none text-center">{{steps[currentStep].title |translate}}</p>
      </div>
    </div>
  </div>
</ng-container>

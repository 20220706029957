<div class="row">
  <div class="col-12 px-0 mdlb-slider-img-wrapper">
    <img class="mdlb-welcome-top-image" [src]="topImg"
         alt="Foglalj most, akár ajándékutalvány nélkül is!"
         title="Foglalj most, akár ajándékutalvány nélkül is!" (error)="topImg = 'assets/images/banner_img.jpg'">
  </div>
</div>
<div class="mdlb-welcome-container " *ngIf="orderIsEditable && cartElements && reservations">
  <div class="row mdlb-ui-padding connect-ui-padding  mt-5">
    <div class="row w-100">
      <div class="col-12">
        <app-stepper [currentStep]="stepNumber" [steps]="steps"></app-stepper>
      </div>
    </div>
    <div class="col-md-12  mdlb-connect-box">
      <h2 class="mdlb-order-heading  mb-5" translate="orderSummarizePage.reservationTitle"></h2>
      <app-order-reservation-elements [reservation]="reservations[0]"></app-order-reservation-elements>
      <app-order-reservation-product [cartElement]="cartElements[0]"></app-order-reservation-product>
      <app-order-form [isEditable]="true"></app-order-form>
    </div>
  </div>
</div>
<div class="mdlb-welcome-container " *ngIf="!orderIsEditable">
  <div class="row mdlb-ui-padding connect-ui-padding  mt-5">
    <div class="col-md-12 mdlb-connect-box">
      <app-order-reservation-product [cartElement]="cartElements[0]"></app-order-reservation-product>
    </div>
  </div>
  <div class="row  mdlb-ui-padding connect-ui-padding">
    <div class="col-md-12 mdlb-connect-box" style="margin-top:80px">
      <div class="row">
        <div class="col-md-12 text-center">
          <h4>
            {{'order.existingProcess'|translate}}
          </h4>
        </div>
      </div>
      <div class="row mdlb-form-button-container">
        <div class="col-md-6 text-right mb-3 mb-sm-0">
          <button class="btn btn-outline-primary btn-sm"
                  (click)="continueProcess()">{{'order.continueProcess' | translate}}</button>
        </div>
        <div class="col-md-6 text-left mb-3 mb-sm-0">
          <button class="btn btn-primary btn-sm"
                  (click)="revokeProcess()">{{'order.revokeProcess' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col-12 px-0 mdlb-slider-img-wrapper">
    <img class="mdlb-welcome-top-image" [src]="topImg"
         alt="Foglalj most, akár ajándékutalvány nélkül is!"
         title="Foglalj most, akár ajándékutalvány nélkül is!" (error)="topImg = 'assets/images/main_img.jpg'" style="">
  </div>
</div>


<div class="mdlb-welcome-container">
  <div class="row mdlb-ui-padding">
    <div class="col-12 text-center">
      <h1 class="mdlb-connect-h1" translate="afterpaymentPage.paymentInfo"></h1>
    </div>
    <div class="col-md-12  mdlb-connect-box">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2 *ngIf="order.paymentStatus == 'Succeeded' || order.paymentStatus == 'fulfilled'" class="mdlb-after-heading2" translate="afterpaymentPage.headingLineSuccess"></h2>
          <h2 *ngIf="order.paymentStatus == 'Canceled' || order.paymentStatus == 'Expired'" class="mdlb-after-heading2" translate="afterpaymentPage.headingLineBarionError"></h2>
          <h2 *ngIf="order.paymentStatus == 'notfulfilled'" class="mdlb-after-heading2" translate="afterpaymentPage.headingLineMakeryError"></h2>
        </div>
        <div class="col-md-12 text-center">
          <img class="mdlb-after-icon" src="assets/images/icon/illustration.svg" alt="{{'afterpaymentPage.headingLineH2' | translate}}" title="{{'afterpaymentPage.headingLineH2' | translate}}">
        </div>
        <div class="col-md-12 text-center">
          <h5 class="mdlb-after-heading5" translate="afterpaymentPage.transactionID"></h5>
          <p class="mdlb-after-barion"><b>{{order.paymentId}}</b></p>
        </div>
        <div  *ngIf="order.paymentStatus != 'Succeeded' && order.paymentStatus != 'fulfilled'" class="col-md-12 text-center">
          <p class="mdlb-after-para" translate="afterpaymentPage.errorPaymentText"></p>
        </div>
      </div>
      <app-rating *ngIf="order.paymentStatus == 'Succeeded' || order.paymentStatus == 'fulfilled'" [paymentId]="order.paymentId"></app-rating>
    </div>
  </div>
</div>



<!--<div class="container-fluid">-->
<!--  <div class="row">-->
<!--    <div class="img-wrapper">-->
<!--      <div class="img-overlay"></div>-->
<!--      <img class="lazy" src="../../../assets/images/t.jpg" class="rowImg"-->
<!--           alt="{{'afterpaymentPage.headingLine' | translate}}"-->
<!--           title="{{'afterpaymentPage.headingLine' | translate}}"/>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div [ngSwitch]="order.paymentStatus" class="row formContainer">-->

<!--    <div *ngSwitchCase="'fulfilled' || 'Succeeded'"-->
<!--         class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 mb-2">-->
<!--      <div class="row">-->
<!--        <div class="col-md-12 mt-5">-->
<!--          <h1 class="my-1 mr-1 mb-3 centered" translate="afterpaymentPage.headingLine"></h1>-->
<!--          <app-stepper [step]="5"></app-stepper>-->
<!--          <p class="my-1 mr-2 centered w-100" translate="afterpaymentPage.transactionID"><span-->
<!--            class="my-1 mr-2 centered"><b> {{order.paymentId}}</b></span></p>-->

<!--          <p class="mt-2 mr-2 centered w-100" translate="afterpaymentPage.successMessage"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div *ngSwitchCase="'Canceled'" class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 mb-2">-->

<!--      <div class="row">-->
<!--        <div class="col-md-12 mt-5">-->
<!--          <h1 class="my-1 mr-1 centered" translate="afterpaymentPage.headingLine"></h1>-->
<!--          <p class="my-1 mr-2 centered" translate="afterpaymentPage.transactionID"></p>-->
<!--          <p class="my-1 mr-2 centered"><b>{{order.paymentId}}</b></p>-->
<!--          <p class="my-1 mr-2 centered" translate="afterpaymentPage.errorPaymentText"></p>-->

<!--          <p class="my-1 mr-2 centered"><b>{{'enum.paymentStatusType.' + order.paymentStatus | translate}}</b></p>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div *ngSwitchCase="'Expired'" class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 mb-2">-->
<!--      <div class="row">-->
<!--        <div class="col-md-12 mt-5">-->
<!--          <h1 class="my-1 mr-1 centered" translate="afterpaymentPage.headingLine"></h1>-->
<!--          <p class="my-1 mr-2 centered" translate="afterpaymentPage.transactionID"></p>-->
<!--          <p class="my-1 mr-2 centered"><b>{{order.paymentId}}</b></p>-->
<!--          <p class="my-1 mr-2 centered" translate="afterpaymentPage.errorPaymentText"></p>-->

<!--          <p class="my-1 mr-2 centered"><b>{{'enum.paymentStatusType.' + order.paymentStatus | translate}}</b></p>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div *ngSwitchCase="'notfulfilled'" class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 mb-2">-->
<!--      <div class="row">-->
<!--        <div class="col-md-12 mt-5">-->
<!--          <h1 class="my-1 mr-1 centered" translate="afterpaymentPage.headingLine"></h1>-->
<!--          <p class="my-1 mr-2 centered" translate="afterpaymentPage.transactionID"></p>-->
<!--          <p class="my-1 mr-2 centered"><b>{{order.paymentId}}</b></p>-->
<!--          <p class="my-1 mr-2 centered" translate="afterpaymentPage.notfulfilledText"></p>-->
<!--          <p class="my-1 mr-2 centered"><b>{{'enum.paymentStatusType.' + order.paymentStatus | translate}}</b></p>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div *ngSwitchDefault class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 mb-2">-->
<!--      <div class="row">-->
<!--        <div class="col-md-12 mt-5">-->
<!--          <h1 class="my-1 mr-1 centered" translate="afterpaymentPage.headingLine"></h1>-->
<!--          <p class="my-1 mr-2 centered" translate="afterpaymentPage.transactionID"></p>-->
<!--          <p class="my-1 mr-2 centered"><b>{{order.paymentId}}</b></p>-->
<!--          <p class="my-1 mr-2 centered" translate="afterpaymentPage.paymentText"></p>-->
<!--          <p class="my-1 mr-2 centered"><b>{{'enum.paymentStatusType.' + order.paymentStatus | translate}}</b></p>-->

<!--          <p class="my-1 mr-2 centered" > {{'afterpaymentPage.message' | translate}}<a href="{{'seohref.contact' | translate}}">{{'afterpaymentPage.link' | translate}}</a>!</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 offset-md-0 mb-5">-->
<!--      <app-rating [paymentId]="order.paymentId"></app-rating>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

import {Component, Input, OnInit} from '@angular/core';
import {DataManipulationService} from "../../services/data-manipulation.service";

@Component({
  selector: 'app-reservation-elements',
  templateUrl: './reservation-elements.component.html',
  styleUrls: ['./reservation-elements.component.scss']
})
export class ReservationElementsComponent implements OnInit {
  @Input() reservation:any;
  constructor(
    private dateManipulation: DataManipulationService
  ) { }

  ngOnInit(): void {
    // this.dateManipulation.addGetNameMethodToElement(this.reservation, "restaurant")
    console.log(this.reservation)
  }

}

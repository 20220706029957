<div class="modal-content ">
  <div class="modal-body">
    <ng-container *ngIf="cartService.cart.cartModel.cartElements.length<1">
      <div class="row ">
        <div class="col-12 text-center">
          <h3 translate="orderdetailsPage.cartEmpty"></h3>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="cartService.cart.cartModel.cartElements.length>0">
      <div class="row mdlb-cart-modal">
        <div class="row w-100 mdlb-cart-header mb-md-2 pb-md-2">
          <div class="col-md-10">
            <h2 translate="cart.cartContent"></h2>
          </div>
          <div class="col-md-2 text-right">
            <a style="font-size: 20px;" href="javascript:void(0)" (click)="bsModalRef.hide()">
              <i class="fas fa-times" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 m-0 p-0">
            <div class="row">
              <ng-container *ngFor="let element of cartService.cart.cartModel.cartElements; let i = index">
                <div class="col-md-12 mx-md-1 px-0  mt-2">
                  <hr *ngIf="i!=0"/>
                  <app-cart-elements [bsModalRef]="bsModalRef" [cartElement]="element"
                                     [isEditable]="cartService.cartEditable()"></app-cart-elements>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="col-sm-6 col-xs-12 text-right mdlb-cart-button">
            <button href="javascript:void(0)" class=" btn btn-primary " type="button"
                    *ngIf="cartService.cart.cartModel.cartElements.length>0 "
                    (click)="handlePayment()"
            >
              {{'shoppingCartModal.checkout' | translate}}
            </button>
          </div>
          <div class="col-sm-6  col-xs-12 text-left mdlb-cart-button">
            <button class="btn btn-outline-primary " type="button" (click)="gotoMainPage()"
                    translate="shoppingCartModal.continueShopping"></button>
          </div>
        </div>
      </div>
    </ng-container>
    <!--    <div class="row">-->
    <!--      <div class="col-md-12 mx-md1 px-0  mt-2"-->
    <!--           *ngFor="let element of cartService.cart.cartModel.cartElements; let i = index">-->
    <!--        <hr *ngIf="i != 0"/>-->

    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="row mt-md-5 mt-0 mdlb-form-button-container">-->
    <!--      <div class="col-md-6 text-right ">-->
    <!--        <button class="btn btn-outline-primary mb-3 mb-sm-0" type="button" (click)="gotoMainPage()"-->
    <!--                translate="shoppingCartModal.continueShopping"></button>-->
    <!--      </div>-->
    <!--      <div class="col-md-6 text-left">-->
    <!--        <button *ngIf=" cartService.getRemaining()<=0 "-->
    <!--                class="btn btn-primary mb-3 mb-sm-0" type="button" (click)="handlePayment()"-->
    <!--                translate="shoppingCartModal.checkout"></button>-->
    <!--        <button *ngIf="cartService.getRemaining()>0"-->
    <!--                class="btn btn-primary mb-3 mb-sm-0" type="button"-->
    <!--        > {{'shoppingCartModal.minOrder.part1' | translate }}-->
    <!--          {{cartService.getRemaining()}}-->
    <!--          {{currencyService.currentCurrencyCode | uppercase}}-->
    <!--          {{'shoppingCartModal.minOrder.part2' | translate}}-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="row mt-md-5 mt-0 " *ngIf="!cartService.cartEditable()">-->
    <!--      <div class="col-12">-->
    <!--        <a href="javascript:void(0)" (click)="cartService.resetOrderProcess()" translate="cart.resetOrderProcess"></a>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

</div>

import {Component, Input, OnInit} from '@angular/core';
import {AnalyticsService} from "../../services/analytics.service";
import {CustomNavigationService} from "../../services/custom-navigation.service";
import {Stepper} from "../../models/stepper.model";

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() currentStep: number;
  @Input() steps: Stepper[];

  constructor(
    private analyticsService: AnalyticsService,
    private customNavigationService: CustomNavigationService
  ) {
  }

  ngOnInit(): void {
  }

  goto(selectedStepNumber) {
    let btnId = "stepNumber" + (selectedStepNumber + 1);
    if (selectedStepNumber < this.currentStep) {
      if (this.steps[selectedStepNumber].disabled) {
        return;
      }
      if (!this.steps[selectedStepNumber].uri) {
        console.error("Nem definiált navigáció a stepperen: " + selectedStepNumber);
        return;
      }
      this.analyticsService.buttonClickEvent(btnId, 'stepper', this.steps[selectedStepNumber].uriToAnalytics);

      let location = this.steps[selectedStepNumber].uri;
      if (this.steps[selectedStepNumber].external) {
        location = this.customNavigationService.extendUrlWithLangCurrencyAndRestaurantHistoryId(location);
      }
      window.location.href = location;
    }
  }

}

import {Injectable} from "@angular/core";
import {MyStorageService} from "./my-storage.service";
import {OrderRepository} from "../repositories/order.repository";
import {RestaurantRepository} from "../repositories/restaurant.repository";
import {OrderPayload} from "../models/order-payload";
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {Cart, OrderData} from "../models/cart-models";
import {TranslateService} from "@ngx-translate/core";
import {AnalyticsService} from "./analytics.service";
import {CurrencyService} from "./currency.service";
import {CartService} from "./cart.service";


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private order: OrderPayload;
  private orderData: OrderData;
  private cart: Cart;
  private click = 0;

  constructor(private orderRepository: OrderRepository,
              private storageService: MyStorageService,
              private restaurantRepository: RestaurantRepository,
              private currencyService: CurrencyService,
              private translateService: TranslateService,
              private analyticsService: AnalyticsService,
              private cartService: CartService,
              private recaptchaV3Service: ReCaptchaV3Service) {
    this.refreshCart();
  }

  createOrderPayload(user: any, cart: Cart, token: string): OrderPayload {
    return new OrderPayload(user, cart, this.currencyService.currentCurrencyCode, token);
  }

  sendOrderToBackendStartPayment(user: any) {
    this.refreshCart();
    if (this.cart != undefined) {
      this.recaptchaV3Service.execute('newOrder')
        .subscribe((token) => {
          this.storageService.saveToStore('userData', user);
          this.analyticsService.initiateCheckoutEvent(this.cart, 1);
          document.location.href = "/" + this.translateService.instant('seohref.orderSummarize') + "/" + this.cart.cartModel.secretKey;
        });
    }
  }

  finalizeOrder(subscribe: boolean) {
    const redirectUrl = this.storageService.getFromStore('barionRedirect', null);
    if (redirectUrl == null) {
      if (this.click == 0) {
        this.click++;
        this.recaptchaV3Service.execute('finalizeOrder')
          .subscribe((token) => {
            let user = this.storageService.getFromStore('userData', {});
            user.subscribed = subscribe;
            let payload = this.createOrderPayload(user, this.cart, token);
            this.orderRepository.saveOrder(payload).subscribe((response) => {
              this.analyticsService.initiatePaymentEvent(this.cart, response.attribute.orderNumber);
              this.storageService.saveToStore('barionRedirect', response.attribute.href);
              document.location.href = response.attribute.href;
            });
          });
      }
    } else {
      document.location.href = redirectUrl;
    }
  }

  addItemOrderdata(billingData, deliveryData, message, deliveryDataIsSame) {
    let copyBillingData = JSON.parse(JSON.stringify(billingData));
    if (deliveryDataIsSame == true) {
      copyBillingData = deliveryData;
      copyBillingData.companyName = billingData.companyName;
      copyBillingData.taxNumber = billingData.taxNumber;
    }
    let orderData = new OrderData(copyBillingData, deliveryData, message, deliveryDataIsSame);
    this.refreshCart();
    this.cart.orderData = orderData;
    this.saveCart();
    this.storageService.saveToStore('orderData', orderData);
  }

  getOrderData() {
    this.orderData = this.storageService.getFromStore('orderData', null);
    return this.orderData;
  }

  deleteOrderData() {
    this.storageService.deleteFromCookies('orderData');
  }

  private refreshCart() {
    this.cartService.refreshTotal();
    this.cart = this.cartService.cart;
  }

  private saveCart() {
    this.storageService.saveToStore('cart', this.cart);
  }
}

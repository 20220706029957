<div class="row" *ngIf="reservation != undefined">
  <div class="col-12 "  *ngFor="let guest of guests">
    <p class="guest-nickname">{{guest.nickname}}</p>
    <div *ngFor="let preOrder of guest.preorderedDiys;"
         class="mdlb-preordered-list">
      <img
        [src]="preOrder.src" alt="{{preOrder.name}}"
        (error)="preOrder.src = 'assets/images/menu.jpg'"/>
      <span class="preordered-title">{{preOrder.name}}</span>
    </div>
    <div *ngIf="guest.preorderedDiys.length<1" class="mdlb-preordered-list">
      <img
        [src]="emptyImg" alt="{{'preOrderedDiy.empty' | translate}}" (error)="emptyImg = 'assets/images/menu.jpg'">
      <span class="preordered-title" >{{'preOrderedDiy.empty' | translate}}</span>
    </div>
    <div *ngFor="let preOrder of guest.preorderedDrinks; ">
      <img
        [src]="preOrder.src" alt="{{preOrder.name}}"
        (error)="preOrder.src = 'assets/images/default.jpg'"/>
      <span class="preordered-title">{{preOrder.name}}</span>
    </div>
  </div>
</div>



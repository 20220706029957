import { Component, OnInit,Input } from '@angular/core';
import {CartElement} from "../../models/cart-models";
import {CartService} from "../../services/cart.service";

@Component({
  selector: 'app-product-counter',
  templateUrl: './product-counter.component.html',
  styleUrls: ['./product-counter.component.scss']
})
export class ProductCounterComponent implements OnInit {

  constructor(public cartService: CartService) { }
  @Input() cartElement: CartElement
  ngOnInit(): void {
  }
  decrementPersonCount() {
    if ((this.cartElement.personCount - 1) >= this.cartElement.productFull.giftCard.minPurchaseAmount) {
      this.cartElement.personCount--;
    }
    this.cartService.updateCartElement(this.cartElement);

  }
  incrementPersonCount() {
    return this.cartElement.personCount++;
    this.cartService.updateCartElement(this.cartElement);
  }
}

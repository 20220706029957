<div class="row ">
  <div class="col-md-12 guestHeading">
    <span>{{guest.nickname}}</span>
    <span *ngIf="guest.email">({{guest.email}})</span>
  </div>
  <div class="col-md-12">
    <app-food-elements *ngFor="let food of guest.preorderedDiys" [food]="food"></app-food-elements>
    <app-drink-element *ngFor="let drink of guest.preorderedDrinks" [drink]="drink"></app-drink-element>
  </div>
</div>


import {environment} from '../../environments/environment';
import {formatDate} from '@angular/common';
import {Stepper} from "../models/stepper.model";

export class Utilities {

  static getServiceUrl() {
    return environment.serviceUrl;
  }

  static getTranslateUrl() {
    return environment.translateUrl;
  }

  static getAcceptCookiesDomain() {
    return environment.acceptCookiesDomain;
  }

  static dateToBackendString(date: Date) {
    return formatDate(date, 'yyyy-MM-dd', 'hu-HU')
  }

  static getImgDownloadServiceUrl() {
    return environment.serviceUrl + "download/img/";
  }

  static getAttachmentServiceUrl() {
    return environment.serviceUrl + "download/";
  }

  static arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  static getMenuRedirectUrl() {
    return environment.menuRedirectUrl;
  }

  static getRedeemRedirectUrl() {
    return environment.redeemRedirectUrl;
  }

  static getGATrackingId() {
    return environment.GA_TRACKING_ID;
  }

  static getBarionPixelId() {
    return environment.BARION_PIXEL_ID;
  }

  static getLinkedInId() {
    return environment.LINKED_IN_ID;
  }

  static isProduction() {
    return environment.production;
  }

  static createModalConfig(initialState, size= 'modal-lg'): any {
    return {
      initialState: initialState,
      backdrop: 'static',
      keyboard: false,
      class: size
    };
  }
  static getDeliveryReservationSteps(secretKey: string) {
    return [
      new Stepper("stepper.choose", '/', '/'),
      new Stepper("stepper.deliveryData", Utilities.getMenuRedirectUrl()+secretKey, Utilities.getMenuRedirectUrl()+'{secretKey}', true),
      new Stepper("stepper.foodChoose", Utilities.getMenuRedirectUrl()+secretKey + "/guests", Utilities.getMenuRedirectUrl()+'{secretKey}/guests', true),
      new Stepper("stepper.summarize",  "/rendelesi-adatok/" + secretKey, "/rendelesi-adatok/{secretKey}"),
      new Stepper("stepper.pay", "/rendelesi-osszesito/" + secretKey, "/rendelesi-osszesito/{secretKey}"),
      new Stepper("stepper.eat")
    ]
  }
}

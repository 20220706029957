import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RepositoryService} from './repository.service';
import {Constants} from "../utils/constants";
import {IPayload} from "../models/IPayload";
import {ToasterService} from "../services/toaster.service";

@Injectable({
  providedIn: 'root'
})
export class UserRepository extends RepositoryService {
  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  public getAllUserData(){
    const path = 'webapi/secure/get/user';
    return super.get(path)
  }

  public getUserDataByType(dataType: string){
    const path = 'webapi/secure/get/user/' + dataType;
    return super.get(path)
  }

  public updatePassword(action: string){
    const path = 'webapi/secure/user/' + action;
    return super.get(path)
  }

  public updateProfile(action: string, userProfile: IPayload){
    const path = 'webapi/secure/user/' + action;
    return super.post(path, userProfile)
  }

  public unsubscribe(encodedEmail){
    const path = 'webapi/api/users/unsubscribe/'+encodedEmail;
    return super.patch(path);
  }

}

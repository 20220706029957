import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {OrderRepository} from "../../repositories/order.repository";
import {AnalyticsService} from "../../services/analytics.service";
import {CartService} from "../../services/cart.service";
import {OrderService} from "../../services/order.service";
import {AttachmentUrlService} from "../../services/attachment-url.service";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  public paymentId: any = {};
  public order: any = {};
  topImg: any;

  constructor(
    private route: ActivatedRoute,
    private attachmentUrlService: AttachmentUrlService,
    public orderRepository: OrderRepository,
    public analyticsService: AnalyticsService,
    public cartService: CartService,
    public orderService: OrderService) {
  }

  ngOnInit(): void {
    this.topImg = this.attachmentUrlService.getScaleWidthImageUrlBySlug('delivery-top-background', 1400);
    let paymentId = "";
    this.route.queryParams.subscribe(params => {
      paymentId = params['paymentId'];
    });
    this.loadSelectedOrder(paymentId);

  }

  private loadSelectedOrder(paymentId) {
    this.orderRepository.getOrderByPaymentId(paymentId).subscribe((data: any) => {
      this.order = data;
      let paymentStatus = this.order.paymentStatus;
      console.log(this.order);
      if (paymentStatus === "Succeeded" || paymentStatus === "fulfilled" || paymentStatus == "notfulfilled") {
        this.analyticsService.purchaseEvent(this.cartService.cart, 3, paymentId);
        this.orderService.deleteOrderData();
        this.cartService.resetCartData();
      }
    });
  }

}

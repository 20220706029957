import { Injectable } from '@angular/core';
import {MyStorageService} from "./my-storage.service";
import {Constants} from "../utils/constants";

@Injectable({
  providedIn: 'root'
})
export class CustomNavigationService {

  constructor(private storageService: MyStorageService) { }

  extendUrlWithLangCurrencyAndRestaurantHistoryId(url: string): string{
    let lang = this.storageService.getFromCookies(Constants.STORAGE_KEYS.LANGUAGE,null);
    let currency = this.storageService.getFromCookies(Constants.STORAGE_KEYS.CURRENCY,null);
    let restaurant = this.storageService.getFromCookies(Constants.STORAGE_KEYS.RESTAURANT, null);

    if(lang){
      url += "?"+Constants.QUERY_PARAMS.LANGUAGE+"="+lang;
    }
    if(currency){
      url += lang ? "&" : "?";
      url += Constants.QUERY_PARAMS.CURRENCY+"="+currency;
    }
    if(restaurant){
      url += (lang || currency) ? "&" : "?";
      url += Constants.QUERY_PARAMS.RESTAURANT+"="+restaurant;
    }

    return url;
  }

  navigateToSubDomain(url:string): void{
    url = this.extendUrlWithLangCurrencyAndRestaurantHistoryId(url);
    window.location.href = url;
  }
}

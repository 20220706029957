import {Injectable} from '@angular/core';
import {MyStorageService} from './my-storage.service';
import {Constants} from '../utils/constants';
import {Utilities} from '../utils/utilities';
import {AnalyticsService} from './analytics.service';
import {Cart, CartElement} from '../models/cart-models';
import {ToasterService} from './toaster.service';
import {CartRepository} from '../repositories/cart.repository';
import {TranslateService} from '@ngx-translate/core';
import {CurrencyService} from './currency.service';
import {CustomNavigationService} from './custom-navigation.service';
import {ProductRepository} from '../repositories/product.repository';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cart: Cart;
  private refreshTotalCurrency: string;
  public deliveryRestaurantAddress: any;
  productCountRefresher: (count) => void;

  constructor(
    private productRepository: ProductRepository,
    private storageService: MyStorageService,
    private currencyService: CurrencyService,
    private analyticsService: AnalyticsService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private cartRepository: CartRepository,
    private customNavigationService: CustomNavigationService,
  ) {
    if (this.refreshTotalCurrency != this.currencyService.currentCurrencyCode) {
      this.refreshTotal();
    } else {
      this.refreshCartObject();
    }

    if (!this.checkCartFormat(this.cart)) {
      this.resetCartData();
    }

    this.currencyService.currencyCodeChange.subscribe(value => {
      if (this.refreshTotalCurrency != value) {
        this.refreshTotal();
      }
    });
  }

  refreshCartObject(): void {
    if (this.productCountRefresher) {
      this.productCountRefresher(this.getProductCount());
    }
    this.cart = this.storageService.getFromStore('cart', new Cart());

  }

  refreshTotal() {
    const currencyCode = this.currencyService.currentCurrencyCode;
    this.refreshCartObject();
    this.cart.total = 0;
    this.cart.discount = 0;

    this.cart.cartModel.cartElements.forEach(cartElement => this.cart.total += parseFloat(cartElement.productFull.prices[currencyCode].grossPrice) * cartElement.count * cartElement.personCount);

    if (this.cart.cartModel.discountFull != null) {
      debugger;
      /** Speciális termékekre vonatkozó kedvezmény */
      if (this.cart.cartModel.discountFull.specificProductIds.length > 0) {
        let discountValueLeft = this.cart.cartModel.discountFull.type.toLowerCase() == 'value' ? this.cart.cartModel.discountFull.prices[currencyCode].grossPrice : 0;
        this.cart.cartModel.cartElements.forEach(cartElement => {
          if (this.cart.cartModel.discountFull.specificProductIds.filter(specificProductId => cartElement.productFull.id == specificProductId).length > 0) {
            const productGrossPrice = parseFloat(cartElement.productFull.prices[currencyCode].grossPrice) * cartElement.count * cartElement.personCount;
            if (this.cart.cartModel.discountFull.type.toLowerCase() == 'value') {
              this.cart.discount += productGrossPrice < discountValueLeft ? productGrossPrice : discountValueLeft;
              discountValueLeft = this.cart.cartModel.discountFull.prices[currencyCode].grossPrice - this.cart.discount;
            } else {
              this.cart.discount += ((productGrossPrice * this.cart.cartModel.discountFull.discountValue) / 100);
            }
          }
        });
        this.cart.total -= this.cart.discount;
      }
      /** Kosárra vonatkozó kedvezmény */
      else {
        if (this.cart.cartModel.discountFull.type.toLowerCase() == 'value') {
          this.cart.discount = parseFloat(this.cart.cartModel.discountFull.prices[currencyCode].grossPrice);
          this.cart.total -= this.cart.discount > this.cart.total ? this.cart.total : this.cart.discount;
        } else {
          this.cart.discount = ((this.cart.total * this.cart.cartModel.discountFull.discountValue) / 100);
          this.cart.total -= this.cart.discount;
        }
      }
    }
    this.saveCart();
    this.refreshTotalCurrency = currencyCode;
    this.refreshCartObject();
    return this.cart.total;
  }

  removeProduct(cartElement: CartElement) {
    this.refreshCartObject();
    const cartItem: any = this.findCartElementBySerial(cartElement.serial);
    if (cartItem.length > 0) {
      const index = this.cart.cartModel.cartElements.indexOf(cartItem[0]);
      this.cart.cartModel.cartElements.splice(index, 1);
    }
    this.saveCart();
    if (this.cart.cartModel.cartElements.length == 0) {
      this.removeDiscount();
      this.cart.discount = 0;
    }
    this.saveCart();
    this.refreshTotal();
    this.analyticsService.removeCartElementEvent(cartElement.productFull, cartElement.count);
  }

  removeDiscount() {
    this.refreshCartObject();
    this.cart.cartModel.discountFull = null;
    this.saveCart();
    this.refreshTotal();
  }

  saveCart() {
    this.storageService.saveToStore('cart', this.cart);
  }

  deleteCartData() {
    this.storageService.deleteFromStore('cart');
    this.storageService.deleteFromStore('barionRedirect');
    this.storageService.deleteFromCookies('orderData');
    this.storageService.deleteFromCookies('userData');
  }

  resetCartData() {
    this.deleteCartData();
    this.cart = new Cart();
    this.saveCart();
  }

  updateCartElement(cartElement: CartElement) {
    console.log(cartElement);
    this.refreshCartObject();
    this.findCartElementBySerial(cartElement.serial)[0].count = cartElement.count;
    this.findCartElementBySerial(cartElement.serial)[0].personCount = cartElement.personCount;
    this.analyticsService.updateCartElementEvent(cartElement.productFull, cartElement.count, cartElement.personCount);
    this.saveCart();
    this.refreshTotal();
  }

  getNetTotal() {
    return this.cart.total + this.cart.discount;
  }


  addDiscount(discount) {
    this.refreshCartObject();
    this.cart.cartModel.discountFull = discount;
    this.saveCart();
    this.refreshTotal();
  }

  addItemToShoppingCart(product, count, personCount) {
    this.refreshCartObject();

    if (!this.cartEditable()) {
      const r = confirm(this.translateService.instant('cart.cartResetAlert'));
      if (r != true) {
        return;
      } else {
        const isHardReset = this.processHardResetAndAdd();
        if (!isHardReset) {
          this.cartRepository.deleteBySecretKey(this.cart.cartModel.secretKey).subscribe(response => {
            this.deleteCartData();
            this.cart = new Cart();
            this.saveCart();
            this.processAddItemToShoppingCart(product, count, personCount);
          });
        } else {
          this.processAddItemToShoppingCart(product, count, personCount);
        }
      }
    } else {
      this.processAddItemToShoppingCart(product, count, personCount);
    }

  }

  private processAddItemToShoppingCart(product, count, personCount) {
    this.cart.language = this.translateService.currentLang;
    if (this.cart.cartModel.cartElements.length > 0) {
      this.cart.cartModel.cartElements.forEach(value => {
        this.removeProduct(value);
      });
      this.toasterService.showInfo('cart.maxLimitReachedProductChangedTitle', 'cart.maxLimitReachedProductChangedMessage');
    }
    const cartElementProduct = product;
    cartElementProduct.description = '';
    const cartElement: CartElement[] = this.findCartElementByProductAndPersonCount(cartElementProduct, personCount);
    if (cartElement.length > 0) {
      cartElement[0].count += count;
    } else {
      const restaurantHistoryId = this.storageService.getFromCookies(Constants.STORAGE_KEYS.RESTAURANT, null);
      const newCartElement = new CartElement(cartElementProduct, count, personCount, this.cart.cartModel.cartElements.length + count * personCount + Math.random(), restaurantHistoryId);
      this.cart.cartModel.cartElements.push(newCartElement);
    }
    this.saveCart();
    this.refreshTotal();
    this.analyticsService.addToCartEvent(product, count, personCount);
  }

  findCartElementByProduct(cartElementProduct) {
    return this.cart.cartModel.cartElements.filter((cartItem) => {
      return cartItem.productFull.id == cartElementProduct.id;
    });
  }

  findCartElementBySerial(serial: number) {
    return this.cart.cartModel.cartElements.filter((cartItem) => {
      return cartItem.serial == serial;
    });
  }

  findCartElementByProductAndPersonCount(cartElementProduct, personCount) {
    return this.cart.cartModel.cartElements.filter((cartItem) => {
      return ((cartItem.productFull.id == cartElementProduct.id) && (cartItem.personCount == personCount));
    });
  }

  findCartElementByProductId(productId) {
    return this.cart.cartModel.cartElements.filter((cartItem) => {
      return cartItem.productFull.id == productId;
    });
  }

  getDiscountAmount() {
    this.refreshCartObject();
    return this.cart.discount;
  }

  getDiscountObject() {
    this.refreshCartObject();
    return this.cart.cartModel.discountFull;
  }

  checkCartFormat(cart: any) {
    const validCart = new Cart();
    const oldKeys = Object.keys(cart).sort();
    const validKeys = Object.keys(validCart).sort();
    return JSON.stringify(oldKeys) === JSON.stringify(validKeys);
  }

  redirectToFoodSelector() {
    this.customNavigationService.navigateToSubDomain(Utilities.getMenuRedirectUrl() + this.cart.cartModel.secretKey);
  }

  refreshBySecretKey(secretKey: string) {
    this.cartRepository.getBySecretKey(secretKey).subscribe(response => {
      this.cart.cartModel.cartElements = [];
      for (const cartElement of response.cartElements) {
        let restaurantHistoryId;
        if (cartElement.deliveryReservations[0] && cartElement.deliveryReservations[0].reservation) {
          restaurantHistoryId = cartElement.deliveryReservations[0].reservation.restaurantHistoryId;
          /** Ez addig igaz, amíg egy étterem szállítja ki az egész megrendelést - MAK-1593 miatt került be*/
          this.deliveryRestaurantAddress = cartElement.deliveryReservations[0].reservation.restaurantBaseAddress;
        }
        const newCartElement = new CartElement(cartElement.product, cartElement.count, cartElement.personCount, cartElement.id, cartElement.restaurantHistoryId);
        this.cart.cartModel.cartElements.push(newCartElement);
      }
      this.refreshTotal();
    });
  }

  cartEditable(): boolean {
    return (this.cart.cartModel.secretKey == null || this.cart.cartModel.secretKey == undefined);
  }

  resetOrderProcess() {
    this.processHardReset();
    this.cartRepository.deleteBySecretKey(this.cart.cartModel.secretKey).subscribe(response => {
      this.deleteCartData();
      document.location.href = '/';
    });
  }

  private processHardReset() {
    const redirectUrl = this.storageService.getFromStore('barionRedirect', null);
    if (redirectUrl != null) {
      this.deleteCartData();
      document.location.href = '/';
    }
  }

  private processHardResetAndAdd() {
    const redirectUrl = this.storageService.getFromStore('barionRedirect', null);
    if (redirectUrl != null) {
      this.deleteCartData();
      return true;
    }
    return false;
  }

  private getProductCount() {
    if (this.cart && this.cart.cartModel) {
      return this.cart.cartModel.cartElements.length;
    }
    return 0;
  }


}

import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AttachmentUrlService} from "./attachment-url.service";

@Injectable({
  providedIn: 'root'
})
export class DataManipulationService {

  constructor(public translate: TranslateService, private attachmentUrlService:AttachmentUrlService) {
  }

  addGetNameMethodToElement(element: any, attributeName?: string){
    let currentLang = this.translate.currentLang;
    if(attributeName != undefined){
      let name = "";
      if(element[attributeName] != undefined) {
        name = element[attributeName].localizations[currentLang].name;
      } else {
        element[attributeName] = {};
      }
      element[attributeName].getName = function () {
        return name;
      };
      element[attributeName].name = name;
    }
    else{
      element.getName = function () {
        return element.localizations[currentLang].name;
      };
      element.name = element.localizations[currentLang].name;
    }
    return element;
  }
  addGetNameMethodToList(list: any[], attributeName?: string){
    for(let element of list){
      element = this.addGetNameMethodToElement(element, attributeName)
    }
    return list;
  }
  addLocaleFromBaseAddressToList(list: any[]){
    for(let element of list){
      element = this.addLocaleFromBaseAddress(element)
    }
    return list;
  }
  addLocaleFromBaseAddress(element){
    element.locale = "default";
    if(element.baseAddress?.country){
      element.locale = element.baseAddress.country;
    }
    return element;
  }

}

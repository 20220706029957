import { Component, OnInit } from '@angular/core';
import {BannerLayoutComponentModel} from "../../banner-layout-component.model";
import {ImgCommonLayoutComponent} from "../img-common-layout/img-common-layout.component";
import {AttachmentUrlService} from "../../../../services/attachment-url.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-img-layout33',
  templateUrl: './img-layout33.component.html',
  styleUrls: ['./img-layout33.component.scss']
})
export class ImgLayout33Component  extends ImgCommonLayoutComponent implements OnInit{

  constructor(attachmentUrlService: AttachmentUrlService, translate: TranslateService) {
    super(attachmentUrlService, translate);
  }

  ngOnInit(): void {
    this.initImages();
  }

}

import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ToasterService } from "./toaster.service";
import { Utilities } from "../utils/utilities";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  private isProduction: boolean;

  constructor(private toasterService: ToasterService) {
    this.isProduction = Utilities.isProduction();
  }

  handleError(err: any) {
    if (!this.isProduction) {
      console.log(err);
    }

    if (err instanceof HttpErrorResponse) {
      let errorNew: Error = {
        name: "",
        message: ""
      }
      errorNew.name = this.errorCodeToErrorTitle(err.status);
      if (!err.error.attribute) {
        this.handleNotCustomError(err, errorNew);
      } else {
        this.handleCustomError(err, errorNew);
      }
    } else {
      this.toasterService.showErrorRawMessage(err.name, err.message);
    }
  }

  private handleNotCustomError(err: HttpErrorResponse, errorNew: Error) {
    this.toasterService.showErrorWithoutTranslate(err.message, errorNew.name);
  }

  private handleCustomError(err: HttpErrorResponse, errorNew: Error) {
    if (err.error.type == 'ValidationError') {
      errorNew.message = "";
      Object.values(err.error.attribute).forEach(er => {
        errorNew.message += er + '<br>';
      });
    } else {
      errorNew.message = err.error.attribute.translationKey;
    }

    this.toasterService.showError(errorNew.message, errorNew.name);

    let violations = err.error.attribute.violations;
    if (violations != undefined) {
      // violations = violations.split(";").join("<br>")
      this.toasterService.showErrorWithoutTranslate(violations);
    }

    let devMsg = err.error.attribute.devMessage;
    if (!this.isProduction && devMsg.length > 0) {
      this.toasterService.showErrorWithoutTranslate(devMsg);
    }
  }

  private errorCodeToErrorTitle(status: number) {
    switch (status) {
      case 400: {
        return 'error.badRequest';
      }
      case 401: {
        return 'error.unauthorized';
      }
      case 402: {
        return 'error.paymentRequired';
      }
      case 403: {
        return 'error.forbidden';
      }
      case 404: {
        return 'error.notFound';
      }
      case 405: {
        return 'error.methodNotAllowed';
      }
      case 408: {
        return 'error.requestTimeOut';
      }
      case 409: {
        return 'error.conflict';
      }
      case 500: {
        return 'error.internalServerError';
      }
      case 501: {
        return 'error.notImplemented';
      }
      case 502: {
        return 'error.badGateway';
      }
      case 503: {
        return 'error.serviceUnavailable';
      }
      case 504: {
        return 'error.gatewayTimeout';
      }
      case 505: {
        return 'error.httpVersionNotSupported';
      }
      // case 4040:{
      //   return 'error.unhandledNotFound';
      // }
      case 420: {
        return 'error.constraintViolation';
      }
      case 440: {
        return 'error.fileError';
      }
      default: {
        return 'error.unknown';
      }
    }
  }

}

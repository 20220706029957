import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {CookieService} from 'ngx-cookie-service';
import {Constants} from "../utils/constants";
import {Utilities} from "../utils/utilities";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MyStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, private cookieService: CookieService) {
  }

  saveToCookies(key: string, object: any) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 1);
    this.cookieService.set(key, object, {expires: expireDate});
  }

  saveToCookiesStringCustomExpiration(key: string, object: string, ExpireDays: number) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + ExpireDays);
    this.cookieService.set(key, object, {expires: expireDate, domain: Utilities.getAcceptCookiesDomain()});
  }

  getFromCookies(key: string, fallbackObject: any) {
    const result = this.cookieService.get(key);
    return (result != null && result != "" ? result : fallbackObject);
  }

  deleteFromCookies(key: string) {
    this.cookieService.delete(key);
  }

  saveToStore(key: string, object: any) {
    this.storage.set(key, object)
  }

  getFromStore(key: string, fallbackObject: any) {
    return this.storage.has(key) ? this.storage.get(key) : fallbackObject;
  }

  deleteFromStore(key: string) {
    this.storage.remove(key);
  }

  checkKey(key: string) {
    return this.cookieService.check(key);
  }

  getStoredOrDefaultLanguage() {
    let defaultLanguage = this.getFromCookies(Constants.STORAGE_KEYS.LANGUAGE, null);
    if (!defaultLanguage) {
      let navigatorLanguage = navigator.language;
      if (navigatorLanguage && navigatorLanguage.length > 2) {
        navigatorLanguage = navigatorLanguage.substring(0, 2);
      }
      defaultLanguage = (Constants.SYSTEM_LANGUAGES.includes(navigatorLanguage)) ? navigatorLanguage : Constants.DEFAULT_LANGUAGE;
    }
    return defaultLanguage;
  }
}

import { Injectable } from '@angular/core';
import {RepositoryService} from "./repository.service";
import {HttpClient} from "@angular/common/http";
import {CartModel} from "../models/cart-models";
import {IPayload} from "../models/IPayload";

@Injectable({
  providedIn: 'root'
})
export class CartRepository extends RepositoryService{

  constructor(httpClient: HttpClient) {
    super();
    this.httpClient = httpClient;
  }

  save(cart: CartModel, currency: string){
    const data: IPayload = {
      id: 0,
      type: 'cartSave',
      attribute: cart
    };
    data.attribute["currency"] = currency;
    const path = 'webapi/api/carts';
    return super.post(path, data)
  }

  getBySecretKey(secretKey: string){
    const path = 'webapi/api/carts/'+secretKey;
    return super.get(path);
  }

  deleteBySecretKey(secretKey:string){
    const path = 'webapi/api/carts/'+secretKey;
    return super.delete(path);
  }
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {CartService} from "../../services/cart.service";
import {CurrencyService} from "../../services/currency.service";

@Component({
  selector: 'app-order-reservation-product',
  templateUrl: './order-reservation-product.component.html',
  styleUrls: ['./order-reservation-product.component.scss']
})
export class OrderReservationProductComponent implements OnInit, OnChanges {

  @Input() cartElement: any;

  product: any;

  constructor(
    private attachmentUrlService: AttachmentUrlService,
    public cartService: CartService,
    public currencyService: CurrencyService
  ) {
  }

  ngOnInit(): void {
    console.log(this.cartService.cart)
    this.product = this.cartService.cart.cartModel.cartElements[0].productFull;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cartElement != undefined) {
      console.log(this.cartElement)

    }
  }

}

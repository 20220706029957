import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Utilities} from "../utils/utilities";

@Injectable({
  providedIn: 'root'
})
export class AttachmentUrlService {

  constructor(public translate: TranslateService) {
  }

  getVideoUrl(localization) {
    let langCode = this.getLangCode(localization);
    return Utilities.getServiceUrl() + 'download/video/' + localization[langCode].slug;
  }

  getSvgImageUrl(localization) {
    let langCode = this.getLangCode(localization);
    return Utilities.getServiceUrl() + 'download/img/' + localization[langCode].slug;
  }

  getScaleWidthImageUrl(localization, width) {
    let langCode = this.getLangCode(localization);
    return this.getScaleWidthImageUrlBySlug(localization[langCode].slug, width);
  }

  getScaleWidthImageUrlBySlug(slug, width) {
    return Utilities.getServiceUrl() + 'download/img/scale/width/' + width + "/" + slug;
  }

  getScaleHeightImageUrl(localization, height) {
    let langCode = this.getLangCode(localization);
    return this.getScaleHeightImageUrlBySlug(localization[langCode].slug, height);
  }

  getScaleHeightImageUrlBySlug(slug, height) {
    return Utilities.getServiceUrl() + 'download/img/scale/height/' + height + "/" + slug;
  }

  getDownloadUrl(localization) {
    let langCode = this.getLangCode(localization);
    return Utilities.getServiceUrl() + 'download/admin/row/' + localization[langCode].slug;
  }

  private getLangCode(localization: any): string {
    let langCode = this.translate.currentLang;
    if (!localization[langCode] || !localization[langCode].slug) {
      console.warn("Csatolmánynak nincs " + langCode + " nyelvű slug-ja");
      langCode = "en";
    }
    return langCode;
  }
}

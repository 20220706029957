import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ProductRepository} from "../../repositories/product.repository";
import {RestaurantRepository} from "../../repositories/restaurant.repository";
import {CartService} from "../../services/cart.service";
import {CartComponent} from "../cart/cart.component";
import {CurrencyService} from "../../services/currency.service";
import {AttachmentUrlService} from "../../services/attachment-url.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() product: any = {};
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private productRepository: ProductRepository,
    private restaurantRepository: RestaurantRepository,
    public currencyService: CurrencyService,
    public urlService: AttachmentUrlService,
    public translateService: TranslateService,
    private cartService: CartService) {
  }


  ngOnInit(): void {
  }

  open() {
    this.bsModalRef = this.modalService.show(CartComponent);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  addToShoppingCart(product, personCount) {
    this.cartService.addItemToShoppingCart(product, 1, personCount);
    this.bsModalRef = this.modalService.show(CartComponent);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openLink() {
    window.location.href = this.translateService.instant('seohref.product') +
      '/' +
      this.product.product.slug
  }
}

import {Component, ComponentFactoryResolver, Input, OnInit, QueryList, Type, ViewChildren} from '@angular/core';
import {BannerLayoutType} from "./banner-layout.model";
import {BannerLayoutComponentModel} from "./banner-layout-component.model";
import {ViewAnchorDirective} from "../../directive/view-anchor.directive";
import {BannerContentModel} from "./banner-content-layout/banner-content.model";
import {RestaurantRepository} from "../../repositories/restaurant.repository";
import {Constants} from "../../utils/constants";
import {MyStorageService} from "../../services/my-storage.service";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {


  @Input() isYellow: boolean = false;
  @Input() bannerLayoutType: BannerLayoutType;
  @Input() contentData: BannerContentModel;
  @Input() imgLayoutComponent: Type<any>;
  @Input() contentLayoutComponent: Type<any>;
  @Input() imgUrls: any[];


  @ViewChildren(ViewAnchorDirective) viewAnchor: QueryList<ViewAnchorDirective>;

  public selectedSlug: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private restaurantRepository: RestaurantRepository,
              private myStorage: MyStorageService) {

  }

  ngOnInit(): void {
    let historyId = this.myStorage.getFromCookies(Constants.STORAGE_KEYS.RESTAURANT, null);
    let restaurants = this.myStorage.getFromStore(Constants.STORAGE_KEYS.WHOLE_RESTAURANTS, []);
    restaurants = restaurants.filter(restaurant => restaurant.historyId == historyId)
    if (restaurants.length > 0) {
      this.selectedSlug = restaurants[0].historySlug;
    }
    setTimeout(() =>{
      this.loadComponents();
    }, 200);
  }

  loadComponents() {
    const contentFactory = this.componentFactoryResolver.resolveComponentFactory(this.contentLayoutComponent);
    const imageFactory = this.componentFactoryResolver.resolveComponentFactory(this.imgLayoutComponent);
    this.viewAnchor.forEach((ad) => {
      const viewContainerRef = ad.viewContainerRef;
      viewContainerRef.clear();
      let componentRef;
      if (ad.isContent) {
        componentRef = viewContainerRef.createComponent<BannerLayoutComponentModel<any>>(contentFactory);
      } else {
        componentRef = viewContainerRef.createComponent<BannerLayoutComponentModel<any[]>>(imageFactory);
      }
      if (ad.data instanceof BannerContentModel) {
        ad.data.slug = this.selectedSlug;
      }
      componentRef.instance.data = ad.data;
      componentRef.instance.slug = this.selectedSlug;
      componentRef.instance.isYellow = this.isYellow;
    });

  }

}

import {Injectable} from '@angular/core';

export interface Menu {
  elements: MenuElement[]
}

export interface MenuElement {
  iconName: string,
  title: string,
  href: string[],
  seoHref: string,
  role: string,
  show: string,
  active: boolean,
  external?: boolean
}

export const MENU: MenuElement[] = [
  {
    iconName: "reservation.svg",
    title: "menu.reservation",
    href: ["foglalas", "reservation"],
    seoHref: "seohref.reservation",
    role: "",
    show: "onMenuAndFooter",
    external: true,
    active: false
  },
  {
    iconName: "welcome.svg",
    title: "menu.webshop",
    href: ["https://shop.makeryworld.com", "https://shop.makeryworld.com"],
    seoHref: "seohref.webshop",
    role: "",
    show: "onMenuAndFooter",
    external: true,
    active: false
  },
  {
    iconName: "welcome.svg",
    title: "menu.delivery",
    href: ["https://delivery.makeryworld.com", "https://delivery.makeryworld.com"],
    seoHref: "seohref.delivery",
    role: "",
    show: "onMenuAndFooter",
    active: true
  },
  {
    iconName: "restaurants.svg",
    title: "menu.restaurants",
    href: ["ettermeink", "restaurants"],
    seoHref: "seohref.restaurants",
    role: "",
    show: "onMenuAndFooter",
    active: false
  },
  {
    iconName: "contact.svg",
    title: "menu.contact",
    href: ["https://shop.makeryworld.com/contact-us", "https://shop.makeryworld.com/contact-us"],
    seoHref: "seohref.contact",
    role: "",
    show: "onMenu",
    external: true,
    active: false
  },
  {
    iconName: "contact.svg",
    title: "footerMenu.carrier",
    href: ["https://www.makeryworld.com/jobs/","https://www.makeryworld.com/jobs/"],
    seoHref: "seohref.carrier",
    role: "",
    show: "onFooter",
    external: false,
    active: false
  },
  {
    iconName: "contact.svg",
    title: "footerMenu.world",
    href: ["https://www.makeryworld.com/","https://www.makeryworld.com/"],
    seoHref: "seohref.world",
    role: "",
    show: "onFooter",
    external: false,
    active: false
  },

  {
    iconName: "",
    title: "",
    href: ["player/video/:streamId"],
    seoHref: "seohref.startpage",
    role: "",
    show: "none",
    active: false
  },
  {
    iconName: "privacy.svg",
    title: "menu.aszf",
    href: ["ugyfel-tajekoztatok/:slug", "terms-and-conditions/:slug"],
    seoHref: "seohref.privacy",
    role: "",
    show: "onFootMenu",
    external: true,
    active: false
  },
  {
    iconName: "afterpayment.svg",
    title: "",
    href: ["sikeres-rendeles", "successful-order"],
    seoHref: "",
    role: "",
    show: "none",
    active: false
  },
  {
    iconName: "homedelivery.svg",
    title: "menu.contact",
    href: ["hazhozszallitas", "home-delivery"],
    seoHref: "seohref.delivery",
    role: "",
    show: "none",
    active: false
  },
  {
    iconName: "extratermek.svg",
    title: "menu.extraproduct",
    href: ["extra-termekek", "additional-products"],
    seoHref: "seohref.extraproduct",
    role: "",
    show: "none",
    active: false
  },
  {
    iconName: "termek.svg",
    title: "",
    href: ["termek/:slug", "product/:slug"],
    seoHref: "seohref.product",
    role: "",
    show: "none",
    active: false
  },
  {
    iconName: "",
    title: "",
    href: ["rendelesi-adatok", "order-details"],
    seoHref: "seohref.orderDetails",
    role: "",
    show: "none",
    active: false
  },
  {
    iconName: "",
    title: "",
    href: ["rendelesi-osszesito", "order-summary"],
    seoHref: "seohref.orderSummarize",
    role: "",
    show: "none",
    active: false
  },
  {
    iconName: "hazhozszallitasaim.svg",
    title: "menu.myHomeDeliveries",
    href: ["hazhozszallitasaim", "my-home-deliveries"],
    seoHref: "seohref.myHomeDeliveries",
    role: "manager",
    show: "none",
    active: false
  },
  {
    iconName: "profil.svg",
    title: "menu.profile",
    href: ["profil", "profile"],
    seoHref: "seohref.profile",
    role: "manager",
    show: "onProfile",
    active: false
  },
  {
    iconName: "landing.svg",
    title: "menu.orderlanding",
    href: ["hazhozszallitasom/:orderId", "mydelivery/:orderId"],
    seoHref: "seohref.orderlanding",
    role: "",
    show: "none",
    active: false
  },
  {
    iconName: "welcome.svg",
    title: "menu.webshop",
    href: [""],
    seoHref: "seohref.startpage",
    role: "",
    show: "none",
    active: false
  }
];

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() {

  }

  getMenu(): MenuElement[] {
    return MENU;
  }

}

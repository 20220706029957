<div class="row">
  <div class="col-12 px-0 mdlb-slider-img-wrapper">
    <img class="mdlb-welcome-top-image lazy"
         alt="Foglalj most, akár ajándékutalvány nélkül is!"
         title="Foglalj most, akár ajándékutalvány nélkül is!"
         [src]="topImg" (error)="topImg= 'assets/images/delivery_img.jpg'"
    />
  </div>
</div>
<div class="mdlb-welcome-container">

  <app-product-details></app-product-details>

  <div class="row mdlb-ui-padding">
    <div class="col-12">
      <h2 class="mdlb-recommended-h2 mb-5 ml-0">{{'product.otherTab' | translate}}</h2>
    </div>
    <div class="col-12 ">
      <ul class="nav nav-tabs mdlb-product-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
             aria-controls="home" aria-selected="true">{{'product.tabOne.tabName' | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
             aria-controls="profile" aria-selected="false">{{'product.tabTwo.tabName' |
            translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
             aria-controls="contact" aria-selected="false">{{'product.tabThree.tabName' |
            translate}}</a>
        </li>
      </ul>
      <div class="tab-content" style="margin-top: -40px;" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <app-banner
            [bannerLayoutType]="bannerOne.layout"
            [imgLayoutComponent]="bannerOne.img"
            [imgUrls]="bannerOne.imgUrls"
            [contentLayoutComponent]="bannerOne.content"
            [contentData]="bannerOne.contentData"
          ></app-banner>
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <app-banner
            [bannerLayoutType]="bannerTwo.layout"
            [imgLayoutComponent]="bannerTwo.img"
            [imgUrls]="bannerTwo.imgUrls"
            [contentLayoutComponent]="bannerTwo.content"
            [contentData]="bannerTwo.contentData"
          ></app-banner>
        </div>
        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
          <app-banner
            [bannerLayoutType]="bannerThree.layout"
            [imgLayoutComponent]="bannerThree.img"
            [imgUrls]="bannerThree.imgUrls"
            [contentLayoutComponent]="bannerThree.content"
            [contentData]="bannerThree.contentData"
          ></app-banner>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<div class="row">-->
<!--  <div class="col-12 px-0 mdlb-slider-img-wrapper">-->
<!--    <img class="mdlb-welcome-top-image" [src]="topImg"-->
<!--         alt="Foglalj most, akár ajándékutalvány nélkül is!"-->
<!--         title="Foglalj most, akár ajándékutalvány nélkül is!" (error)="topImg = '../images/main_img.jpg'" style="">-->
<!--  </div>-->
<!--</div>-->
<!--<div class="mdlb-welcome-container">-->

<!--  <div class="row  mdlb-ui-padding">-->
<!--    <div class="col-12">-->
<!--      <h2 class="mdlb-product-details-h1">{{'product.otherTab' | translate}}</h2>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row mdlb-ui-padding">-->
<!--    <div class="col-12">-->

<!--    </div>-->
<!--  </div>-->
<!--</div>-->

